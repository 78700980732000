import { api } from './configs/axiosConfigs';
import { defineCancelApiObject } from './configs/axiosUtils';
import endPoints from './configs/endPoints';
import methods from './configs/methods';

export const FeesCollectionAPI = {
  get: async (params = {}, cancel = false) => {
    try {
      const response = await api.request({
        url: endPoints.getFeesCollections,
        method: methods.GET,
        params,
        signal: cancel ? cancelApiObject['get'].handleRequestCancellation().signal : undefined
      });

      if (response && response.data && response.data.success) {
        const feesCollection = response.data.feesCollection || [];
        return { success: true, message: response.data.message, feesCollection };
      } else {
        return { success: false, message: 'An unexpected error occurred, please contact administrator' };
      }
    } catch (error) {
      console.error('API call failed:', error);
      if (error.response && error.response.data) {
        const errorMessage =
          error.response.data.message || 'An unexpected error occurred, please contact administrator';
        return { success: false, message: errorMessage };
      }

      return { success: false, message: 'An unexpected error occurred, please contact administrator' };
    }
  },
  collect: async (body = {}, cancel = false) => {
    try {
      const response = await api.request({
        url: endPoints.collectFees,
        method: methods.POST,
        data: body,
        signal: cancel ? cancelApiObject['collectFees'].handleRequestCancellation().signal : undefined
      });

      if (response && response.data.success) {
        const feesCollection = response.data.feesCollection || [];
        return { success: true, message: response.data.message, feesCollection };
      } else {
        return { success: false, message: 'An unexpected error occurred, please contact administrator' };
      }
    } catch (error) {
      console.error('API call failed:', error);
      if (error.response && error.response.data) {
        const errorMessage =
          error.response.data.message || 'An unexpected error occurred, please contact administrator';
        return { success: false, message: errorMessage };
      }

      return { success: false, message: 'An unexpected error occurred, please contact administrator' };
    }
  }
};

const cancelApiObject = defineCancelApiObject(FeesCollectionAPI);
