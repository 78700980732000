import endPoints from './configs/endPoints';
import methods from './configs/methods';
import { api } from './configs/axiosConfigs';
import { ifscCodeRegex } from '../constants';

export const BankDetailAPI = {
  get: async (ifsc) => {
    try {
      const isValidIfsc = ifscCodeRegex.test(ifsc);
      if (!isValidIfsc) {
        throw new Error('Invalid IFSC code format');
      }

      const bankDetail = localStorage.getItem(ifsc);
      if (bankDetail) {
        return JSON.parse(bankDetail);
      }

      const response = await api.request({
        url: endPoints.getBankDetail,
        method: methods.POST,
        data: { ifsc }
      });

      localStorage.setItem(ifsc, JSON.stringify(response.data));

      return response.data;
    } catch (error) {
      console.log('\n▶️ -> BankDetailAPI get: -> error:', error);
    }
  }
};
