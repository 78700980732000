import React, { Fragment, useEffect, useRef, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { signUpSchema } from '../../yupSchema/signupSchema';
import { loginSchema } from '../../yupSchema/loginSchema';
import { encrypt, isBlank, isEqual, isNotBlank } from '../../utils';
import './Login.css';
import { UsersAPI } from '../../apis/UsersAPI';
import LoadingIndicator from '../../helpers/LoadingIndicator';
import { loadModelData } from '../../admin/containers/userRegistration/LoadModelData';

const Login = () => {
  const [showSignup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSignup, setSignup] = useState(false);
  const [currentSchema, setCurrentSchema] = useState(loginSchema);
  const [initialValues, setInitialValues] = useState({ email_or_username: '', password: '' });

  const signUpButtonRef = useRef(null);
  const signInButtonRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    activateSignIn();
  }, []);

  const Formik = useFormik({
    initialValues,
    validationSchema: currentSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        let passwordPlain = values.password;
        let emailOrUsername = values.email_or_username;

        if (isBlank(emailOrUsername) || isBlank(passwordPlain)) {
          let errorMessage = 'Unable to login please try again.';
          toast.error(errorMessage);
          setLoading(false);
          return;
        }

        let password = encrypt(passwordPlain);
        let body = { username: emailOrUsername, password };
        if (emailOrUsername.length !== 4) {
          delete body.username;
          body = { email: emailOrUsername, ...body };
        }

        // const decrypted = decrypt('U2FsdGVkX1/lbevZCCRjnw5m9L69iKIvD/cL75O3XL4=');
        // console.log('🪵 : onSubmit: : decrypted:', decrypted); //dshd
        // console.log('🪵 : onSubmit: : body:', body);
        const response = await UsersAPI.login(body);
        console.log('🪵 : onSubmit: : response:', response);

        if (response.success) {
          if (isSignup) {
            toast.success('Signup successful, please login to continue.');
            activateSignIn();
          } else {
            const user = response.user;

            if (isNotBlank(user)) {
              const currentRole = user.role.role;

              if (
                isNotBlank(currentRole) &&
                (isEqual(currentRole, 'IT Staff') ||
                  isEqual(currentRole, 'Admin Staff') ||
                  isEqual(currentRole, 'Super Admin') ||
                  isEqual(currentRole, 'Admin'))
              ) {
                toast.success('Login successful!');
                setTimeout(() => {
                  history.push('/admin');
                }, 200);

                setTimeout(async () => {
                  await loadModelData();
                }, 0);
              } else {
                let errorMessage = 'User not authorized, try different user.';
                toast.error(errorMessage);
              }
            } else {
              let errorMessage = 'Unable to login please try again.';
              toast.error(errorMessage);
            }
          }

          Formik.resetForm();
        } else {
          console.log('🪵 : onSubmit: : error:', response.message);
          let errorMessage = 'Unable to login please try again.';
          toast.error(errorMessage);
        }
        setLoading(false);
      } catch (error) {
        console.log('🪵 : onSubmit: : error:', error);
        let errorMessage = 'Unable to login please try again.';

        if (error.response.status === 404) {
          if (isSignup) {
            errorMessage = 'Signup failed, please try again.';
          }
        } else {
          errorMessage = error.response.data.message;
        }

        toast.error(errorMessage);
        setLoading(false);
      }
    }
  });

  const activateSignUp = () => {
    setInitialValues({
      email: '',
      username: '',
      password: '',
      cPassword: ''
    });
    setSignup(true);
    setCurrentSchema(signUpSchema);
    if (showSignup) {
      signInButtonRef.current.className = 'btn-form';
      signUpButtonRef.current.className = 'btn-form active';
    }
  };

  const activateSignIn = () => {
    setInitialValues({ email_or_username: '', password: '' });
    setSignup(false);
    setCurrentSchema(loginSchema);

    if (showSignup) {
      signUpButtonRef.current.className = 'btn-form';
      signInButtonRef.current.className = 'btn-form active';
    }
  };

  const loginButton = () => (
    <button className='btn-form active' ref={signInButtonRef} onClick={activateSignIn}>
      Log In
    </button>
  );

  const signUpButton = () => (
    <button className='btn-form' ref={signUpButtonRef} onClick={activateSignUp}>
      Sign Up
    </button>
  );

  const signUpAndLoginButtonContainer = () =>
    showSignup && (
      <div className='container-btn-form d-flex flex-row justify-content-center align-items-center'>
        {loginButton()}
        {signUpButton()}
      </div>
    );

  return (
    <Fragment>
      <div className='container-form-registration m-auto col-lg-8 col-md-10 col-sm-12 col-12'>
        {signUpAndLoginButtonContainer()}

        <form onSubmit={Formik.handleSubmit} className='p-5' method='POST'>
          {isSignup ? <h2>Sign Up</h2> : <h2>Login</h2>}

          <div className='mb-3'>
            <label className='form-label'>Username or Email</label>
            <input
              type='text'
              name='email_or_username'
              className='form-control'
              id='email_or_username'
              aria-describedby='emailHelp'
              value={Formik.values.email_or_username}
              onChange={Formik.handleChange}
              onBlur={Formik.handleBlur}
              placeholder='Username or Email *'
            />
            {Formik.touched.email_or_username && Formik.errors.email_or_username && (
              <p className='form-error'>{Formik.errors.email_or_username}</p>
            )}
          </div>

          {isSignup && (
            <div className='mb-3'>
              <label className='form-label'>Username</label>
              <input
                type='text'
                name='username'
                className='form-control'
                id=''
                aria-describedby='emailHelp'
                value={Formik.values.username}
                onChange={Formik.handleChange}
                onBlur={Formik.handleBlur}
                placeholder='Username *'
              />
              {Formik.touched.username && Formik.errors.username && (
                <p className='form-error'>{Formik.errors.username}</p>
              )}
            </div>
          )}

          <div className='mb-3'>
            <label className='form-label'>Password</label>
            <input
              type='password'
              name='password'
              className='form-control'
              id='exampleInputPassword1'
              value={Formik.values.password}
              onChange={Formik.handleChange}
              onBlur={Formik.handleBlur}
              placeholder='Password'
            />
            {Formik.touched.password && Formik.errors.password && (
              <p className='form-error'>{Formik.errors.password}</p>
            )}
          </div>

          {isSignup && (
            <div className='mb-3'>
              <label className='form-label'>Confirm Password</label>
              <input
                type='password'
                name='cPassword'
                className='form-control'
                id='exampleInputPassword1'
                value={Formik.values.cPassword}
                onChange={Formik.handleChange}
                onBlur={Formik.handleBlur}
                placeholder='Confirm Password'
              />
              {Formik.touched.cPassword && Formik.errors.cPassword && (
                <p className='form-error'>{Formik.errors.cPassword}</p>
              )}
            </div>
          )}

          <button type='submit' className='btn btn-dark'>
            Submit
          </button>
        </form>
      </div>
      <Toaster />
      <LoadingIndicator open={loading} />
    </Fragment>
  );
};

export default Login;
