import React, { useState, useEffect, useCallback, useMemo, Suspense } from 'react';
import { animateScroll } from 'react-scroll';
import { GrFacebookOption } from 'react-icons/gr';
import { AiOutlineInstagram, AiOutlineTwitter } from 'react-icons/ai';
import { MdExpandLess } from 'react-icons/md';
import FooterData from '../../../data/home/footer.json';
import styles from './Footer.module.scss';
import axios from 'axios';
import { baseURL } from '../../../constants';
import { Link, useHistory, useLocation } from 'react-router-dom';
import LogoImage from '../../../components/header/Logo';
import { debounce } from '../../../utils';

const Footer = React.memo(({ footerBg, menuItemSelected }) => {
  const history = useHistory();
  const location = useLocation();

  const [scroll, setScroll] = useState(0);
  const [data, setData] = useState({});
  const [logo, setLogo] = useState({});

  const top = 100;

  const handleScroll = useCallback(() => {
    setScroll(window.scrollY);
  }, []);

  useEffect(() => {
    const debounceScroll = debounce(handleScroll, 100);
    window.addEventListener('scroll', debounceScroll);
    return () => {
      window.removeEventListener('scroll', debounceScroll);
    };
  }, [handleScroll]);

  const fetchData = useCallback(async () => {
    try {
      const contactResponse = await axios.get(`${baseURL}/api/contact/all`);
      if (contactResponse.data.success) {
        setData(contactResponse.data.data[0]);
      }
    } catch (e) {
      console.log('Error ', e);
    }

    try {
      const logoResponse = await axios.get(`${baseURL}/api/logo/all`);
      if (logoResponse.data.success) {
        setLogo(logoResponse.data.data[0]);
      }
    } catch (e) {
      console.log('Error ', e);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const scrollToTop = useCallback(() => {
    animateScroll.scrollToTop();
  }, []);

  const menuItemSelectedLocal = useCallback(
    (menuName) => {
      if (menuItemSelected) {
        menuItemSelected(menuName);
      }
    },
    [menuItemSelected]
  );

  const handleClick = useCallback(
    (e, redirectPath, menuName) => {
      e.preventDefault();
      e.stopPropagation();
      menuItemSelectedLocal(menuName);

      if (!location.pathname.includes(redirectPath)) {
        setTimeout(() => history.push(redirectPath), 300);
      }
    },
    [history, location, menuItemSelectedLocal]
  );

  const footerClasses = useMemo(() => `footer-area mt-5 ${footerBg === 'white' ? styles.footer2 : ''}`, [footerBg]);
  const footerTopClasses = useMemo(
    () => `${styles.footerTop} ${footerBg === 'white' ? 'bg--cart-7' : 'bg--cart-2'}`,
    [footerBg]
  );

  return (
    <footer className={footerClasses}>
      <div className={footerTopClasses}>
        <div className='container footer-bg'>
          <div className='row'>
            {/* Start Single Widget */}
            <div className='col-12 col-sm-6 col-lg-3'>
              <div className={styles.singleWidget}>
                <div className={styles.logo}>
                  <Link to={'/'}>
                    <div className='text-white text-center'>
                      <Suspense fallback={<div>Loading...</div>}>
                        <LogoImage logo={logo} isHeader={false} />
                      </Suspense>
                    </div>
                  </Link>
                </div>
                <div className={styles.content}>
                  <p className='text-center'>
                    {FooterData.cpy1} &copy; {FooterData.cpyright} <br />
                    {FooterData.cpy2}
                  </p>
                </div>
              </div>
            </div>
            {/* End Single Widget */}
            {/* Start Single Widget */}
            <div className='col-12 col-sm-6 col-lg-3 offset-lg-1 xs__mt--40 d-flex align-items-center flex-column'>
              <div className={` ${styles.singleWidget}  `}>
                <h2 className={styles.ftTitle}>{FooterData.nav}</h2>
                <ul className={`${styles.ftContactLink}`}>
                  <li>
                    <Link to={FooterData.home} onClick={(e) => handleClick(e, FooterData.home, FooterData.home)}>
                      {FooterData.home}
                    </Link>
                  </li>
                  <li>
                    <Link to={FooterData.home} onClick={(e) => handleClick(e, FooterData.home, FooterData.about)}>
                      {FooterData.about}
                    </Link>
                  </li>
                  <li>
                    <Link to={FooterData.home} onClick={(e) => handleClick(e, FooterData.home, FooterData.academics)}>
                      {FooterData.academics}
                    </Link>
                  </li>
                  <li>
                    <Link to={FooterData.home} onClick={(e) => handleClick(e, FooterData.home, FooterData.facilities)}>
                      {FooterData.facilities}
                    </Link>
                  </li>
                  <li>
                    <Link to={FooterData.home} onClick={(e) => handleClick(e, FooterData.home, FooterData.events)}>
                      {FooterData.events}
                    </Link>
                  </li>
                  <li>
                    <Link to={FooterData.gallery}>{FooterData.gallery}</Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* End Single Widget */}
            {/* Start Single Widget */}
            <div className='col-lg-3 offset-lg-1 col-md-6 col-sm-6 col-12 sm__mt--40 md__mt--40'>
              <div className={styles.singleWidget}>
                <div className='footer-contact-text'>
                  <h2 className={`${styles.ftTitle}`}>{FooterData.contact}</h2>
                  <p className='footer-text'>{data && data.address}</p>
                  <span className='footer-text'>{data && data.email}</span>
                  <br />
                  <span className='footer-text'>{data && data.phone}</span>
                  <div className={`${styles.ftSocialLink} mt-4`}>
                    <ul className={styles.socialIcon}>
                      {data.twt && (
                        <li>
                          <a href={data.twt} target='_blank' rel='noreferrer'>
                            <AiOutlineTwitter />
                          </a>
                        </li>
                      )}
                      {data.inst && (
                        <li>
                          <a href={data.inst} target='_blank' rel='noreferrer'>
                            <AiOutlineInstagram />
                          </a>
                        </li>
                      )}
                      {data.fb && (
                        <li>
                          <a href={data.fb} target='_blank' rel='noreferrer'>
                            <GrFacebookOption />
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* End Single Widget */}
          </div>
        </div>
      </div>
      <button className={`scroll-top ${scroll > top ? 'show' : ''}`} onClick={scrollToTop}>
        <MdExpandLess />
      </button>
    </footer>
  );
});

Footer.displayName = 'Footer';
export default Footer;
