import React, { Fragment, useCallback, useState } from 'react';
import { Button, Stack, Divider, Autocomplete, TextField, FormControlLabel } from '@mui/material';
import { isBlank, isNotBlank, removeItemAtIndex, autoCompleteCustomStyle } from '../../../utils';
import { useFormik } from 'formik';
import IconButton from '@mui/material/IconButton';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import AlertDialog from '../../../helpers/AlertDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import { feesCategories } from '../../../constants';
import { FeesPerClassAPI } from '../../../apis';

const updateInitialValues = (editFeesPerClass = null) => ({
  feesTypes: (editFeesPerClass && editFeesPerClass.feesTypes) || [],
  feesForClass: (editFeesPerClass && editFeesPerClass.feesForClass && editFeesPerClass.feesForClass._id) || '',
  classMedium: (editFeesPerClass && editFeesPerClass.classMedium && editFeesPerClass.classMedium._id) || '',
  classStream: (editFeesPerClass && editFeesPerClass.classStream && editFeesPerClass.classStream._id) || ''
});

const FeesPerClassForm = ({ modelData, feesPerClassRecords, editFeesPerClass, responseCallBack, setLoading }) => {
  const [showClearDialog, setShowClearDialog] = useState(false);
  const [showRemoveFeesTypeDialog, setShowRemoveFeesTypeDialog] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [removeFeesTypeAtIndex, setRemoveFeesTypeAtIndex] = useState(-1);
  const [sameForAll, setSameForAll] = useState(false);
  const classes = modelData.classes.filter((c) => {
    if (isBlank(feesPerClassRecords)) {
      return true;
    }

    const filteredClass = feesPerClassRecords.find((r) => r.feesForClass._id === c);
    return isBlank(filteredClass);
  });
  const class11 = modelData.classes.find((c) => c.class === '11th Grade');
  const class12 = modelData.classes.find((c) => c.class === '12th Grade');
  const streams = modelData.streams;
  const mediums = modelData.classMediums;
  const defaultFieldWidth = 535;

  const Formik = useFormik({
    initialValues: updateInitialValues(editFeesPerClass),
    onSubmit: (values) => handleSubmit(values)
  });

  const createCombinations = (values) => {
    const combinedArray = [];
    const classId = values.feesForClass;

    mediums.forEach((medium) => {
      if (class11._id === classId || class12._id === classId) {
        streams.forEach((stream) => {
          const fields = {
            feesTypes: values.feesTypes,
            feesForClass: values.feesForClass,
            classMedium: medium._id,
            classStream: class11._id !== classId && class12._id !== classId ? null : stream._id
          };

          combinedArray.push({ ...fields });
        });
      } else {
        const fields = {
          feesTypes: values.feesTypes,
          feesForClass: values.feesForClass,
          classMedium: medium._id,
          classStream: null
        };

        combinedArray.push({ ...fields });
      }
    });

    return combinedArray;
  };

  const handleReset = useCallback(() => {
    setSameForAll(false);
    Formik.resetForm({ values: updateInitialValues(editFeesPerClass) });
    setShowClearDialog(false);
  }, [showClearDialog]);

  const handleSubmit = async (values) => {
    try {
      if (isBlank(values)) {
        throw new Error('Please fill fees details');
      }

      const feesTypesValue = values.feesTypes;
      if (isBlank(feesTypesValue)) {
        throw new Error('Please fill fees details');
      }

      if (
        !sameForAll &&
        isBlank(values.classStream) &&
        (values.feesForClass === class11._id || values.feesForClass === class12._id)
      ) {
        throw new Error('You must select subject stream for class 11th and 12th');
      }

      if (!sameForAll && isBlank(values.classMedium)) {
        throw new Error('Please select class medium');
      }

      let response = null;
      setLoading(true);

      let requestValues = [values];
      if (isBlank(editFeesPerClass)) {
        if (sameForAll) {
          const returnValues = createCombinations(values);
          requestValues = [...returnValues];
        }
        response = await FeesPerClassAPI.create(requestValues);
      } else {
        response = await FeesPerClassAPI.update({ ...values, id: editFeesPerClass._id });
      }

      if (response && response.success) {
        Formik.resetForm();
        responseCallBack({
          success: true,
          message: isBlank(editFeesPerClass)
            ? 'Added fees per class successfully'
            : 'Updated fees per class successfully',
          feesPerClass: response
        });
      } else {
        responseCallBack({
          success: false,
          message: 'Unable to save details, please try again!'
        });
      }
    } catch (error) {
      console.log('🪵 : onSubmit: : error:', error);
      let errorMessage = 'An error occurred, please try again!';
      if (error && 'message' in error) {
        errorMessage = error.message;
      } else if (error && 'response' in error && 'data' in error.response && 'message' in error.response.data) {
        errorMessage = error.response.data.message;
      }

      responseCallBack({ success: false, message: errorMessage });
    } finally {
      setLoading(false);
    }
  };

  const parsedFessCategories = () => {
    const parsedValue = feesCategories.filter((fc) => {
      const filteredFeesType = Formik.values.feesTypes.find((ft) => ft.feesType === fc);
      return isBlank(filteredFeesType);
    });

    return parsedValue;
  };

  const cancelAddFeesAlert = useCallback(
    () => (
      <AlertDialog
        cancelButtonTitle='No'
        continueButtonTitle='Yes'
        title={'Cancel'}
        message={`Are you sure you want to cancel ${editFeesPerClass ? 'updating' : 'adding'} fees?`}
        showDialog={showCancelDialog}
        setShowDialog={setShowCancelDialog}
        onContinue={continueCancel}
      />
    ),
    [showCancelDialog, setShowCancelDialog, continueCancel]
  );

  const clearAllEntriesAlert = useCallback(
    () => (
      <AlertDialog
        cancelButtonTitle='No'
        continueButtonTitle='Yes'
        title={'Clear'}
        message={'Are you sure you want to clear all the fields?'}
        showDialog={showClearDialog}
        setShowDialog={setShowClearDialog}
        onContinue={handleReset}
      />
    ),
    [showClearDialog, setShowClearDialog, handleReset]
  );

  const removeFeesTypeAlert = useCallback(
    () => (
      <AlertDialog
        cancelButtonTitle='No'
        continueButtonTitle='Yes'
        title={'Remove fees type'}
        message={'Are you sure you want to remove the this fees type?'}
        showDialog={showRemoveFeesTypeDialog}
        setShowDialog={setShowRemoveFeesTypeDialog}
        onContinue={continueRemoveFeesType}
        onClose={() => setRemoveFeesTypeAtIndex(-1)}
      />
    ),
    [showRemoveFeesTypeDialog, removeFeesTypeAtIndex, setShowRemoveFeesTypeDialog, continueRemoveFeesType]
  );

  const deleteFeesForClassAlert = useCallback(
    () => (
      <AlertDialog
        cancelButtonTitle='No'
        continueButtonTitle='Yes'
        title={'Delete'}
        message={'Are you sure you want to delete the fees detail for this class?'}
        showDialog={showDeleteDialog}
        setShowDialog={setShowDeleteDialog}
        onContinue={continueDeleteFeesForClass}
      />
    ),
    [showDeleteDialog, setShowDeleteDialog, continueDeleteFeesForClass]
  );

  const continueRemoveFeesType = () => {
    if (isNotBlank(Formik.values.feesTypes) && removeFeesTypeAtIndex >= 0) {
      const filtered = removeItemAtIndex(Formik.values.feesTypes, removeFeesTypeAtIndex);
      Formik.setFieldValue('feesTypes', filtered);
    }

    setRemoveFeesTypeAtIndex(-1);
    setShowRemoveFeesTypeDialog(false);
  };

  const continueDeleteFeesForClass = () => {
    try {
      setTimeout(async () => {
        let response = null;
        if (isNotBlank(editFeesPerClass)) {
          setLoading(true);
          response = await FeesPerClassAPI.delete({ id: editFeesPerClass._id });
          setLoading(false);
        }

        Formik.resetForm();
        responseCallBack({
          success: true,
          message: 'Deleted fees details for class',
          feesPerClass: response
        });
      }, 0);
    } catch (error) {
      console.log('🪵 : deleteUser : error:', error);
      responseCallBack({
        success: false,
        message: 'Failed to delete fees for class'
      });
    }
  };

  const handleResetForm = () => {
    setShowClearDialog(true);
  };

  const handleDelete = () => {
    setShowDeleteDialog(true);
  };

  const handleCancel = () => {
    setShowCancelDialog(true);
  };

  const continueCancel = () => {
    setShowCancelDialog(false);
    responseCallBack({
      success: false,
      message: editFeesPerClass ? 'Fees update canceled' : 'Add fees canceled',
      cancel: true
    });
  };

  const handleAddFeesType = () => {
    const filteredValue = Formik.values.feesTypes
      ? Formik.values.feesTypes.filter((item) => isBlank(item.feesType) || item.amount <= 0)
      : [];
    if (filteredValue.length === 0) {
      Formik.setFieldValue('feesTypes', [
        ...(Formik.values.feesTypes || []),
        {
          feesType: null,
          description: null,
          isRecurring: false,
          amount: null
        }
      ]);
    }
  };

  const handleRemoveFeesType = (index) => {
    setRemoveFeesTypeAtIndex(index);
    setShowRemoveFeesTypeDialog(true);
  };

  const classDetailsSection = () => (
    <Stack spacing={2} sx={{ marginTop: 1, marginBottom: 4 }} direction='row'>
      <Stack spacing={2} sx={{ marginTop: 1, marginBottom: 4, flexGrow: 1, maxWidth: defaultFieldWidth }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={sameForAll}
              onChange={(event) => setSameForAll(event.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label='Same For All Streams and Mediums'
          labelPlacement='end'
        />
        <Autocomplete
          id='feesForClass-autocomplete'
          sx={{ maxWidth: defaultFieldWidth, ...autoCompleteCustomStyle }}
          fullWidth
          disablePortal
          autoHighlight
          options={classes}
          getOptionLabel={(option) => option.class || ''}
          value={classes.find((c) => c._id === Formik.values.feesForClass) || null}
          onChange={(e, value) => Formik.setFieldValue('feesForClass', value ? value._id : '')}
          onBlur={Formik.handleBlur}
          renderInput={(params) => (
            <TextField
              {...params}
              id='feesForClass'
              name='feesForClass'
              type='text'
              variant='outlined'
              color='secondary'
              label='Class'
              fullWidth
              error={Boolean(Formik.touched.feesForClass && Formik.errors.feesForClass)}
              helperText={Formik.touched.feesForClass && Formik.errors.feesForClass}
            />
          )}
        />
      </Stack>

      {(Formik.values.feesForClass === class11._id || Formik.values.feesForClass === class12._id) && (
        <Stack spacing={2} sx={{ marginTop: 1, marginBottom: 4, flexGrow: 1, maxWidth: defaultFieldWidth }}>
          <FormControlLabel
            sx={{ maxWidth: defaultFieldWidth, visibility: 'hidden' }}
            control={<Checkbox inputProps={{ 'aria-label': 'controlled' }} />}
            label=''
          />
          <Autocomplete
            id='classStream-autocomplete'
            sx={{ maxWidth: defaultFieldWidth, ...autoCompleteCustomStyle }}
            fullWidth
            disabled={sameForAll}
            disablePortal
            autoHighlight
            options={streams}
            getOptionLabel={(option) => option.stream || ''}
            isOptionEqualToValue={(option, value) => option._id === value._id || isBlank(value)}
            value={streams.find((c) => c._id === Formik.values.classStream) || null}
            onChange={(e, value) => Formik.setFieldValue('classStream', value ? value._id : '')}
            onBlur={Formik.handleBlur('classStream')}
            renderInput={(params) => (
              <TextField
                {...params}
                id='classStream'
                name='classStream'
                type='text'
                variant='outlined'
                color='secondary'
                label='Stream'
                fullWidth
                error={Formik.touched.classStream && Boolean(Formik.errors.classStream)}
                helperText={Formik.touched.classStream && Formik.errors.classStream}
              />
            )}
          />
        </Stack>
      )}

      <Stack spacing={2} sx={{ marginTop: 1, marginBottom: 4, flexGrow: 1, maxWidth: defaultFieldWidth }}>
        <FormControlLabel
          sx={{ maxWidth: defaultFieldWidth, visibility: 'hidden' }}
          control={<Checkbox inputProps={{ 'aria-label': 'controlled' }} />}
          label=''
        />
        <Autocomplete
          id='classMedium-autocomplete'
          sx={{ maxWidth: defaultFieldWidth, ...autoCompleteCustomStyle }}
          fullWidth
          disabled={sameForAll}
          disablePortal
          autoHighlight
          options={mediums}
          getOptionLabel={(option) => option.classMedium || ''}
          isOptionEqualToValue={(option, value) => option._id === value._id || isBlank(value)}
          value={mediums.find((c) => c._id === Formik.values.classMedium) || null}
          onChange={(e, value) => Formik.setFieldValue('classMedium', value ? value._id : '')}
          onBlur={Formik.handleBlur('classMedium')}
          renderInput={(params) => (
            <TextField
              {...params}
              id='classMedium'
              name='classMedium'
              type='text'
              variant='outlined'
              color='secondary'
              label='Medium'
              fullWidth
              error={isNotBlank(Formik.touched.classMedium && Formik.errors.classMedium)}
              helperText={Formik.errors.classMedium}
            />
          )}
        />
      </Stack>
    </Stack>
  );

  const feesDetailSection = () => (
    <Stack spacing={2} direction='column' paddingTop={4}>
      {Formik.values.feesTypes &&
        Formik.values.feesTypes.length > 0 &&
        Formik.values.feesTypes.map((item, index) => (
          <Stack key={index} spacing={2} direction='row' sx={{ marginTop: 1, marginBottom: 4 }}>
            <Autocomplete
              id={`feesTypes.feesType-${index}`}
              sx={{
                maxWidth: defaultFieldWidth,
                ...autoCompleteCustomStyle
              }}
              fullWidth
              required
              disablePortal
              autoHighlight
              options={parsedFessCategories()}
              getOptionLabel={(option) => option || ''}
              isOptionEqualToValue={(option, value) =>
                option === value ||
                isBlank(value) ||
                isNotBlank(Formik.values.feesTypes.find((ft) => ft.feesType === value))
              }
              value={Formik.values.feesTypes.length > index ? Formik.values.feesTypes[index].feesType || '' : ''}
              onChange={(event, value) => Formik.setFieldValue(`feesTypes[${index}].feesType`, value)}
              onBlur={() => Formik.setFieldTouched(`feesTypes[${index}].feesType`, true)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id={`feesTypes.feesType-${index}`}
                  name={`feesTypes[${index}].feesType`}
                  type='text'
                  variant='outlined'
                  color='secondary'
                  label='Fees Type'
                  required
                  fullWidth
                  error={
                    !!(
                      Formik.touched.feesTypes &&
                      Formik.touched.feesTypes.length > index &&
                      Formik.touched.feesTypes[index] &&
                      Formik.errors.feesTypes &&
                      Formik.errors.feesTypes.length > index &&
                      Formik.errors.feesTypes[index] &&
                      Formik.errors.feesTypes[index].feesType
                    )
                  }
                  helperText={
                    Formik.errors.feesTypes &&
                    Formik.errors.feesTypes.length > index &&
                    Formik.errors.feesTypes[index] &&
                    Formik.errors.feesTypes[index].feesType
                  }
                />
              )}
            />

            <TextField
              id={`feesTypes.description-${index}`}
              type='text'
              variant='outlined'
              color='secondary'
              label='Description'
              name={`feesTypes[${index}].description`}
              value={Formik.values.feesTypes[index].description || ''}
              onChange={Formik.handleChange}
              onBlur={Formik.handleBlur}
              fullWidth
              multiline
            />

            <TextField
              id={`feesTypes.amount-${index}`}
              sx={{
                maxWidth: '166px',
                ...autoCompleteCustomStyle
              }}
              type='number'
              variant='outlined'
              color='secondary'
              label='Amount'
              name={`feesTypes[${index}].amount`}
              value={Formik.values.feesTypes[index].amount || ''}
              onChange={Formik.handleChange}
              onBlur={Formik.handleBlur}
              fullWidth
              required
              error={Boolean(
                Formik.touched.feesTypes &&
                  Formik.touched.feesTypes[index] &&
                  Formik.errors.feesTypes &&
                  Formik.errors.feesTypes[index] &&
                  Formik.errors.feesTypes[index].amount
              )}
              helperText={
                Formik.errors.feesTypes && Formik.errors.feesTypes[index] && Formik.errors.feesTypes[index].amount
              }
            />

            {false && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Formik.values.feesTypes[index].isRecurring}
                    onChange={(event) => Formik.setFieldValue(`feesTypes[${index}].isRecurring`, event.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label='Recurring'
                labelPlacement='end'
              />
            )}

            <IconButton
              aria-label='delete'
              size='large'
              sx={{ maxWidth: '40px' }}
              onClick={() => handleRemoveFeesType(index)}
            >
              <DeleteIcon fontSize='inherit' />
            </IconButton>
          </Stack>
        ))}
    </Stack>
  );

  const verticalDivider = useCallback(() => <Divider orientation='vertical' flexItem />, []);
  const horizontalDivider = useCallback(() => <Divider orientation='horizontal' flexItem />, []);

  const formTitleAndCloseIcon = useCallback(() => {
    let titleValue = 'Fees Details';

    return (
      <Stack direction='row' spacing={1}>
        <h4
          style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            width: '-webkit-fill-available'
          }}
        >
          {titleValue}
        </h4>

        <IconButton aria-label='delete' color='primary' style={{ width: '40px' }} onClick={handleCancel}>
          <CloseOutlined />
        </IconButton>
      </Stack>
    );
  }, [handleCancel]);

  const actionButtons = useCallback(
    () => (
      <Stack spacing={2} direction='row' divider={verticalDivider()} justifyContent='flex-end' alignItems='center'>
        <Button variant='contained' color='primary' type='button' onClick={handleAddFeesType}>
          Add Type
        </Button>
        <Button variant='contained' color='primary' type='button' onClick={handleCancel}>
          Cancel
        </Button>
        {isBlank(editFeesPerClass) && (
          <Button variant='contained' color='primary' type='button' onClick={handleResetForm}>
            Reset
          </Button>
        )}
        {isNotBlank(editFeesPerClass) && (
          <Button variant='contained' color='primary' type='button' onClick={handleDelete}>
            Delete
          </Button>
        )}
        <Button variant='contained' color='primary' type='submit' onClick={Formik.handleSubmit}>
          {`${isBlank(editFeesPerClass) ? 'Submit' : 'Update'}`}
        </Button>
      </Stack>
    ),
    [editFeesPerClass, handleResetForm, handleDelete, handleCancel]
  );

  return (
    <>
      <Stack spacing={2} direction='column' divider={horizontalDivider()} justifyContent='space-between'>
        {formTitleAndCloseIcon()}
        <div style={{ overflowY: 'auto', height: 'auto', maxHeight: '750px', paddingRight: '10px' }}>
          <form onSubmit={Formik.handleSubmit}>
            {classDetailsSection()}
            {horizontalDivider()}
            {feesDetailSection()}
          </form>
        </div>
        {actionButtons()}
      </Stack>

      {cancelAddFeesAlert()}
      {clearAllEntriesAlert()}
      {removeFeesTypeAlert()}
      {deleteFeesForClassAlert()}
    </>
  );
};

export default FeesPerClassForm;
