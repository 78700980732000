import React, { Fragment } from 'react';
import Header from '../containers/headers/headerOne';
import Footer from '../containers/footers/footerOne';

const LayoutOne = ({ children, footerBg, menuItemSelected }) => {
  const menuItemSelectedLocal = (menuName) => {
    if (menuItemSelected) {
      menuItemSelected(menuName);
    }
  };

  return (
    <Fragment>
      <Header menuItemSelected={menuItemSelectedLocal} />
      {children}
      <Footer footerBg={footerBg} menuItemSelected={menuItemSelectedLocal} />
    </Fragment>
  );
};

export default LayoutOne;
