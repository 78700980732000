import React, { forwardRef, useEffect, useState } from 'react';
import Swiper from 'react-id-swiper';
import SectionTitle from '../../../components/UI/section-title/section-title-one';
import Facilitiesimg from '../../../assets/images/banner/facilitiesborderimg.png';
import Facilitiessingle from './Facilitiessingle';
import styles from '../../blog-grid/blog-grid-one/BlogGrid.module.scss';
import './Facilities.css';
import axios from 'axios';
import { baseURL } from '../../../constants';

const Facilities = forwardRef((_props, ref) => {
  const getUrl = `${baseURL}/api/infra/all`;
  const [blogGridData, setData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    axios
      .get(getUrl)
      .then((resp) => {
        if (resp.data.success) {
          setData(resp.data.data);
          setDataLoaded(true);
        }
      })
      .catch((e) => {
        console.log('Error ', e);
      });
  }, [getUrl]);

  const params = {
    loop: true,
    grabCursor: true,
    autoplay: {
      delay: 10000,
      disableOnInteraction: true
    },
    navigation: {
      nextEl: '.next-btn',
      prevEl: '.prev-btn'
    },
    breakpoints: {
      1024: {
        slidesPerView: 3
      },
      768: {
        slidesPerView: 2
      },
      640: {
        slidesPerView: 1
      },
      320: {
        slidesPerView: 1
      }
    },
    renderNextButton: () => (
      <button className={`${styles.swiperButtonNext} ${styles.htSwiperButtonNav} next-btn`}>Next</button>
    ),
    renderPrevButton: () => (
      <button className={`${styles.swiperButtonPrev} ${styles.htSwiperButtonNav} prev-btn`}>Prev</button>
    )
  };

  return (
    <div className='row justify-content-center align-items-center mt-5' ref={ref}>
      <div className='col-10 facilities'>
        <div className='row'>
          <div className='col-12 mt-5'>
            <SectionTitle text='Facilities & Infrastructure' />
            <p className='text-center title-second mb-0'>World Class Academic Infrastructure</p>
            <div className='d-flex justify-content-center mb-3'>
              <img src={Facilitiesimg} alt='' />
            </div>
          </div>
        </div>
        {blogGridData && blogGridData.length > 0 ? (
          <div className='row justify-content-center align-items-center mb-5'>
            <div className='col-12'>
              <div className='row Facilities-slider'>
                {dataLoaded ? (
                  <Swiper {...params}>
                    {blogGridData.map((single, key) => {
                      return <Facilitiessingle data={single} key={key} styles={styles} sliderClass='swiper-slide' />;
                    })}
                  </Swiper>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
});

Facilities.displayName = 'Facilities';
export default Facilities;
