import * as yup from 'yup';

export const landingImageSchema = yup.object({
  title: yup
    .string()
    .min(2, 'Title must be at least 2 characters!')
    .max(30, 'Title must be at most 30 characters!')
    .optional(),
  redirectUrl: yup.string().url('Redirect URL must be a valid URL!').optional()
});
