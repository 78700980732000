import React, { Fragment } from 'react';
import AdminForm from '../components/form';
// import Create from '../containers/news/create';
import Layout from '../layouts/layout';

const News = () => {
  return (
    <Fragment>
      <Layout>
        {/* <Create/> */}
        <AdminForm formType={'create'} pageType={'news'} />
      </Layout>
    </Fragment>
  );
};

export default News;
