import React from 'react';
import Admin from './admin/Admin';
import ScrollToTop from './helpers/ScrollToTop';
import GalleryPage from './pages/Gallery';
import Home from './pages/Home';

import NotFound from './pages/NotFound';
import LoginPage from './pages/LoginPage';
import ContactUsPage from './containers/home/contactUs/contactUsPage';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BrowserRouter, Route, Switch } from 'react-router-dom/cjs/react-router-dom';

const App = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <BrowserRouter basename='/'>
        <ScrollToTop>
          <Switch>
            <Route exact path={'/'} component={Home} />
            <Route exact path={'/home'} component={Home} />
            <Route path={'/login'} component={LoginPage} />
            <Route path={'/contactUs'} component={ContactUsPage} />
            <Route path={'/gallery'} component={GalleryPage} />
            <Route path={'/admin'} component={Admin} />
            <Route path={'/not-found'} component={NotFound} />
            <Route exact component={NotFound} />
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
    </LocalizationProvider>
  );
};

export default App;
