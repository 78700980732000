import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';

const SecureImage = ({ src, alt, className }) => {
  const [imageData, setImageData] = useState(null);
  const [loading, setLoading] = useState(true);

  const spaceName = process.env.REACT_APP_Digital_Ocean_Space_Name;
  const endPoint = process.env.REACT_APP_Digital_Ocean_Space_Endpoint;
  const accessKeyId = process.env.REACT_APP_Digital_Ocean_Space_Access_Key;
  const secretAccessKey = process.env.REACT_APP_Digital_Ocean_Space_Secret_Key;

  const openDatabase = () => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open('imageDatabase', 1);

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        db.createObjectStore('images', { keyPath: 'key' });
      };

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  };

  const getCachedImage = (db, cacheKey) => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction('images', 'readonly');
      const store = transaction.objectStore('images');
      const request = store.get(cacheKey);

      request.onsuccess = (event) => {
        resolve(event.target.result ? event.target.result.blob : null);
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  };

  const storeImageInIndexedDB = (db, cacheKey, imageBlob) => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction('images', 'readwrite');
      const store = transaction.objectStore('images');
      const image = { key: cacheKey, blob: imageBlob };

      const request = store.put(image);
      request.onsuccess = () => resolve();
      request.onerror = (event) => reject(event.target.error);
    });
  };

  useEffect(() => {
    const spacesEndpoint = new AWS.Endpoint(endPoint);
    const s3 = new AWS.S3({
      endpoint: spacesEndpoint,
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey
    });

    const downloadImage = async (url) => {
      try {
        const fileName = url.substring(url.lastIndexOf('/') + 1);
        const cacheKey = `image_cache_${fileName}`;
        const db = await openDatabase();
        const cachedImage = await getCachedImage(db, cacheKey);

        if (cachedImage) {
          setImageData(URL.createObjectURL(cachedImage));
          setLoading(false);
          return;
        }

        const params = {
          Bucket: spaceName,
          Key: fileName
        };

        const data = await s3.getObject(params).promise();
        const blob = new Blob([data.Body], { type: 'image/jpeg' });

        await storeImageInIndexedDB(db, cacheKey, blob);
        setImageData(URL.createObjectURL(blob));
      } catch (error) {
        console.error('Error downloading image:', error);
      } finally {
        setLoading(false);
      }
    };

    downloadImage(src);
  }, [src, spaceName, endPoint, accessKeyId, secretAccessKey]);

  return <>{loading ? <div>Loading...</div> : <img src={imageData} alt={alt} className={className} />}</>;
};

export default SecureImage;
