import { api } from './configs/axiosConfigs';
import { defineCancelApiObject } from './configs/axiosUtils';
import endPoints from './configs/endPoints';
import methods from './configs/methods';

export const StatesAPI = {
  get: async (cancel = false) => {
    try {
      const response = await api.request({
        url: endPoints.getAllStates,
        method: methods.GET,
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
      });

      return response.data;
    } catch (error) {
      console.log('🪵 : get: : error:', JSON.stringify(error));
    }
  }
};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(StatesAPI);
