import CryptoJS from 'crypto-js';
import { KEYS } from '../constants';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);

export const encrypt = (string) => {
  try {
    const secret = process.env.REACT_APP_AES_SECRET;
    const encrypted = CryptoJS.AES.encrypt(string, secret).toString();
    return encrypted;
  } catch (error) {
    console.log('🪵 : encrypt : error:', error);
    return null;
  }
};

export const decrypt = (string) => {
  try {
    const secret = process.env.REACT_APP_AES_SECRET;
    const bytes = CryptoJS.AES.decrypt(string, secret);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  } catch (error) {
    console.log('🪵 : decrypt : error:', error);
    return null;
  }
};

export const isBlank = (value) => {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === 'string') {
    // Check if string is empty or contains only whitespace characters
    return value.trim().length === 0;
  }

  if (Array.isArray(value)) {
    // Check if array is empty
    return value.length === 0;
  }

  if (typeof value === 'object') {
    // Check if value is date
    if (value instanceof Date && typeof value.getMonth === 'function') {
      return false;
    }

    // Check if object is empty
    return Object.keys(value).length === 0;
  }

  // Check if number is NaN or 0
  if (typeof value === 'number') {
    return isNaN(value) || value === 0;
  }

  // For any other type, consider it as not blank
  return false;
};

export const isNotBlank = (value) => {
  return !isBlank(value);
};

export const isEqual = (value1, value2) => {
  if (typeof value1 !== typeof value2) {
    return false;
  }

  if (typeof value1 === 'string') {
    return value1.toLowerCase() === value2.toLowerCase();
  }

  if (typeof value1 === 'number' || typeof value1 === 'boolean') {
    return value1 === value2;
  }

  if (Array.isArray(value1)) {
    if (value1.length !== value2.length) {
      return false;
    }
    for (let i = 0; i < value1.length; i++) {
      if (!isEqual(value1[i], value2[i])) {
        return false;
      }
    }
    return true;
  }

  if (typeof value1 === 'object' && value1 !== null) {
    const keys1 = Object.keys(value1);
    const keys2 = Object.keys(value2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!isEqual(value1[key], value2[key])) {
        return false;
      }
    }
    return true;
  }

  return false;
};

export const getCurrentLoggedInUser = async () => {
  try {
    const userJsonString = localStorage.getItem(KEYS.LOGGED_IN_USER);

    if (isNotBlank(userJsonString)) {
      const user = JSON.parse(userJsonString);
      if (isNotBlank(user)) {
        return user;
      } else {
        throw new Error('User not available');
      }
    } else {
      throw new Error('User not available');
    }
  } catch (error) {
    console.log('🪵 : getCurrentLoggedInUser : error:', error);
    throw error;
  }
};

export const removeItemAtIndex = (array = [], indexToRemove = 0) => {
  if (isBlank(array)) {
    return [];
  }

  if (indexToRemove < 0 || indexToRemove >= array.length) {
    return array;
  }

  return array.filter((_, index) => index !== indexToRemove);
};

export const autoCompleteCustomStyle = {
  '& .MuiAutocomplete-endAdornment': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  '& .css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator': {
    width: '27px'
  },
  '& .css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator': {
    width: '27px'
  },
  '& .css-113ntv0-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator': {
    width: '27px'
  }
};

export const datePickerCustomStyle = {
  '& .css-12mkn7b-MuiButtonBase-root-MuiIconButton-root-MuiPickersCalendarHeader-switchViewButton': {
    width: '35px !important'
  },
  '& .css-kg9q0s-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button': {
    width: '35px !important'
  },
  '& .css-1nkg345-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button': {
    width: '35px !important'
  },
  '& .css-sf5j28-MuiButtonBase-root-MuiIconButton-root-MuiPickersCalendarHeader-switchViewButton': {
    width: '35px !important'
  }
};

const parseDateInput = (date, format) => {
  if (dayjs.isDayjs(date)) {
    return date.isUTC() ? date : date.utc();
  } else if (date instanceof Date) {
    return dayjs(date).utc();
  } else {
    return dayjs.utc(date, format);
  }
};

export const timestampFromDate = ({ date = null, format = 'DD/MM/YYYY' } = {}) => {
  const dateObject = date ? parseDateInput(date, format) : dayjs.utc();

  const formattedDate = dateObject.format(format);
  const parsedDateObject = dayjs.utc(formattedDate, format);

  if (isBlank(format) || format === 'DD/MM/YYYY' || format === 'DD-MM-YYYY') {
    return parsedDateObject.startOf('day').unix();
  } else {
    return parsedDateObject.unix();
  }
};

export const formattedDate = ({ date = null, format = 'DD/MM/YYYY', isString = false } = {}) => {
  const dateObject = date ? parseDateInput(date, format) : dayjs.utc();

  if (isString) {
    return dateObject.local().format(format);
  }

  const formattedDateStr = dateObject.format(format);
  return dayjs.utc(formattedDateStr, format, true);
};

export const dateFromTimestamp = ({ timestamp, format = null, isString = false } = {}) => {
  let date;

  if (isNotBlank(timestamp)) {
    date = dayjs.utc(dayjs.unix(timestamp));
  } else {
    date = dayjs.utc();
  }

  if (isNotBlank(format)) {
    return formattedDate({ date, format, isString });
  }

  if (isString) {
    return date.local().toISOString();
  }

  return date.toDate();
};

export const stringToHash = (str = randomString()) => {
  let localInputString = str;
  let hash = 0;

  if (isBlank(localInputString)) {
    localInputString = randomString();
  }

  for (let i = 0; i < localInputString.length; i++) {
    const char = localInputString.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32-bit integer
  }

  return Math.abs(hash).toString();
};

export const randomString = () => {
  return Math.random().toString(36).substring(7);
};

export const generateObjectId = () => {
  const timestamp = Math.floor(new Date().getTime() / 1000).toString(16);
  return (
    timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, () => Math.floor(Math.random() * 16).toString(16)).toLowerCase()
  );
};

export const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};
