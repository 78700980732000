import React, { useEffect, useState } from 'react';

import Navigation from '../../../components/header/Navigation';
import HeaderBtn from '../../../components/header/HeaderBtn';
import MobileMenu from '../../../components/header/MobileMenu';
import styles from './Header.module.scss';
import axios from 'axios';
import LogoItem from '../../../../src/components/header/Logo';
import Button from '../../../components/UI/button';
import { useMediaQuery, useTheme } from '@mui/material';
import { baseURL } from '../../../constants';

const Header = ({ menuItemSelected }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const header = document.querySelector('header');
    setHeaderTop(header.offsetTop);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  const [Logo, setLogo] = useState({});

  const getLogoUrl = `${baseURL}/api/logo/all`;
  useEffect(() => {
    axios
      .get(getLogoUrl)
      .then((resp) => {
        if (resp.data.success) {
          setLogo(resp.data.data[0]);
        }
      })
      .catch((e) => {
        console.log('Error ', e);
      });
  }, []);

  const menuItemSelectedLocal = (menuName, redirectPath) => {
    if (menuItemSelected) {
      menuItemSelected(menuName, redirectPath);
    }
  };

  const actionButtons = () => {
    return (
      <div
        className='col-6 col-lg-4'
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          flexDirection: 'row',
          alignItems: 'center',
          alignContent: 'center'
        }}
      >
        <div className='admission-btn'>
          <Button type='link' url='/contactUs' text='Admission' btnStyle='white' />
        </div>
        <div className='login-btn'>
          <Button type='link' url='/login' text='Login' btnStyle='white' />
        </div>
      </div>
    );
  };

  const browserNavigationActions = () => {
    return (
      <div className='row justify-content-center align-items-center' style={{ marginLeft: 15 }}>
        {/* <div className='col-lg-12 d-none d-lg-block'>
          <Navigation logo={Logo} menuItemSelected={menuItemSelectedLocal} />
        </div> */}

        <Navigation logo={Logo} menuItemSelected={menuItemSelectedLocal} />
      </div>
    );
  };

  return (
    <header
      className={`${styles.headerArea} ${scroll > headerTop ? styles.stick : ''}`}
      style={{ backgroundColor: '#343436e6', top: 0 }}
    >
      <div className='container'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className='d-flex flex-row'>
            <LogoItem logo={Logo} isHeader />
            {!isMobile && browserNavigationActions()}
          </div>

          {!isMobile && actionButtons()}
          {isMobile && <HeaderBtn styles={styles} />}
          {isMobile && <MobileMenu styles={styles} menuItemSelected={menuItemSelectedLocal} />}
        </div>
      </div>
    </header>
  );
};

export default Header;
