import React, { Fragment } from 'react';
import AdminForm from '../components/form';
// import Create from '../containers/facility-infra/create';
import Layout from '../layouts/layout';

const FacilityInfra = () => {
  return (
    <Fragment>
      <Layout>
        <AdminForm formType={'create'} pageType={'infra'} />
      </Layout>
    </Fragment>
  );
};

export default FacilityInfra;
