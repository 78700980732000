import React, { Fragment } from 'react';
import Sidebar from '../containers/sidebar/Sidebar';
import './layout.css';

const Layout = ({ children }) => {
  const btnMenu = () => {
    document.getElementById('container-sidebar').classList.toggle('display-none-resp');
  };

  return (
    <Fragment>
      <div style={{ width: '100%', height: '100vh', backgroundColor: 'gray' }} className='d-flex flex-row'>
        <div id='container-sidebar' className='container-sidebar display-none-resp'>
          <Sidebar />
        </div>

        <div className='container-main-content d-flex flex-column bg-dark'>
          <div className='admin-title d-flex flex-row justify-content-around text-center text-bg-dark p-1'>
            <button className='button-menu' onClick={btnMenu}>
              Toggle
            </button>
          </div>
          <div className='d-flex flex-column align-items-center bg-dark p-2 h-100 w-100'>{children}</div>
        </div>
      </div>
    </Fragment>
  );
};

export default Layout;
