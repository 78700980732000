import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../layouts/layout';
import FeesCollectionList from '../containers/feesCollection/FeesCollectionList';
import FeesForStudent from '../containers/feesCollection/FeesForStudent';
// import { Breadcrumbs } from '@mui/material';

const Fees = () => {
  // const location = useLocation();
  // const { pathname } = location;

  return (
    <Fragment>
      <Layout>
        {/* {pathname === '/admin/fees/student-fees-detail' && (
          <div className='d-flex flex-row align-items-center bg-white p-2  w-100 card mb-1'>
            <Breadcrumbs aria-label='breadcrumb' separator='›'>
              <div className='badge badge-pill badge-light'>
                <Link to='/admin/fees'>Student Search</Link>
              </div>
              <div className='badge badge-pill badge-light'>
                <div>Student Fees Detail</div>
              </div>
            </Breadcrumbs>
          </div>
        )} */}

        <Switch>
          <Route exact path={'/admin/fees'} component={FeesCollectionList} />
          <Route exact path={'/admin/fees/student-fees-detail'} component={FeesForStudent} />
        </Switch>
      </Layout>
    </Fragment>
  );
};

export default Fees;
