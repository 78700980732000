import React, { Fragment, useCallback, useMemo, useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Box,
  Stack,
  Divider,
  Modal,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import toast, { Toaster } from 'react-hot-toast';
import { getCurrentLoggedInUser, isNotBlank, isBlank, dateFromTimestamp } from '../../../utils';
import { useHistory, useLocation } from 'react-router-dom';
import LoadingIndicator from '../../../helpers/LoadingIndicator';
import IconButton from '@mui/material/IconButton';
import PrintRounded from '@mui/icons-material/PrintRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AlertDialog from '../../../helpers/AlertDialog';
import { FeesPerClassAPI, UsersAPI, FeesCollectionAPI, LogoAPI } from '../../../apis';
import { EmptyGridOverlay } from '../../../helpers/EmptyGridOverlay';
import FeesCollectionForm from './FeesCollectionForm';
import { BOX_STYLE, GRID_STYLE } from '../../../constants';
import PrintReceipt from '../print/PrintReceipt';

const FeesForStudent = () => {
  const history = useHistory();
  const location = useLocation();
  const { selectedUser } = location.state || {};
  const [receiptsToBePrinted, setReceiptsToBePrinted] = useState();
  const [showPrintDialog, setShowPrintDialog] = useState(false);
  const [showBackDialog, setShowBackDialog] = useState(false);
  const [feesPerClass, setFeesPerClass] = useState();
  const [feesCollection, setFeesCollection] = useState();
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [showCollectFeesDialog, setShowCollectFeesDialog] = useState(false);
  const [logoUrl, setLogoUrl] = useState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (isBlank(selectedUser)) {
      history.push('/admin/fees');
    }

    loadFeesForClass();
    loadFeesCollections();
    downloadLogo();
  }, [selectedUser]);

  useEffect(() => {
    loadCurrentUser();
  }, []);

  const loadCurrentUser = () => {
    setTimeout(async () => {
      try {
        const user = await getCurrentLoggedInUser();
        if (isNotBlank(user)) {
          setCurrentUser(user);
        } else {
          await UsersAPI.logout();
          history.push('/');
        }
      } catch (error) {
        console.log('🪵 : useEffect : error:', error);
        await UsersAPI.logout();
        history.push('/');
      }
    }, 0);
  };

  const loadFeesCollections = useCallback(async () => {
    if (isNotBlank(selectedUser)) {
      try {
        setLoading(true);
        setFeesCollection(null);

        const response = await FeesCollectionAPI.get({
          student: selectedUser._id
        });
        if (response.success) {
          if (isNotBlank(response.feesCollection)) {
            setFeesCollection(response.feesCollection[0]);
          }
        } else {
          throw Error(response.message || 'Error in fetch');
        }
      } catch (error) {
        console.log('\n▶️ -> selectedUser:', selectedUser);
        console.log('Error loading fees collection for class:', error);
      } finally {
        setLoading(false);
      }
    }
  }, [selectedUser]);

  const downloadLogo = useCallback(async () => {
    try {
      setLoading(true);
      setLogoUrl(null);
      const response = await LogoAPI.get();
      if (response.success) {
        if (isNotBlank(response.logoUrl)) {
          setLogoUrl(response.logoUrl);
        }
      } else {
        throw Error(response.message || 'Error in fetch');
      }
    } catch (error) {
      console.log('Error loading fees collection for class:', error);
    } finally {
      setLoading(false);
    }
  }, [selectedUser, logoUrl]);

  const loadFeesForClass = useCallback(
    async (refresh = false) => {
      try {
        setLoading(true);
        setFeesPerClass([]);
        const response = await FeesPerClassAPI.get(refresh, {
          feesForClass: selectedUser.classAttending._id,
          classMedium: selectedUser.classMedium && selectedUser.classMedium._id,
          classStream: selectedUser.classStream && selectedUser.classStream._id
        });
        if (response.success) {
          if (isNotBlank(response.feesPerClass)) {
            setFeesPerClass(response.feesPerClass[0]);
          }
        }
      } catch (error) {
        console.log('Error loading fees for class:', error);
      } finally {
        setLoading(false);
      }
    },
    [selectedUser]
  );

  const handleBack = () => {
    // setShowBackDialog(true);

    setShowBackDialog(false);
    history.goBack();
  };

  const cancelAddFeesAlert = useCallback(
    () => (
      <AlertDialog
        cancelButtonTitle='No'
        continueButtonTitle='Yes'
        title={'Cancel'}
        message={`Are you sure you want to cancel ${selectedUser ? 'updating' : 'adding'} fees details?`}
        showDialog={showBackDialog}
        setShowDialog={setShowBackDialog}
        onContinue={continueBack}
      />
    ),
    [showBackDialog, setShowBackDialog, continueBack]
  );

  const continueBack = () => {
    setShowBackDialog(false);
    history.goBack();
  };

  const verticalDivider = useCallback(() => <Divider orientation='vertical' flexItem />, []);
  // const horizontalDivider = useCallback(() => <Divider orientation='horizontal' flexItem />, []);

  const getFullName = useCallback(
    (userObject) =>
      userObject ? `${userObject.firstName || ''} ${userObject.middleName || ''}  ${userObject.lastName || ''}` : '',
    []
  );

  const headerWithActionButton = useCallback(
    () => (
      <Stack direction='row' justifyContent='space-between'>
        <Stack direction='row' spacing={2}>
          <IconButton aria-label='delete' color='primary' style={{ width: '40px' }} onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
          <h4
            style={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'gray'
            }}
          >
            {selectedUser ? `${getFullName(selectedUser)}` : 'Fees Detail'}
          </h4>
        </Stack>
        <Stack spacing={2} direction='row' divider={verticalDivider()} justifyContent='flex-end' alignItems='center'>
          <Button
            style={{ maxWidth: '150px' }}
            variant='contained'
            color='primary'
            type='submit'
            onClick={onClickCollectFeesButton}
          >
            Collect Fees
          </Button>
        </Stack>
      </Stack>
    ),
    [selectedUser]
  );

  const studentDetail = () => {
    return (
      <Card sx={{ minWidth: '100%', margin: '20px' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={2}>
              <Typography variant='body2' color='textSecondary'>
                Name
              </Typography>
              <Typography variant='body1'>{getFullName(selectedUser)}</Typography>
            </Grid>
            {selectedUser.classAttending && (
              <Grid item xs={12} sm={2}>
                <Typography variant='body2' color='textSecondary'>
                  Class
                </Typography>
                <Typography variant='body1'>{selectedUser.classAttending.class}</Typography>
              </Grid>
            )}
            {selectedUser.classStream && (
              <Grid item xs={12} sm={2}>
                <Typography variant='body2' color='textSecondary'>
                  Stream
                </Typography>
                <Typography variant='body1'>{selectedUser.classStream.stream}</Typography>
              </Grid>
            )}
            {selectedUser.classMedium && (
              <Grid item xs={12} sm={2}>
                <Typography variant='body2' color='textSecondary'>
                  Medium
                </Typography>
                <Typography variant='body1'>{selectedUser.classMedium.classMedium}</Typography>
              </Grid>
            )}
            {selectedUser.classSection && (
              <Grid item xs={12} sm={2}>
                <Typography variant='body2' color='textSecondary'>
                  Section
                </Typography>
                <Typography variant='body1'>{selectedUser.classSection.classSection}</Typography>
              </Grid>
            )}
            {selectedUser.classGenderGroup && (
              <Grid item xs={12} sm={2}>
                <Typography variant='body2' color='textSecondary'>
                  Gender Group
                </Typography>
                <Typography variant='body1'>{selectedUser.classGenderGroup.classGenderGroup}</Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const totalPaidPerType = useMemo(() => {
    const totalFees = {};
    const data = (feesCollection && feesCollection.feesDetail) || [];
    data.forEach((item) => {
      if (totalFees[item.feesType]) {
        totalFees[item.feesType] += item.receiptAmount;
      } else {
        totalFees[item.feesType] = item.receiptAmount;
      }
    });

    return totalFees;
  }, [feesCollection]);

  const classFeesDetail = useCallback(() => {
    return (
      <>
        <Typography variant='h6' component='div' gutterBottom>
          Fees Details
        </Typography>
        <Card sx={{ minWidth: '100%', margin: '20px' }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <Typography variant='body2' color='textSecondary'>
                  Type
                </Typography>
              </Grid>
              {selectedUser.classAttending && (
                <Grid item xs={12} sm={2}>
                  <Typography variant='body2' color='textSecondary'>
                    Description
                  </Typography>
                </Grid>
              )}
              {selectedUser.classStream && (
                <Grid item xs={12} sm={2}>
                  <Typography variant='body2' color='textSecondary'>
                    Recurring
                  </Typography>
                </Grid>
              )}
              {selectedUser.classMedium && (
                <Grid item xs={12} sm={2}>
                  <Typography variant='body2' color='textSecondary'>
                    Amount
                  </Typography>
                </Grid>
              )}
              {selectedUser.classMedium && (
                <Grid item xs={12} sm={2}>
                  <Typography variant='body2' color='textSecondary'>
                    Paid
                  </Typography>
                </Grid>
              )}
              {selectedUser.classMedium && (
                <Grid item xs={12} sm={2}>
                  <Typography variant='body2' color='textSecondary'>
                    Remaining
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Box my={2}>
              <Divider />
            </Box>
            {isNotBlank(feesPerClass) &&
              isNotBlank(feesPerClass.feesTypes) &&
              feesPerClass.feesTypes.map((fpc, index) => (
                <Grid container spacing={2} key={index} sx={{ marginBottom: '10px' }}>
                  <Grid item xs={12} sm={2}>
                    <Typography variant='body1'>{fpc.feesType}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Typography variant='body1'>{fpc.description}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Typography variant='body1'>{fpc.isRecurring ? 'Yes' : 'No'}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Typography variant='body1'>{fpc.amount}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Typography variant='body1'>{parseInt(totalPaidPerType[fpc.feesType] || 0)}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Typography variant='body1'>
                      {fpc.amount - parseInt(totalPaidPerType[fpc.feesType] || 0)}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            <Box mt={2} mb={1}>
              <Divider />
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant='h6' component='div' gutterBottom>
                  Total
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant='h6' component='div' gutterBottom>
                  {(feesPerClass &&
                    feesPerClass.feesTypes &&
                    feesPerClass.feesTypes.reduce((total, fee) => total + fee.amount, 0)) ||
                    '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant='h6' component='div' gutterBottom>
                  {(feesPerClass &&
                    feesPerClass.feesTypes &&
                    feesPerClass.feesTypes.reduce(
                      (total, fee) => total + parseInt(totalPaidPerType[fee.feesType] || 0),
                      0
                    )) ||
                    '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant='h6' component='div' gutterBottom>
                  {(feesPerClass &&
                    feesPerClass.feesTypes &&
                    feesPerClass.feesTypes.reduce(
                      (total, fee) => total + (fee.amount || 0) - parseInt(totalPaidPerType[fee.feesType] || 0),
                      0
                    )) ||
                    '-'}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  }, [selectedUser, feesPerClass, feesCollection]);

  const handlePrintReceipt = (value) => {
    if (isNotBlank(value)) {
      setShowPrintDialog(true);
      setReceiptsToBePrinted([value]);
    }
  };

  const CustomPrintIconButton = ({ onClick }) => (
    <IconButton aria-label='print' color='info' style={{ width: '20px' }} onClick={onClick}>
      <PrintRounded />
    </IconButton>
  );

  const getCellIcon = (values) => {
    const { row } = values;
    return <CustomPrintIconButton onClick={() => handlePrintReceipt(row)} />;
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      headerName: '',
      renderCell: getCellIcon,
      width: 50
    },
    {
      field: 'feesType',
      headerName: 'Type',
      width: 220
    },
    {
      field: 'frequency',
      sortable: false,
      width: 150,
      headerName: 'Frequency'
    },
    {
      field: 'fees',
      sortable: false,
      width: 150,
      valueGetter: (params) => params.row.fees && params.row.fees.toFixed(2),
      headerName: 'Fees'
    },
    {
      field: 'concession',
      sortable: false,
      headerName: 'Concession',
      valueGetter: (params) => params.row.concession && params.row.concession.toFixed(2),
      width: 100
    },
    {
      field: 'feesAfterConcession',
      sortable: false,
      headerName: 'Fees After Concession',
      valueGetter: (params) => params.row.feesAfterConcession && params.row.feesAfterConcession.toFixed(2),
      width: 200
    },
    {
      field: 'receiptAmount',
      sortable: false,
      width: 150,
      headerName: 'Receipt Amount',
      valueGetter: (params) => params.row.receiptAmount
    },
    {
      field: 'isRefund',
      sortable: false,
      width: 150,
      headerName: 'Receipt Type',
      renderCell: (params) => {
        if (params.row.isRefund) {
          return <div style={{ color: 'red', fontWeight: 'bolder' }}>{'Refund Payment'}</div>;
        }

        return <div style={{ color: 'green', fontWeight: '500' }}>{'Received Payment'}</div>;
      }
    },
    {
      field: 'paidFees',
      sortable: false,
      width: 150,
      valueGetter: (params) => params.row.paidFees && params.row.paidFees.toFixed(2),
      headerName: 'Paid'
    },
    {
      field: 'remainingFees',
      sortable: false,
      width: 150,
      valueGetter: (params) => params.row.remainingFees && params.row.remainingFees.toFixed(2),
      headerName: 'Remaining'
    },
    {
      field: 'paidBy',
      width: 250,
      headerName: 'Payer',
      valueGetter: (params) => params.row.paidBy || '-'
    },
    {
      field: 'collectedBy',
      width: 250,
      headerName: 'Collector',
      valueGetter: (params) => getFullName(params.row.collectedBy)
    },
    {
      field: 'createdAt',
      width: 250,
      headerName: 'Date',
      valueGetter: (params) =>
        dateFromTimestamp({ timestamp: params.row.createdAt, format: 'D MMM, YYYY h:mm A', isString: true })
    },
    {
      field: 'updatedBy',
      width: 250,
      headerName: 'Updater',
      valueGetter: (params) => {
        if (params.row.createdAt === params.row.updatedAt) {
          return '-';
        }
        return params.row.updatedAt > 0 ? getFullName(params.row.collectedBy) : '-';
      }
    },
    {
      field: 'updatedAt',
      width: 250,
      headerName: 'Update Date',
      valueGetter: (params) => {
        if (params.row.createdAt === params.row.updatedAt) {
          return '-';
        }
        const dateParams = {
          timestamp: params.row.updatedAt,
          format: 'D MMM, YYYY h:mm A',
          isString: true
        };
        return params.row.updatedAt > 0 ? dateFromTimestamp(dateParams) : '-';
      }
    }
  ];

  const feesReceipts = useCallback(() => {
    return (
      <div style={{ height: 300, width: '100%' }}>
        <Typography variant='h6' component='div' gutterBottom>
          Receipts
        </Typography>
        <DataGrid
          getRowId={(row) => row._id.valueOf()}
          slots={{
            noRowsOverlay: EmptyGridOverlay
          }}
          rows={(feesCollection && feesCollection.feesDetail) || []}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 13
              }
            }
          }}
          onRowClick={onClickRow}
          pageSizeOptions={[13]}
          sx={GRID_STYLE}
        />
      </div>
    );
  }, [selectedUser, feesPerClass, feesCollection]);

  const onClickRow = (content) => {
    console.log('\n▶️ -> onClickRow -> content:', content);
    setShowCollectFeesDialog(true);
  };

  const onClickCollectFeesButton = () => {
    setShowCollectFeesDialog(true);
  };

  const responseCallBack = (
    response = { success: false, message: '', cancel: false, feesCollection: null, shouldPrint: false }
  ) => {
    setLoading(false);
    setShowPrintDialog(false);
    setReceiptsToBePrinted(null);

    if (response.success) {
      closeCollectFeesDialog();
      loadFeesCollections();
      toast.success(response.message);

      if (
        response.shouldPrint &&
        isNotBlank(response.feesCollection) &&
        isNotBlank(response.feesCollection.feesDetail)
      ) {
        setShowPrintDialog(true);
        setReceiptsToBePrinted(response.feesCollection.feesDetail);
      }
    } else {
      toast.error(response.message);
      if (response.cancel) {
        closeCollectFeesDialog();
      }
    }
  };

  const closeCollectFeesDialog = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setShowCollectFeesDialog(false);
  };

  const feesCollectionModel = () => {
    return (
      <Modal
        open={showCollectFeesDialog}
        onClose={closeCollectFeesDialog}
        aria-labelledby='parent-modal-title'
        aria-describedby='parent-modal-description'
      >
        <Box sx={BOX_STYLE} style={{ width: '90%' }}>
          <FeesCollectionForm
            selectedUser={selectedUser}
            responseCallBack={responseCallBack}
            setLoading={setLoading}
            currentUser={currentUser}
            feesTypesForClass={feesPerClass ? feesPerClass.feesTypes : []}
            feesCollection={feesCollection}
          />
        </Box>
      </Modal>
    );
  };

  const closePrintDialog = () => {
    setShowPrintDialog(false);
    setReceiptsToBePrinted(null);
  };

  const showPrint = () =>
    isNotBlank(receiptsToBePrinted) && (
      <Modal
        open={showPrintDialog}
        onClose={closePrintDialog}
        aria-labelledby='parent-modal-title'
        aria-describedby='parent-modal-description'
      >
        <Box sx={{ ...BOX_STYLE, minWidth: '30%', maxWidth: isMobile ? '90%' : '50%' }} style={{ height: '100%' }}>
          <PrintReceipt
            receipts={receiptsToBePrinted}
            student={selectedUser}
            logoUrl={logoUrl}
            closePrintDialog={closePrintDialog}
          />
        </Box>
      </Modal>
    );

  return (
    <Fragment>
      {selectedUser && (
        <>
          <div key={'Fees-Collection'} className='p-3 card w-100 h-100' style={{ overflowY: 'auto' }}>
            <Stack spacing={2}>
              {headerWithActionButton()}
              {studentDetail()}
              {classFeesDetail()}
              {feesReceipts()}
            </Stack>
          </div>
          {feesCollectionModel()}
          {cancelAddFeesAlert()}
          {showPrint()}
          <Toaster />
        </>
      )}

      <LoadingIndicator open={loading} />
    </Fragment>
  );
};

export default FeesForStudent;
