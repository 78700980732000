import React, { Fragment } from 'react';
import Layout from '../layouts/layout';
import FeesPerClassList from '../containers/feesPerClass/FeesPerClassList';

const Fees = () => {
  return (
    <Fragment>
      <Layout>
        <FeesPerClassList />
      </Layout>
    </Fragment>
  );
};

export default Fees;
