import React from 'react';
import styles from './Breadcrumb.module.scss';
// import bgImg from '../../../assets/images/bg/5.jpg';

const Breadcrumb = ({ title }) => {
  return (
    // <div className={styles.prBreadcrumbArea} style={{ backgroundImage: `url(${bgImg})` }}>  // style={{ backgroundColor: '#2d2d2d' }}
    <div className={styles.prBreadcrumbArea}>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className={`${styles.breadcrumbInner} text-left`}>
              <h2 className={`${styles.breadcrumbTitle} m-auto text-center`}>{title}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
