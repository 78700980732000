import React, { Fragment } from 'react';
import UserRegistrationForm from '../containers/userRegistration/UserRegistration';
import Layout from '../layouts/layout';

const Registration = () => {
  return (
    <Fragment>
      <Layout>
        <UserRegistrationForm />
      </Layout>
    </Fragment>
  );
};

export default Registration;
