import * as yup from 'yup';

export const contactSchema = yup.object({
  address: yup.string().required('Address is required'),
  email: yup.string().required('Email is required'),
  phone: yup.string().required('Phone Number is required'),
  fb: yup.string().required('Facebook is required'),
  in: yup.string().required('Instagram is required'),
  twt: yup.string().required('Twitter is required')
});
