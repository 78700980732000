/* eslint-disable indent */
import React, { Fragment, useRef } from 'react';
import LayoutOne from '../layouts';
import HeroSlider from '../containers/hero-sliders/hero-slider-two';
import WhyJoin from '../containers/home/whyjoin/Whyjoin';
import Administration from '../containers/home/administration/Administration';
import Facilities from '../containers/home/facilities/Facilities';
import UpComingEvent from '../containers/home/upcomingevent/Upcomingevent';
import Parallax from '../containers/home/parallax/Parallax';
import News from '../containers/home/news/News';
import NavigationData from '../data/home/nav.json';
import { isNotBlank } from '../utils';
import { useMediaQuery, useTheme } from '@mui/material';

const Home = () => {
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const academicsRef = useRef(null);
  const researchRef = useRef(null);
  const sportsCurricularRef = useRef(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const executeScroll = (reference) => {
    if (isNotBlank(reference) && isNotBlank(reference.current)) {
      reference.current.scrollIntoView({ behavior: 'smooth', block: isMobile ? 'start' : 'center', inline: 'nearest' });
    }
  };

  const onMenuItemSelected = (menuName) => {
    switch (menuName) {
      case NavigationData.n1:
        executeScroll(homeRef);
        break;
      case NavigationData.n2:
        executeScroll(aboutRef);
        break;
      case NavigationData.n3:
        executeScroll(academicsRef);
        break;
      case NavigationData.n4:
        executeScroll(sportsCurricularRef);
        break;
      case NavigationData.n5:
        executeScroll(researchRef);
        break;
      case NavigationData.n6:
        break;
      case NavigationData.n7:
        break;
      default:
        break;
    }
  };

  return (
    <Fragment>
      <LayoutOne footerBg='white' menuItemSelected={onMenuItemSelected}>
        <HeroSlider ref={homeRef} />
        <WhyJoin ref={aboutRef} />
        <Parallax />
        <Administration ref={academicsRef} />
        <Facilities ref={sportsCurricularRef} />
        <UpComingEvent ref={researchRef} />
        <News />
      </LayoutOne>
    </Fragment>
  );
};

export default Home;
