import React from 'react';
import Button from '../../UI/button';
import { useMediaQuery, useTheme } from '@mui/material';
import './style.scss';

const HeroSliderTwoSingle = ({ data, styles, sliderClassName }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const titleFontClass = isMobile ? 'hero-text-title-mobile' : 'hero-text-title';
  const descriptionFontClass = isMobile ? 'hero-text-description-mobile' : 'hero-text-description';

  return (
    <div
      className={`${styles.slide} ${styles.sliderFixedHeight} ${styles.graBg1} ${
        sliderClassName || ''
      } d-flex align-items-center`}
    >
      <img src={data.image} className={isMobile ? 'slider-image-mobile' : 'slider-image'} alt='slider' />
      {data.title && <div className={`overlay ${isMobile ? 'slider-image-mobile' : 'slider-image'}`}></div>}
      <div className='container slider-text-div'>
        <div className='row justify-content-center align-items-center mt-xl-3'>
          <div className='col-12'>
            <div className={`${styles.content} d-flex flex-column justify-content-center align-items-center`}>
              <h1 className={`hero-text ${titleFontClass}`}>{data.title || ''}</h1>
              <div className='row d-flex flex-column justify-content-center align-items-center'>
                <div className='col-12 col-sm-9 col-lg-6'>
                  <p className={`hero-text text-center ${descriptionFontClass}`}>{data.description || ''}</p>
                </div>
                <div className='d-flex slider-btn'>
                  {data &&
                    data.actions &&
                    data.actions.map((action) => (
                      <Button type='link' url={action.redirectUrl} text={action.title} key={action.title} />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSliderTwoSingle;
