import * as yup from 'yup';

export const loginSchema = yup.object({
  email_or_username: yup
    .string()
    .required('Please enter valid email or 4 digit username!')
    .test('email_or_username', 'Email/Username is invalid', (value) => {
      const validatedValue = validateEmail(value) || validateUsername(value);
      return validatedValue;
    }),
  password: yup.string().required('Password can not be empty!')
});

const validateEmail = (email) => {
  return yup.string().email().isValidSync(email);
};

const validateUsername = (username) => {
  return yup.string().length(4).isValidSync(username);
};

// const validatePhone = (phone) => {
//   return yup
//     .number()
//     .integer()
//     .positive()
//     .test((phone) => {
//       return phone && phone.toString().length >= 8 && phone.toString().length <= 14 ? true : false;
//     })
//     .isValidSync(phone);
// };

// email: yup.string().email().required('Please enter valid email address!'),
