export const endPoints = {
  getAllClasses: '/api/classes/all',
  getAllClassGenderGroups: '/api/classGenderGroups/all',
  getAllStreams: '/api/streams/all',
  getAllClassMediums: '/api/classMediums/all',
  getAllClassSections: '/api/classSections/all',
  getAllCities: '/api/cities/all',
  getAllStates: '/api/states/all',
  getAllCountries: '/api/countries/all',
  getAllDesignations: '/api/designations/all',
  getAllEmployeeGrades: '/api/employeeGrades/all',
  getAllEmploymentTypes: '/api/employmentTypes/all',
  getAllGenders: '/api/genders/all',
  getAllRelations: '/api/relations/all',
  getAllRoles: '/api/roles/all',
  getAllSubjects: '/api/subjects/all',
  getAllWorkShifts: '/api/workShifts/all',
  getAllUsers: '/api/user/all',
  searchUsers: '/api/user/search',
  addUser: '/api/user/create',
  userCredentials: '/api/user/credentials',
  smsCredentials: '/api/user/smsCredentials',
  updateUser: '/api/user/update',
  deleteUser: '/api/user/delete',
  loginUser: '/api/user/login',
  logoutUser: '/api/user/logout',
  getAllQualifications: '/api/qualifications/all',
  getAllFeesPerClass: '/api/feesPerClass/all',
  createFeesPerClass: '/api/feesPerClass/create',
  updateFeesPerClass: '/api/feesPerClass/update',
  deleteFeesPerClass: '/api/feesPerClass/delete',
  getFeesCollections: '/api/feesCollection/all',
  collectFees: '/api/feesCollection/collect',
  getLogo: '/api/logo/all',
  getLandingImages: '/api/landingImages/all',
  createLandingImages: '/api/landingImages/create',
  updateLandingImages: '/api/landingImages/update',
  deleteLandingImages: '/api/landingImages/delete',
  getBankDetail: '/api/bankDetail/all'
};

export default endPoints;
