import React, { Fragment } from 'react';
import AdminForm from '../components/form';
// import Create from '../containers/facility-infra/create';
import Layout from '../layouts/layout';

const Dates = () => {
  return (
    <Fragment>
      <Layout>
        <AdminForm formType={'create'} pageType={'dates'} />
      </Layout>
    </Fragment>
  );
};

export default Dates;
