import React, { useEffect, useState, useCallback, useMemo } from 'react';
import axios from 'axios';
import Lightbox from 'react-image-lightbox';
import { baseURL } from '../../../constants';
import { Box, ImageList, ImageListItem, useMediaQuery, useTheme } from '@mui/material';
import './Gallery.css';
import 'react-image-lightbox/style.css';

function Images() {
  const [images, setImages] = useState([]);
  const [index, setIndex] = useState(0);
  const [openImageSlider, setOpenImageSlider] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const fetchImages = useCallback(async () => {
    try {
      const resp = await axios.get(`${baseURL}/api/gallery/all`);
      if (resp && resp.data && resp.data.success && resp.data.data) {
        const preparedImages = resp.data.data
          .filter((item) => item.imgUrl)
          .map((image) => ({
            src: image.imgUrl,
            original: image.imgUrl,
            width: isMobile ? 120 : 320,
            height: 'auto',
            isSelected: false,
            caption: image.title
          }));

        setImages(preparedImages);
      } else {
        console.error('Failed to fetch images:', resp.data.message);
      }
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  }, [isMobile]);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  const handleClick = (index) => {
    setIndex(index);
    setIsLoading(true); // Set loading state to true when opening the slider
    setOpenImageSlider(true);
  };

  const handleImageLoad = () => {
    setIsLoading(false); // Set loading state to false when image has loaded
  };

  const preparedImageList = useMemo(() => {
    return images.map((item, idx) => (
      <ImageListItem key={item.src} onClick={() => handleClick(idx)}>
        <img
          srcSet={`${item.src}?w=248&fit=contain&auto=format&dpr=2 2x`}
          src={`${item.src}?w=248&fit=contain&auto=format`}
          alt={item.caption}
          loading='lazy'
        />
      </ImageListItem>
    ));
  }, [images, handleClick, isMobile]);

  return (
    <div className='gallery-container'>
      <Box sx={{ overflowY: 'scroll' }}>
        <ImageList variant='masonry' cols={isMobile ? 2 : 4} gap={8}>
          {preparedImageList}
        </ImageList>
      </Box>
      {openImageSlider && images.length > 0 && (
        <Lightbox
          animationOnKeyInput
          mainSrc={images[index].original}
          imageTitle={images[index].caption}
          nextSrc={images[(index + 1) % images.length].original}
          prevSrc={images[(index + images.length - 1) % images.length].original}
          onCloseRequest={() => setOpenImageSlider(false)}
          onMovePrevRequest={() => setIndex((index + images.length - 1) % images.length)}
          onMoveNextRequest={() => setIndex((index + 1) % images.length)}
          onImageLoad={handleImageLoad} // Attach image load handler
          reactModalStyle={{ overlay: { zIndex: 2000 } }}
          imageLoadErrorMessage={<div>Image failed to load, try again</div>}
        />
      )}
      {isLoading && <div className='loading-indicator'>Loading...</div>}
    </div>
  );
}

export default Images;
