import React, { Fragment } from 'react';
import Layout from '../layouts/layout';
import LandingImageList from '../containers/landing/LandingImageList';

const LandingPage = () => {
  return (
    <Fragment>
      <Layout>
        <LandingImageList />
      </Layout>
    </Fragment>
  );
};

export default LandingPage;
