import React, { Fragment } from 'react';
import AdminForm from '../components/form';
// import Create from '../containers/facility-infra/create';
import Layout from '../layouts/layout';

const Gallery = () => {
  return (
    <Fragment>
      <Layout>
        <AdminForm formType={'create'} pageType={'gallery'} />
      </Layout>
    </Fragment>
  );
};

export default Gallery;
