/* eslint-disable indent */
import React, { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';

const Logo = memo((props) => {
  const { logo, isHeader } = props;
  const imgUrl = logo && logo.imgUrl ? logo.imgUrl : '';
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const styles = useMemo(
    () =>
      isHeader
        ? {
            display: 'flex',
            objectFit: 'cover',
            height: isMobile ? 70 : 110,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 10,
            paddingBottom: 10,
            borderRadius: isMobile ? 5 : 15
          }
        : { maxHeight: isMobile ? 100 : 180, maxWidth: 250, padding: 10, borderRadius: 10 },
    [isMobile, isHeader]
  );

  return (
    <div className='logo'>
      <Link to={'/'}>
        <img style={styles} alt='Logo' src={imgUrl} />
      </Link>
    </div>
  );
});

Logo.displayName = 'Logo';

export default Logo;
