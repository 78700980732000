import React, { forwardRef, useEffect, useState } from 'react';
import SectionTitle from '../../../components/UI/section-title/section-title-one';
import Apjimg from '../../../assets/images/client/apj.png';
import { baseURL } from '../../../constants';
import axios from 'axios';
import './Whyjoin.css';

const WhyJoin = forwardRef((_props, ref) => {
  const [data, setData] = useState();
  const getUrl = `${baseURL}/api/welcome/all`;
  useEffect(() => {
    axios
      .get(getUrl)
      .then((resp) => {
        if (resp.data.success) {
          setData(resp.data.data[0]);
        }
      })
      .catch((e) => {
        console.log('Error ', e);
      });
  }, []);

  return (
    <div ref={ref} className='row  pt-4 justify-content-center' id='whyus'>
      <div className='col-10 why-join-wrapper mt-5 mb-4 '>
        <div className='row'>
          <div className='col-sm-12 col-lg-8 title text-center py-5 d-flex justify-content-center align-items-center'>
            <div className='d-flex justify-content-center align-items-center'>
              <div className='col-12'>
                <SectionTitle text={'Welcome'} />
                <p className='mt-3 text-black'>{data ? data.content : ''}</p>
              </div>
            </div>
          </div>
          <div className='col-sm-12 col-lg-4 d-flex justify-content-center align-items-center'>
            <img className='welcome-image' src={data && 'imgUrl' in data ? data.imgUrl : Apjimg} alt='apj' />
          </div>
        </div>
      </div>
    </div>
  );
});

WhyJoin.displayName = 'WhyJoin';
export default WhyJoin;
