import { api } from './configs/axiosConfigs';
import { defineCancelApiObject } from './configs/axiosUtils';
import endPoints from './configs/endPoints';
import methods from './configs/methods';

export const FeesPerClassAPI = {
  get: async (refresh = false, params = {}, cancel = false) => {
    try {
      const response = await api.request({
        url: endPoints.getAllFeesPerClass,
        method: methods.GET,
        params,
        signal: cancel ? cancelApiObject['get'].handleRequestCancellation().signal : undefined
      });
      if (response.data.success) {
        const feesPerClass = (response && response.data && response.data.feesForClass) || [];
        return { success: true, message: response.data.message, feesPerClass };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      console.log('\n▶️ -> get: -> refresh: error', refresh, error);
      return { success: false, message: error.response.data.message };
    }
  },
  create: async (body = [], cancel = false) => {
    try {
      const response = await api.request({
        url: endPoints.createFeesPerClass,
        method: methods.POST,
        data: body,
        signal: cancel ? cancelApiObject['create'].handleRequestCancellation().signal : undefined
      });

      if (response.data.success) {
        const feesPerClass = response.data.feesForClass || [];
        return { success: true, message: response.data.message, feesPerClass };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      console.error('API call failed:', error);
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'An unexpected error occurred';

      return { success: false, message: errorMessage };
    }
  },
  update: async (body = {}, cancel = false) => {
    try {
      const response = await api.request({
        url: endPoints.updateFeesPerClass,
        method: methods.PATCH,
        data: body,
        signal: cancel ? cancelApiObject['update'].handleRequestCancellation().signal : undefined
      });

      if (response.data.success) {
        const feesPerClass = (response && response.data && response.data.feesForClass) || [];
        return { success: true, message: response.data.message, feesPerClass };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      console.log('🪵 : update feesPerClass: : error:', error);
      return { success: false, message: error.response.data.message };
    }
  },
  delete: async (body = {}, cancel = false) => {
    try {
      const response = await api.request({
        url: endPoints.deleteFeesPerClass,
        method: methods.DELETE,
        data: body,
        signal: cancel ? cancelApiObject['delete'].handleRequestCancellation().signal : undefined
      });

      return { success: response.data.success, message: response.data.message };
    } catch (error) {
      return { success: false, message: error.response.data.message };
    }
  }
};

const cancelApiObject = defineCancelApiObject(FeesPerClassAPI);
