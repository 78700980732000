import React from 'react';
import { isNotBlank } from '../../../utils';
import './PrintReceipt.css';

const Receipt = React.forwardRef((props, ref) => {
  const { invoiceData, logoUrl } = props;
  const { studentName, className, classMedium, classSection, invoiceDate, feesDetails, totalAmount } = invoiceData;

  return (
    <div ref={ref} className='receipt'>
      <div className='receipt-header'>
        <img src={logoUrl} alt='Company Logo' className='company-logo' />
      </div>
      <h2 className='receipt-heading'>Invoice</h2>
      <div className='receipt-details'>
        <div className='receipt-header-row'>
          <p className='student-details'>
            <strong>Name:</strong> {studentName}
          </p>
          <p className='student-details'>
            <strong>Class:</strong> {className}
          </p>
          <p
            className='student-details'
            style={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <strong>Date:</strong> {invoiceDate}
          </p>
        </div>
        <div className='receipt-header-row'>
          <p className='student-details'>
            <strong>Medium:</strong> {classMedium}
          </p>
          <p className='student-details'>
            <strong>Section:</strong> {classSection}
          </p>
          <p className='student-details'></p>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Fees Type</th>
            <th>Frequency</th>
            <th>Payee</th>
            <th>Concession</th>
            <th>Amount</th>
            <th>Method</th>
          </tr>
        </thead>
        <tbody>
          {isNotBlank(feesDetails) &&
            feesDetails.map((fee, index) => (
              <tr key={index}>
                <td className='feesType'>{fee.feesType}</td>
                <td className='frequency'>{fee.frequency}</td>
                <td className='payee'>{fee.paidBy}</td>
                <td className='concession'>{fee.concession > 0 ? `${fee.concession} %` : 0}</td>
                <td className='receiptAmount'>{fee.receiptAmount}</td>
                <td className='paymentMethod'>{fee.paymentMethod}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className='receipt-footer-total'>
        <h3>Total Amount: {(totalAmount || 0).toFixed(2)}</h3>
      </div>
      <div className='receipt-footer-signature'>
        <div>Administrator</div>
      </div>
      <div className='receipt-footer-note'>This is computer generated receipt no signature required</div>
    </div>
  );
});

Receipt.displayName = 'Receipt';
export default Receipt;
