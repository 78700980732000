import React, { useCallback, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import Receipt from './Receipt';
import './PrintReceipt.css';
import { dateFromTimestamp, isNotBlank } from '../../../utils';
import toast from 'react-hot-toast';

const PrintReceipt = (props) => {
  const { receipts, student, logoUrl, closePrintDialog = () => {} } = props;
  const invoiceRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => invoiceRef.current
  });

  const handleCancel = () => {
    closePrintDialog();
  };

  const getFullName = useCallback(
    (userObject) =>
      userObject ? `${userObject.firstName || ''} ${userObject.middleName || ''}  ${userObject.lastName || ''}` : '',
    []
  );

  const handleEmail = useCallback(() => {
    toast.success('Coming soon!!');
  }, []);

  const handleWhatsApp = useCallback(() => {
    toast.success('Coming soon!!');
  }, []);

  const invoiceData = {
    studentName: getFullName(student),
    className: student.classAttending.class,
    classMedium: student.classMedium.classMedium,
    classSection: student.classSection.classSection,
    classGenderGroup: student.classGenderGroup.classGenderGroup,
    invoiceDate: isNotBlank(receipts)
      ? dateFromTimestamp({ timestamp: receipts[0].createdAt, format: 'MMM DD, YYYY', isString: true })
      : '-',
    feesDetails: receipts || [],
    totalAmount: isNotBlank(receipts) ? receipts.reduce((total, item) => total + item.receiptAmount, 0) : 0
  };

  return (
    <div className='receipt-container'>
      <Receipt ref={invoiceRef} invoiceData={invoiceData} logoUrl={logoUrl} />
      <div className='button-container'>
        <button onClick={handlePrint} className='print-button'>
          Print Invoice
        </button>
        <button onClick={handleEmail} className='email-button'>
          Email
        </button>
        <button onClick={handleWhatsApp} className='whatsapp-button'>
          Whatsapp
        </button>
        <button onClick={handleCancel} className='cancel-button'>
          Close
        </button>
      </div>
    </div>
  );
};

export default PrintReceipt;
