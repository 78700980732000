import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { isNotBlank } from '../utils';

const AlertDialog = ({
  title,
  message,
  showDialog = false,
  setShowDialog = () => {},
  cancelButtonTitle = 'Cancel',
  continueButtonTitle = 'Continue',
  onContinue = () => {},
  onClose = () => {},
  cancelOnlyDialog = false,
  children
}) => {
  const [open, setOpen] = useState(showDialog);

  useEffect(() => {
    setOpen(showDialog);
  }, [showDialog]);

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setOpen(false);
    setShowDialog(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description' style={{ marginBottom: '10px' }}>
          {message}
        </DialogContentText>
        {children}
      </DialogContent>
      <DialogActions spacing={2}>
        {isNotBlank(cancelButtonTitle) && (
          <Button style={{ maxWidth: '70px' }} variant='outlined' color='primary' onClick={handleClose}>
            {cancelButtonTitle}
          </Button>
        )}
        {cancelOnlyDialog === false && (
          <Button style={{ maxWidth: '70px' }} autoFocus variant='outlined' color='primary' onClick={onContinue}>
            {continueButtonTitle}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
