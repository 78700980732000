import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Button.module.scss';

const Button = ({ type, url, text, btnStyle }) => {
  if (type === 'link') {
    return (
      <Link
        className={`${styles.schoolmgtBtn} ${btnStyle === 'white' ? styles.schoolmgtBtnWhite : ''} ${
          btnStyle === 'light' ? styles.schoolmgtBtnLight : ''
        }`}
        to={url}
      >
        {text}
      </Link>
    );
  }
  if (type === 'button') {
    return (
      <button
        className={`${styles.schoolmgtBtn} ${btnStyle === 'white' ? styles.schoolmgtBtnWhite : ''} ${
          btnStyle === 'light' ? styles.schoolmgtBtnLight : ''
        }`}
      >
        {text}
      </button>
    );
  }
  return '';
};

export default Button;
