import React, { Fragment } from 'react';
import AdminForm from '../components/form';
import Layout from '../layouts/layout';

const Dashboard = () => {
  // const [isCreate, setCreate] = useState(false)
  return (
    <Fragment>
      <Layout>
        <AdminForm formType={'create'} pageType={'adm'} />
      </Layout>
    </Fragment>
  );
};

export default Dashboard;
