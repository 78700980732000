import React, { Fragment, useCallback, useState } from 'react';
import AlertDialog from '../../../helpers/AlertDialog';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { TextField, Button, Typography, Stack, IconButton, Divider } from '@mui/material';
import { useFormik } from 'formik';
import { DataGrid } from '@mui/x-data-grid';
import { generateObjectId, isBlank, isNotBlank } from '../../../utils';
import { GRID_STYLE } from '../../../constants';
import { EmptyGridOverlay } from '../../../helpers/EmptyGridOverlay';
import { landingImageSchema } from '../../../yupSchema/landingImageSchema';
import { LandingImagesAPI } from '../../../apis';
import { CloseOutlined } from '@mui/icons-material';
import './LandingForm.css';
// import SecureImage from '../../../helpers/SecureImage';

const updateInitialValues = (values = null) => ({
  title: (values && values.title) || '',
  description: (values && values.description) || '',
  redirectUrl: (values && values.redirectUrl) || '',
  image: (values && values.image) || '',
  actions: (values && values.actions) || []
});

const LandingForm = ({ responseCallBack, editLandingPageInfo, setLoading }) => {
  const [showClearDialog, setShowClearDialog] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [actions, setActions] = useState(editLandingPageInfo ? editLandingPageInfo.actions : []);

  const formik = useFormik({
    initialValues: updateInitialValues(editLandingPageInfo),
    validationSchema: landingImageSchema,
    onSubmit: (values) => handleSubmit(values)
  });

  const handleSubmit = (values) => {
    try {
      if (isBlank(values)) {
        throw Error('Please landing information details.');
      }

      if (!values.image) {
        throw Error('Please select a landing image.');
      }

      setTimeout(async () => {
        let response = null;
        setLoading(true);

        const formData = new FormData();
        for (const key in values) {
          if (key === 'image' && 'image' in values) {
            if (typeof values[key] === 'object' && 'name' in values[key]) {
              if (editLandingPageInfo && 'image' in editLandingPageInfo) {
                formData.append('imageToBeDeleted', editLandingPageInfo.image);
              }

              formData.append(key, values[key], values[key].name);
            }
          } else if (key === 'actions') {
            formData.append(key, JSON.stringify(actions));
          } else {
            formData.append(key, values[key]);
          }
        }

        if (isBlank(editLandingPageInfo)) {
          response = await LandingImagesAPI.create(formData);
        } else {
          if ('image' in editLandingPageInfo && editLandingPageInfo.image !== values.image) {
            formData.append('imageToBeDeleted', editLandingPageInfo.image);
          }

          formData.append('id', editLandingPageInfo._id);
          response = await LandingImagesAPI.update(formData);
        }

        if (response && response.success) {
          formik.resetForm();
          setLoading(false);
          responseCallBack({
            success: true,
            message: isBlank(editLandingPageInfo)
              ? 'Added landing page image successfully'
              : 'Updated landing page image successfully',
            feesPerClass: response
          });
        } else {
          responseCallBack({ success: false, message: response.message || 'Unknown error, try again!' });
        }
      }, 0);
    } catch (error) {
      console.log('🪵 : onSubmit: : error:', error);
      let errorMessage = '';

      if ('message' in error) {
        errorMessage = error.message;
      } else {
        errorMessage = error.response.data.message;
      }

      responseCallBack({ success: false, message: errorMessage });
    } finally {
      setLoading(false);
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedActions = actions.map((action) => (action._id === newRow._id ? { ...newRow } : action));
    setActions(updatedActions);
    return newRow;
  };

  const onProcessRowUpdateError = (error) => {
    console.error('Error updating row:', error);
  };

  const addAction = () => {
    if (actions.length < 3) {
      setActions([
        ...actions,
        {
          _id: generateObjectId(),
          title: `Action ${actions.length + 1}`,
          subTitle: '',
          redirectUrl: '',
          action: '',
          materialIcon: '',
          tintColor: '#663399',
          titleColor: '#000000',
          subTitleColor: '#999999',
          backgroundColor: '#FFFFFF'
        }
      ]);
    } else {
      alert('You can only add up to 3 actions.');
    }
  };

  const handleDeleteAction = (id) => {
    const updatedActions = actions.filter((action) => action._id !== id);
    setActions(updatedActions);
  };

  const handleEditAction = (id, field, api) => {
    api.startCellEditMode({ id, field });

    setTimeout(() => {
      const input = api.getCellElement(id, field).querySelector('input');
      if (input) {
        input.focus();
      }
    }, 0);
  };

  const handleReset = useCallback(() => {
    formik.resetForm({ values: updateInitialValues(editLandingPageInfo) });
    setActions(editLandingPageInfo ? editLandingPageInfo.actions : []);
    setShowClearDialog(false);
  }, [showClearDialog, editLandingPageInfo]);

  const continueCancel = () => {
    setShowCancelDialog(false);
    responseCallBack({
      success: false,
      message: 'Cancelled landing image changes',
      cancel: true
    });
  };

  const cancelAddFeesAlert = useCallback(
    () => (
      <AlertDialog
        cancelButtonTitle='No'
        continueButtonTitle='Yes'
        title={'Cancel'}
        message={`Are you sure you want to cancel ${editLandingPageInfo ? 'updating' : 'adding'} landing page info?`}
        showDialog={showCancelDialog}
        setShowDialog={setShowCancelDialog}
        onContinue={continueCancel}
      />
    ),
    [showCancelDialog, continueCancel, editLandingPageInfo]
  );

  const clearAllEntriesAlert = useCallback(
    () => (
      <AlertDialog
        cancelButtonTitle='No'
        continueButtonTitle='Yes'
        title={'Clear'}
        message={'Are you sure you want to clear all the fields?'}
        showDialog={showClearDialog}
        setShowDialog={setShowClearDialog}
        onContinue={handleReset}
      />
    ),
    [showClearDialog, handleReset]
  );

  const renderColorCell = (params) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ backgroundColor: params.value, width: 24, height: 24, borderRadius: '50%', marginRight: 8 }}></div>
      {params.value}
    </div>
  );

  const actionColumns = [
    {
      field: 'edit',
      headerName: '',
      width: 60,
      renderCell: (params) => (
        <IconButton onClick={() => handleEditAction(params.row._id, 'title', params.api)}>
          <EditIcon />
        </IconButton>
      )
    },
    { field: 'title', headerName: 'Title', width: 200, editable: true },
    { field: 'subTitle', headerName: 'Sub Title', width: 200, editable: true },
    { field: 'redirectUrl', headerName: 'Redirect URL', width: 200, editable: true },
    { field: 'action', headerName: 'Action', width: 100, editable: true },
    { field: 'materialIcon', headerName: 'Material Icon', width: 150, editable: true },
    {
      field: 'tintColor',
      headerName: 'Tint Color',
      width: 150,
      editable: true,
      renderCell: renderColorCell
    },
    {
      field: 'titleColor',
      headerName: 'Title Color',
      width: 150,
      editable: true,
      renderCell: renderColorCell
    },
    {
      field: 'subTitleColor',
      headerName: 'Subtitle Color',
      width: 150,
      editable: true,
      renderCell: renderColorCell
    },
    {
      field: 'backgroundColor',
      headerName: 'Background Color',
      width: 150,
      editable: true,
      renderCell: renderColorCell
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params) => (
        <IconButton onClick={() => handleDeleteAction(params.row._id)}>
          <DeleteIcon />
        </IconButton>
      )
    }
  ];

  const addImage = (e) => {
    const files = e.target.files;
    formik.setFieldValue('image', files[0]);
  };

  const formTitleAndCloseIcon = useCallback(() => {
    let titleValue = 'Landing Image With Details';

    return (
      <Stack direction='row' spacing={1}>
        <h4
          style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            width: '-webkit-fill-available'
          }}
        >
          {titleValue}
        </h4>

        <IconButton
          aria-label='delete'
          color='primary'
          style={{ width: '40px' }}
          onClick={() => setShowCancelDialog(true)}
        >
          <CloseOutlined />
        </IconButton>
      </Stack>
    );
  }, [setShowCancelDialog]);

  const horizontalDivider = useCallback(() => <Divider orientation='horizontal' flexItem />, []);

  const getActionsGrid = useCallback(
    () => (
      <Stack spacing={1} sx={{ overflowX: 'auto', height: 350, width: '100%' }}>
        <Typography variant='h6'>Actions</Typography>
        <div style={{ minHeight: 300, width: '100%' }}>
          <DataGrid
            getRowId={(row) => row._id || row.id}
            slots={{ noRowsOverlay: EmptyGridOverlay }}
            disableSelectionOnClick
            disableColumnMenu
            disableColumnSelector
            rows={actions || []}
            columns={actionColumns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 13
                }
              }
            }}
            pageSizeOptions={[13]}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={onProcessRowUpdateError}
            experimentalFeatures={{ newEditingApi: true }}
            sx={GRID_STYLE}
          />
        </div>
      </Stack>
    ),
    [actions, actionColumns, processRowUpdate, onProcessRowUpdateError]
  );

  const getImageUri = () => {
    if (formik && 'values' in formik && 'image' in formik.values) {
      if (formik.values.image && typeof formik.values.image === 'object') {
        const url = URL.createObjectURL(formik.values.image);
        return url;
      } else if (formik.values.image && typeof formik.values.image === 'string') {
        return formik.values.image;
      }
    }

    return '';
  };

  const getContentForm = () => (
    <form onSubmit={formik.handleSubmit}>
      <Stack direction={'row'} spacing={2} sx={{ marginTop: 1, marginBottom: 1 }}>
        <Stack spacing={2} direction='column'>
          <TextField
            type='file'
            name='image'
            label='Image URL (1400 x 800 & Upto 5 MB)'
            className='form-field'
            InputLabelProps={{ shrink: true }}
            inputProps={{ accept: 'image/*' }}
            onChange={addImage}
          />
          {getImageUri() && (
            <div className='image-container'>
              <img src={getImageUri()} alt={'Image'} className='img' />
            </div>
          )}
        </Stack>
        <Stack spacing={2} direction='column' sx={{ flexGrow: 1 }}>
          <Stack direction={'row'} spacing={2}>
            <TextField
              name='title'
              label='Title'
              fullWidth
              className='form-field'
              {...formik.getFieldProps('title')}
              error={isNotBlank(formik.touched.title && formik.errors.title)}
              helperText={formik.errors.title}
            />
            <TextField
              name='redirectUrl'
              label='Redirect URL'
              fullWidth
              className='form-field'
              {...formik.getFieldProps('redirectUrl')}
              error={isNotBlank(formik.touched.redirectUrl && formik.errors.redirectUrl)}
              helperText={formik.errors.redirectUrl}
            />
          </Stack>

          <Stack direction={'row'} spacing={2}>
            <TextField
              name='description'
              label='Description'
              fullWidth
              multiline
              className='form-field'
              {...formik.getFieldProps('description')}
              error={isNotBlank(formik.touched.description && formik.errors.description)}
              helperText={formik.errors.description}
            />
          </Stack>
        </Stack>
      </Stack>
    </form>
  );

  const actionButtons = () => (
    <Stack direction={'row'} spacing={2}>
      <Button variant='contained' color='primary' onClick={() => setShowCancelDialog(true)} className='form-field'>
        Cancel
      </Button>
      <Button variant='contained' color='primary' onClick={() => setShowClearDialog(true)} className='form-field'>
        Clear
      </Button>
      <Button variant='contained' color='primary' onClick={addAction} className='form-field'>
        Add Action
      </Button>
      <Button variant='contained' color='primary' fullWidth type='submit' onClick={formik.handleSubmit}>
        {`${isBlank(editLandingPageInfo) ? 'Submit' : 'Update'}`}
      </Button>
    </Stack>
  );

  return (
    <Fragment>
      <Stack spacing={2} direction='column' divider={horizontalDivider()} justifyContent='space-between'>
        {formTitleAndCloseIcon()}
        {getContentForm()}
        {getActionsGrid()}
        {actionButtons()}
      </Stack>
      {cancelAddFeesAlert()}
      {clearAllEntriesAlert()}
    </Fragment>
  );
};

export default LandingForm;
