import React, { Fragment } from 'react';

const AdmCard = ({ data, del, edit }) => {
  const author = `Name: ${data.author || '-'}`;
  const qualification = `Qualification: ${data.degree || '-'}`;
  const experience = `Experience: ${data.experience || '-'} Years`;
  const designation = `Designation: ${data.designation || '-'}`;
  const imgUrl = data.imgUrl;

  return (
    <Fragment>
      <div key={data._id} className='p-3 card mt-3' style={{ width: '100%' }}>
        <div className='d-flex flex-row justify-content-between'>
          <div className='d-flex flex-row justify-content-left'>
            <img style={{ marginRight: 15 }} src={imgUrl} height='150px' alt={'AdminCard'} />
            <div className='d-flex flex-column justify-content-center'>
              <p className='w-auto'>{author}</p>
              <p className='w-auto'>{qualification}</p>
              <p className='w-auto'>{experience}</p>
              <p className='w-auto'>{designation}</p>
            </div>
          </div>

          <div className='w-auto d-flex flex-column justify-content-center align-items-center'>
            <button className='btn btn-edit btn-sm btn-primary' onClick={() => edit(data._id)}>
              Edit
            </button>
            <br />
            <button className='btn btn-delete btn-sm btn-danger' onClick={() => del(data._id)}>
              Delete
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AdmCard;
