import React from 'react';
import Breadcrumb from '../components/UI/breadcrumb';
import LayoutOne from '../layouts';
import Images from '../containers/gallery/Images/Images';

const GalleryPage = () => {
  return (
    <LayoutOne footerBg='white'>
      <Breadcrumb title='Gallery' />
      <Images />
    </LayoutOne>
  );
};

export default GalleryPage;
