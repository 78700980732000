import React, { forwardRef, useEffect, useState } from 'react';
import { FaGraduationCap } from 'react-icons/fa';
import { FaBlackTie } from 'react-icons/fa';
import { AiOutlineHistory } from 'react-icons/ai';
import AdministrationIcon from '../../../assets/images/author/ad-icon.png';
import './Administration.css';
import Swiper from 'react-id-swiper';
import axios from 'axios';
import { baseURL } from '../../../constants';
import SectionTitle from '../../../components/UI/section-title/section-title-one';
import styles from '../../blog-grid/blog-grid-one/BlogGrid.module.scss';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { Link } from '@mui/material';

const Administration = forwardRef((_props, ref) => {
  const getUrl = `${baseURL}/api/adm/all`;

  const [administrationData, setAdministrationData] = useState([]);
  useEffect(() => {
    axios
      .get(getUrl)
      .then((resp) => {
        setAdministrationData(resp.data.data);
      })
      .catch((e) => {
        console.log('Error ', e);
      });
  }, [getUrl]);

  const params = {
    loop: true,
    grabCursor: true,
    autoplay: {
      delay: 10000,
      disableOnInteraction: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      1024: {
        slidesPerView: 3
      },
      768: {
        slidesPerView: 2
      },
      640: {
        slidesPerView: 1
      },
      320: {
        slidesPerView: 1
      }
    },
    renderPrevButton: () => (
      <button className={`${styles.swiperButtonPrev} ${styles.htSwiperButtonNav} swiper-button-prev`}>
        <IoIosArrowBack />
      </button>
    ),

    renderNextButton: () => (
      <button className={`${styles.swiperButtonNext} ${styles.htSwiperButtonNav} swiper-button-next`}>
        <IoIosArrowForward />
      </button>
    )
  };

  const getAdministratorCard = (single, key) => {
    return (
      <div key={key} className='col-12 col-sm-6 col-lg-4'>
        <div className={styles.blog}>
          <div className={`${styles.thumb}`}>
            <Link to='#'>
              <img
                src={single.imgUrl}
                height='250px'
                alt='Facilities Image'
                style={{
                  objectFit: 'cover',
                  objectPosition: 'center'
                }}
              />
            </Link>
            <div className='card-body p-2'>
              <p className='card-title mt-2 ml-2 name'>{single.author}</p>
              <div className='details'>
                <FaBlackTie className='gra-icon text-secondary' />
                <span>{single.designation}</span>
                <br />
                <FaGraduationCap className='gra-icon text-secondary'></FaGraduationCap>
                <span className='card-text'>{single.degree}</span>
                <br />
                <AiOutlineHistory className='gra-icon text-secondary' />
                <span>{single.experience} years</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className='row justify-content-center align-items-center mt-5' ref={ref}>
        <div className='col-10 py-5 administration'>
          <div className='mb-sm-2 d-flex flex-column justify-content-center align-items-center'>
            <SectionTitle text='Administrators' />
            <p className='text-center title-second mb-0'>Empowering with Precision and Insight</p>
            <img className='text-center ad-img' src={AdministrationIcon} alt='administration icon' />
          </div>
          {administrationData && administrationData.length > 0 && (
            <div className='row justify-content-center align-items-center'>
              <div className='col-12'>
                <div className='row Facilities-slider'>
                  <Swiper {...params}>
                    {administrationData.map((single, key) => getAdministratorCard(single, key))}
                  </Swiper>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
});

Administration.displayName = 'Administration';
export default Administration;
