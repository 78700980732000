import React, { Fragment } from 'react';
import SecureImage from '../../../../helpers/SecureImage';

const GalleryCard = ({ data, del }) => {
  const deleteButton = () => {
    return (
      <div className='w-25 d-flex flex-column justify-content-center align-items-center'>
        <button
          className='btn btn-delete btn-sm btn-danger'
          style={{ marginBottom: 15 }}
          onClick={() => {
            del(data._id);
          }}
        >
          Delete
        </button>
      </div>
    );
  };

  return (
    <Fragment>
      <div
        key={data._id}
        className='p-3 card mt-3 mr-1 shadow-lg p-3 mb-5 bg-black rounded'
        style={{ width: '350px', overflow: 'hidden' }}
      >
        {deleteButton()}
        <div className='d-flex flex-column justify-content-between'>
          <SecureImage
            src={data.imgUrl}
            className='img-fluid img-thumbnail rounded mx-auto d-block'
            alt={'Gallery Card'}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default GalleryCard;
