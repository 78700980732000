import React, { useCallback, useState } from 'react';
import { Button, TextField, Stack, Divider, Autocomplete, Card, CardContent } from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useFormik } from 'formik';
import { schemaForUserType } from '../../../yupSchema/addUserSchema';
import {
  isBlank,
  isEqual,
  isNotBlank,
  autoCompleteCustomStyle,
  datePickerCustomStyle,
  formattedDate,
  timestampFromDate,
  dateFromTimestamp
} from '../../../utils';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import AlertDialog from '../../../helpers/AlertDialog';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { UsersAPI } from '../../../apis/UsersAPI';
import { BankDetailAPI } from '../../../apis/BankDetailAPI';
import { govIdTypes, ifscCodeRegex } from '../../../constants';

const UserRegistrationForm = ({ editUser, actionRoleParam, modelData, responseCallBack, setLoading, currentUser }) => {
  const [clearMessage] = useState(
    'Before proceeding, please be aware that all the values you have entered will be cleared. Are you sure you want to continue?'
  );
  const [removeClassesTeachingMessage] = useState('Are you sure you want to remove the class?');
  const [submitErrorMessage, setSubmitErrorMessage] = useState(
    `Unable to ${isNotBlank(editUser) ? 'update' : 'add'} user, please try again!`
  );
  const [maxSubjectsPerTeacher] = useState(7);
  const [showCredentialsDialog, setShowCredentialsDialog] = useState(false);
  const [showClearDialog, setShowClearDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteAlertTitle] = useState('Delete User');
  const [deleteAlertMessage] = useState(
    'Before proceeding, please be aware that user will be marked as deleted. Are you sure you want to continue?'
  );
  const [clearAlertTitle] = useState('Clear All Entries');
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [cancelAlertTitle] = useState(`Cancel ${editUser ? 'Update' : 'Registration'}`);
  const [showRemoveClassesTeachingDialog, setShowRemoveClassesTeachingDialog] = useState(false);
  const [classIndexToBeRemoved, setClassIndexToBeRemoved] = useState(undefined);
  const [removeClassesTeachingAlertTitle] = useState('Remove Subject');
  const [showMaxSubjectDialog, setShowMaxSubjectDialog] = useState(false);
  const [actionRole, setActionRole] = useState(actionRoleParam);
  const [causeOfAction, setCauseOfAction] = useState();
  const [validationSchema, setValidationSchema] = useState(schemaForUserType(actionRoleParam.role));

  const [sendCredentialsAlertTitle] = useState('Send Credentials');
  const [sendCredentialsAlertMessage, setSendCredentialsAlertMessage] = useState(
    `Send credentials to registered mobile number: ${(editUser && editUser.mobile) || ''}`
  );
  const [showCredentialsAlertTitle] = useState('Show Credentials');
  const [showCredentialsAlertMessage] = useState(
    `Please do NOT share credentials with anybody but ${(editUser && editUser.firstName) || ''} ${
      (editUser && editUser.lastName) || ''
    }`
  );
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const defaultFieldWidth = 393.5;
  const classes = modelData.classes;
  const classGenderGroups = modelData.classGenderGroups;
  const streams = modelData.streams;
  const mediums = modelData.classMediums;
  const sections = modelData.classSections;
  const cities = modelData.cities;
  const designations = modelData.designations;
  const grades = modelData.employeeGrades;
  const employmentTypes = modelData.employmentTypes;
  const relations = modelData.relations;
  const roles = modelData.roles;
  const subjects = modelData.subjects;
  const shifts = modelData.workShifts;
  const qualifications = modelData.qualifications;
  const class11 = classes.find((c) => c.class === '11th Grade');
  const class12 = classes.find((c) => c.class === '12th Grade');

  let requestBody = {};

  const Formik = useFormik({
    initialValues: updateInitialValues(editUser, actionRole),
    validationSchema,
    onSubmit: (values) => handleSubmit(values)
  });

  const handleReset = useCallback(() => {
    Formik.resetForm({ values: updateInitialValues(editUser, actionRole) });
    setShowClearDialog(false);
  }, [showClearDialog]);

  const handleSubmit = (values) => {
    try {
      let isValid = false;

      requestBody = null;
      isValid = validatePersonalDetails(values);
      if (actionRole.role === 'Student' && isValid) {
        isValid = validateStudentDetails(values);
      } else if (actionRole.role === 'Teacher' && isValid) {
        isValid = validateTeacherDetails(values);
        if (isValid) {
          isValid = validateEmploymentDetails(values);
        }
      } else if (actionRole.role !== 'Student' && actionRole.role !== 'Teacher' && isValid) {
        isValid = validateEmploymentDetails(values);
      }

      isValid = validateBankDetails(values);
      isValid = validateGovIdDetails(values);

      if (!isValid || isBlank(requestBody)) {
        responseCallBack({ success: false, message: submitErrorMessage });
        return;
      }

      setTimeout(async () => {
        let response = null;
        setLoading(true);
        if (isBlank(editUser)) {
          response = await UsersAPI.create({ ...requestBody });
        } else {
          response = await UsersAPI.update({ ...requestBody, id: editUser._id });
        }

        response.success && Formik.resetForm();
        setLoading(false);
        responseCallBack(response);
      }, 0);
    } catch (error) {
      console.log('🪵 : onSubmit: : error:', error);
      responseCallBack({ success: false, message: error.response.data.message });
    }
  };

  const isSelectedClass11thOr12th = () =>
    Formik.values.classAttending &&
    (Formik.values.classAttending._id === class11._id || Formik.values.classAttending._id === class12._id);

  const validateBankDetails = (values) => {
    const bankDetails = values.bankDetails;
    const ifscCode = bankDetails.ifscCode;
    const accountNumber = bankDetails.accountNumber;
    const bankName = bankDetails.bankName;
    const branchName = bankDetails.branchName;
    const address = bankDetails.address;
    const city = bankDetails.city;
    const state = bankDetails.state;

    const isValid = isNotBlank(ifscCode) && isNotBlank(accountNumber);

    if (isValid) {
      let returnValue = { ifscCode, accountNumber };

      if (bankName) {
        returnValue = { ...returnValue, bankName };
      }

      if (branchName) {
        returnValue = { ...returnValue, branchName };
      }

      if (address) {
        returnValue = { ...returnValue, address };
      }

      if (city) {
        returnValue = { ...returnValue, city };
      }

      if (state) {
        returnValue = { ...returnValue, state };
      }

      requestBody = { ...requestBody, bankDetails: returnValue };
    }

    return true;
  };

  const validateGovIdDetails = (values) => {
    const collectedGovIds = values.govIds;
    const validGovIds = [];

    if (isBlank(collectedGovIds)) {
      return true;
    }

    for (let index = 0; index < collectedGovIds.length; index++) {
      const govId = collectedGovIds[index];
      const govIdNumber = govId.govIdNumber;
      const govIdType = govId.govIdType;

      if (
        isNotBlank(govIdType) &&
        govIdType.trim().length > 0 &&
        isNotBlank(govIdNumber) &&
        govIdNumber.trim().length > 0
      ) {
        validGovIds.push({
          govIdNumber,
          govIdType: govId.govIdType,
          govIdTypeOthersName: govId.govIdTypeOthersName
        });
      }
    }

    if (isNotBlank(validGovIds)) {
      requestBody = { ...requestBody, govIds: validGovIds };
    }

    return true;
  };

  const validatePersonalDetails = (values) => {
    const image = values.image;

    const firstName = values.firstName;
    const middleName = values.middleName;
    const lastName = values.lastName;
    const dob = values.dob;

    const email = values.email;
    const mobile = values.mobile;
    const role = values.role;
    const shift = values.shift;

    const address = values.address;
    const city = values.city;
    const state = values.state;
    const country = values.country;

    const parentOrSpouseName = values.parentOrSpouseName;
    const parentOrSpouseContact = values.parentOrSpouseContact;
    const emergencyContactRelation = values.emergencyContactRelation;
    const joiningDate = values.joiningDate;
    const childId = values.childId;
    const admissionNo = values.admissionNo;
    const penNo = values.penNo;

    const isValid =
      isNotBlank(image) &&
      isNotBlank(firstName) &&
      isNotBlank(lastName) &&
      isNotBlank(dob) &&
      isNotBlank(mobile) &&
      isNotBlank(role) &&
      isNotBlank(shift) &&
      isNotBlank(address) &&
      isNotBlank(city) &&
      isNotBlank(state) &&
      isNotBlank(country) &&
      isNotBlank(parentOrSpouseName) &&
      isNotBlank(parentOrSpouseContact) &&
      isNotBlank(emergencyContactRelation) &&
      isNotBlank(joiningDate);

    requestBody = null;
    if (isValid) {
      const returnValue = {
        image,
        firstName,
        middleName,
        lastName,
        dob: timestampFromDate({ date: dob }),
        email,
        mobile,
        role,
        shift,
        address,
        city,
        state,
        country,
        parentOrSpouseName,
        parentOrSpouseContact,
        emergencyContactRelation,
        joiningDate: timestampFromDate({ date: joiningDate }),
        childId,
        admissionNo,
        penNo
      };

      requestBody = { ...returnValue };
    }

    return isValid;
  };

  const validateStudentDetails = (values) => {
    const classAttending = values.classAttending;
    const classMedium = values.classMedium;
    const classSection = values.classSection;
    const classGenderGroup = values.classGenderGroup;
    const previousSchool = values.previousSchool;
    let classStream = isNotBlank(values.classStream) ? values.classStream : null;

    let isValid =
      isNotBlank(classAttending) && isNotBlank(classMedium) && isNotBlank(classSection) && isNotBlank(classGenderGroup);

    if (isValid && isNotBlank(classStream) && !isSelectedClass11thOr12th()) {
      setSubmitErrorMessage('Stream should only be added for class 11th and 12th, please remove!');
      classStream = null;
    } else if (isValid && isBlank(classStream) && isSelectedClass11thOr12th()) {
      setSubmitErrorMessage(`Stream should be added for ${classAttending.class}, please add!`);
      isValid = false;
    }

    if (isValid) {
      const returnValue = {
        classAttending,
        classMedium,
        classSection,
        classStream,
        classGenderGroup,
        previousSchool
      };

      requestBody = { ...requestBody, ...returnValue };
    } else {
      requestBody = null;
    }

    return isValid;
  };

  const validateTeacherDetails = (values) => {
    const classesTeaching = values.classesTeaching;
    const classTeacherOfClass = values.classTeacherOfClass;
    const subjectExpertise = values.subjectExpertise;

    const isValid = isNotBlank(classesTeaching) && isNotBlank(classTeacherOfClass) && isNotBlank(subjectExpertise);

    if (isValid) {
      const returnValue = {
        classesTeaching,
        classTeacherOfClass,
        subjectExpertise
      };

      requestBody = { ...requestBody, ...returnValue };
    } else {
      requestBody = null;
    }

    return isValid;
  };

  const validateEmploymentDetails = (values) => {
    const employmentGrade = values.employmentGrade;
    const employmentType = values.employmentType;
    const employmentDesignation = values.employmentDesignation;
    const yearsOfExperience = values.yearsOfExperience;
    const qualification = values.qualification;
    const previousDesignation = values.previousDesignation;
    const previousEmployer = values.previousEmployer;

    const isValid =
      isNotBlank(employmentGrade) &&
      isNotBlank(employmentType) &&
      isNotBlank(employmentDesignation) &&
      isNotBlank(qualification) &&
      isNotBlank(yearsOfExperience);

    if (isValid) {
      const returnValue = {
        employmentGrade,
        employmentType,
        employmentDesignation,
        yearsOfExperience,
        qualification,
        previousDesignation,
        previousEmployer
      };

      requestBody = { ...requestBody, ...returnValue };
    } else {
      requestBody = null;
    }

    return isValid;
  };

  const handleDeleteClassesTeaching = (index) => {
    setClassIndexToBeRemoved(index);
    setShowRemoveClassesTeachingDialog(true);
  };

  const handleDeleteUser = () => {
    setShowDeleteDialog(true);
  };

  const deleteUser = () => {
    try {
      setTimeout(async () => {
        if (isNotBlank(causeOfAction)) {
          let response = null;
          setLoading(true);
          if (isNotBlank(editUser)) {
            response = await UsersAPI.update({
              isDeleted: true,
              causeOfAction,
              id: editUser._id,
              role: editUser.role
            });
          }

          setLoading(false);
          Formik.resetForm();
          responseCallBack(response);
        }
      }, 0);
    } catch (error) {
      console.log('🪵 : deleteUser : error:', error);
    }
  };

  const showCredentials = () => {
    if (showSendCredentialsButton()) {
      setTimeout(async () => {
        setLoading(true);
        try {
          const response = await UsersAPI.smsCredentials({ id: editUser._id });
          console.log('🪵 : setTimeout : response:', response);

          if (isNotBlank(response)) {
            setSendCredentialsAlertMessage(`${sendCredentialsAlertMessage}.\n Message Sent!`);
          } else {
            console.log('🪵 : handleShowCredentials : error:', response.message);
          }
        } catch (error) {
          console.log('🪵 : handleShowCredentials : error:', error);
        }

        setTimeout(() => {
          setShowCredentialsDialog(false);
          setLoading(false);
          setSendCredentialsAlertMessage(`${sendCredentialsAlertMessage}.`);
        }, 300);
      }, 0);
    } else {
      setShowCredentialsDialog(false);
    }
  };

  const handleShowCredentials = () => {
    setTimeout(async () => {
      setLoading(true);
      if (showSendCredentialsButton()) {
        setShowCredentialsDialog(true);
      } else {
        try {
          try {
            await UsersAPI.smsCredentials({ id: editUser._id });
          } catch (error) {
            console.log('\n▶️ -> setTimeout -> error:', error);
          }
          const response = await UsersAPI.credentials({ id: editUser._id });
          if (isNotBlank(response) && isNotBlank(response.user)) {
            setUsername(response.user.username);
            setPassword(response.user.password);

            setShowCredentialsDialog(true);
          } else {
            console.log('🪵 : handleShowCredentials : error:', response.message);
          }
        } catch (error) {
          console.log('🪵 : handleShowCredentials : error:', error);
        }
      }

      setLoading(false);
    }, 0);
  };

  const handleResetForm = () => {
    setShowClearDialog(true);
  };

  const handleCancel = () => {
    setShowCancelDialog(true);
  };

  const continueCancel = () => {
    setShowCancelDialog(false);
    responseCallBack({
      success: false,
      message: editUser ? 'User update canceled.' : 'Registration canceled.',
      registrationCanceled: true
    });
  };

  const handleAddMoreGovernmentIds = () => {
    if (Formik.values.govIds.length === 0) {
      Formik.setFieldValue('govIds', [
        {
          govIdNumber: '',
          govIdType: '',
          govIdTypeOthersName: ''
        }
      ]);
    } else {
      const oldGovIds = Formik.values.govIds;
      const govIdNumber = oldGovIds[oldGovIds.length - 1].govIdNumber;
      let govIdType = oldGovIds[oldGovIds.length - 1].govIdType;

      if (govIdType === 'Other') {
        govIdType = oldGovIds[oldGovIds.length - 1].govIdTypeOthersName;
      }

      if (
        isNotBlank(govIdType) &&
        govIdType.trim().length > 0 &&
        isNotBlank(govIdNumber) &&
        govIdNumber.trim().length > 0
      ) {
        const newGovIds = [...oldGovIds, { govIdNumber: '', govIdType: '', govIdTypeOthersName: '' }];
        Formik.setFieldValue('govIds', newGovIds);
      }
    }
  };

  const handleAddClassesTeaching = () => {
    if (Formik.values.classesTeaching.length === maxSubjectsPerTeacher) {
      setShowMaxSubjectDialog(true);
    } else {
      const filteredValue = Formik.values.classesTeaching.filter(
        (item) =>
          isBlank(item.class) ||
          isBlank(item.classGenderGroup) ||
          isBlank(item.classMedium) ||
          isBlank(item.classSection) ||
          // isBlank(item.classStream) ||
          isBlank(item.subject)
      );

      if (filteredValue.length === 0) {
        Formik.setFieldValue('classesTeaching', [
          ...Formik.values.classesTeaching,
          {
            class: classes[0],
            classGenderGroup: classGenderGroups[0],
            classMedium: mediums[0],
            classSection: sections[0],
            classStream: undefined,
            subject: subjects[0]
          }
        ]);
      }
    }
  };

  const removeClassesTeaching = () => {
    if (classIndexToBeRemoved !== null) {
      let arrayToFilter = Formik.values.classesTeaching;
      arrayToFilter.splice(classIndexToBeRemoved, 1);
      Formik.setFieldValue('classesTeaching', arrayToFilter);

      setClassIndexToBeRemoved(undefined);
      setShowRemoveClassesTeachingDialog(false);
    }
  };

  const maxSubjectPerTeacherAlert = () => (
    <AlertDialog
      title={'Max Subjects Added'}
      message={`Only ${maxSubjectsPerTeacher} subjects can be added per teacher`}
      showDialog={showMaxSubjectDialog}
      setShowDialog={setShowMaxSubjectDialog}
      cancelOnlyDialog
    />
  );

  const cancelRegistrationAlert = () => (
    <AlertDialog
      cancelButtonTitle='No'
      continueButtonTitle='Yes'
      title={cancelAlertTitle}
      message={clearMessage}
      showDialog={showCancelDialog}
      setShowDialog={setShowCancelDialog}
      onContinue={continueCancel}
    />
  );

  const clearAllEntriesAlert = () => (
    <AlertDialog
      cancelButtonTitle='No'
      continueButtonTitle='Yes'
      title={clearAlertTitle}
      message={clearMessage}
      showDialog={showClearDialog}
      setShowDialog={setShowClearDialog}
      onContinue={handleReset}
    />
  );

  const showCredentialsAlert = () => (
    <AlertDialog
      cancelOnlyDialog={showCredentialsButton()}
      continueButtonTitle={'Send'}
      title={showSendCredentialsButton() ? sendCredentialsAlertTitle : showCredentialsAlertTitle}
      message={showSendCredentialsButton() ? sendCredentialsAlertMessage : showCredentialsAlertMessage}
      showDialog={showCredentialsDialog}
      setShowDialog={setShowCredentialsDialog}
      onContinue={showCredentials}
    >
      {showCredentialsButton() && (
        <Stack
          spacing={2}
          direction='column'
          justifyContent='space-around'
          alignItems='center'
          style={{ width: '100%' }}
        >
          <label>{`Username: ${username}`}</label>
          <label>{`Password: ${password}`}</label>
        </Stack>
      )}
    </AlertDialog>
  );

  const deleteUserAlert = () => (
    <AlertDialog
      cancelButtonTitle='No'
      continueButtonTitle='Yes'
      title={deleteAlertTitle}
      message={deleteAlertMessage}
      showDialog={showDeleteDialog}
      setShowDialog={setShowDeleteDialog}
      onContinue={deleteUser}
    >
      <TextareaAutosize
        id='causeOfAction'
        type='causeOfAction'
        variant='outlined'
        color='secondary'
        label='Reason For Deletion'
        value={causeOfAction}
        onChange={(e) => setCauseOfAction(e.target.value)}
        minRows={3}
        maxRows={5}
        aria-label='maximum height'
        placeholder='Reason for deleting the user is mandatory'
      />
    </AlertDialog>
  );

  const removeClassesTeachingDialog = () => (
    <AlertDialog
      cancelButtonTitle='No'
      continueButtonTitle='Yes'
      title={removeClassesTeachingAlertTitle}
      message={removeClassesTeachingMessage}
      showDialog={showRemoveClassesTeachingDialog}
      setShowDialog={setShowRemoveClassesTeachingDialog}
      onContinue={removeClassesTeaching}
    />
  );

  const verticalDivider = () => <Divider orientation='vertical' flexItem />;
  const horizontalDivider = () => <Divider orientation='horizontal' flexItem />;

  const formTitleAndCloseIcon = () => {
    let titleValue = actionRole.role;

    return (
      <Stack direction='row' spacing={1}>
        <h4
          style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            width: '-webkit-fill-available'
          }}
        >{`${isBlank(editUser) ? 'Register' : 'Update'} ${titleValue}`}</h4>

        <IconButton aria-label='delete' color='primary' style={{ width: '40px' }} onClick={handleCancel}>
          <CloseOutlined />
        </IconButton>
      </Stack>
    );
  };

  const formSubTitle = (subTitle) => <h5>{subTitle}</h5>;

  const personalDetailsSection = () => (
    <>
      {formSubTitle('Personal Details')}
      <Stack spacing={2} direction='row' sx={{ marginTop: 1, marginBottom: 4 }}>
        <TextField
          id='firstName'
          type='text'
          variant='outlined'
          color='secondary'
          label='First Name'
          value={Formik.values.firstName}
          onChange={Formik.handleChange}
          onBlur={Formik.handleBlur}
          fullWidth
          required
          error={isNotBlank(Formik.touched.firstName && Formik.errors.firstName)}
          helperText={Formik.errors.firstName}
        />

        <TextField
          id='middleName'
          type='text'
          variant='outlined'
          color='secondary'
          label='Middle Name'
          value={Formik.values.middleName}
          onChange={Formik.handleChange}
          onBlur={Formik.handleBlur}
          fullWidth
        />

        <TextField
          id='lastName'
          type='text'
          variant='outlined'
          color='secondary'
          label='Last Name'
          value={Formik.values.lastName}
          onChange={Formik.handleChange}
          onBlur={Formik.handleBlur}
          fullWidth
          required
          error={isNotBlank(Formik.touched.lastName && Formik.errors.lastName)}
          helperText={Formik.errors.lastName}
        />

        <DatePicker
          id='dob-datepicker'
          label='Date of Birth'
          value={Formik.values.dob}
          onChange={(date) => Formik.setFieldValue('dob', formattedDate({ date }))}
          onBlur={Formik.handleBlur('dob')}
          fullWidth
          required
          variant='outlined'
          color='secondary'
          format='dd/MM/yyyy'
          slotProps={{
            layout: { sx: datePickerCustomStyle },
            textField: {
              id: 'dob',
              name: 'dob',
              fullWidth: true,
              required: true,
              variant: 'outlined',
              color: 'secondary',
              format: 'DD/MM/YYYY',
              value: Formik.values.dob,
              onChange: (date) => Formik.setFieldValue('dob', formattedDate({ date })),
              onBlur: Formik.handleBlur('dob'),
              error: isNotBlank(Formik.touched.dob && Formik.errors.dob),
              helperText: Formik.touched.dob && Formik.errors.dob
            }
          }}
        />
      </Stack>
      <Stack spacing={2} direction='row' sx={{ marginBottom: 4 }}>
        <TextField
          id='email'
          type='email'
          variant='outlined'
          color='secondary'
          label='Email'
          value={Formik.values.email}
          onChange={Formik.handleChange}
          onBlur={Formik.handleBlur}
          fullWidth
          error={isNotBlank(Formik.touched.email && Formik.errors.email)}
          helperText={Formik.errors.email}
        />

        <TextField
          id='mobile'
          type='mobile'
          variant='outlined'
          color='secondary'
          label='Mobile'
          value={Formik.values.mobile}
          onChange={Formik.handleChange}
          onBlur={Formik.handleBlur}
          required
          fullWidth
          error={isNotBlank(Formik.touched.mobile && Formik.errors.mobile)}
          helperText={Formik.errors.mobile}
        />

        <Autocomplete
          id='role-autocomplete'
          sx={{ ...autoCompleteCustomStyle }}
          required
          fullWidth
          disablePortal
          autoHighlight
          options={roles}
          getOptionLabel={(option) => (option.role ? option.role : '')}
          isOptionEqualToValue={(option, value) => option._id === value._id || isBlank(value)}
          value={Formik.values.role || null}
          onChange={(e, value) => {
            Formik.setFieldValue('role', value);

            if (isNotBlank(value)) {
              setActionRole(value);
              setValidationSchema(schemaForUserType(value.role));
            }
          }}
          onBlur={Formik.handleBlur('role')}
          renderInput={(params) => (
            <TextField
              {...params}
              id='role'
              name='role'
              type='text'
              variant='outlined'
              color='secondary'
              label='Role'
              required
              fullWidth
              error={isNotBlank(Formik.touched.role && Formik.errors.role)}
              helperText={Formik.errors.role}
            />
          )}
        />

        <Autocomplete
          id='shift-autocomplete'
          sx={{ ...autoCompleteCustomStyle }}
          required
          fullWidth
          disablePortal
          autoHighlight
          options={shifts}
          getOptionLabel={(option) => (option.shift ? option.shift : '')}
          isOptionEqualToValue={(option, value) => option._id === value._id || isBlank(value)}
          value={Formik.values.shift || null}
          onChange={(e, value) => Formik.setFieldValue('shift', value)}
          onBlur={Formik.handleBlur('shift')}
          renderInput={(params) => (
            <TextField
              {...params}
              id='shift'
              name='shift'
              type='text'
              variant='outlined'
              color='secondary'
              label='Shift'
              required
              fullWidth
              error={isNotBlank(Formik.touched.shift && Formik.errors.shift)}
              helperText={Formik.errors.shift}
            />
          )}
        />
      </Stack>
      <Stack spacing={2} direction='row' sx={{ marginBottom: 4 }}>
        <TextField
          id='address'
          type='text'
          variant='outlined'
          color='secondary'
          label='Address'
          value={Formik.values.address}
          onChange={Formik.handleChange}
          onBlur={Formik.handleBlur}
          fullWidth
          required
          error={isNotBlank(Formik.touched.address && Formik.errors.address)}
          helperText={Formik.errors.address}
        />

        <Autocomplete
          id='city-autocomplete'
          sx={{
            ...autoCompleteCustomStyle
          }}
          fullWidth
          required
          disablePortal
          autoHighlight
          options={cities}
          getOptionLabel={(option) => (option.city ? option.city : '')}
          isOptionEqualToValue={(option, value) => option._id === value._id || isBlank(value)}
          value={Formik.values.city || null}
          onChange={(e, value) => {
            if (value) {
              Formik.setFieldValue('city', value);
              Formik.setFieldValue('state', value.state);
              Formik.setFieldValue('country', value.country);
            }
          }}
          onBlur={Formik.handleBlur('city')}
          renderInput={(params) => (
            <TextField
              {...params}
              id='city'
              name='city'
              type='text'
              variant='outlined'
              color='secondary'
              label='City'
              required
              fullWidth
              error={isNotBlank(Formik.touched.city && Formik.errors.city)}
              helperText={Formik.errors.city}
            />
          )}
        />
        <TextField
          id='state'
          type='text'
          variant='outlined'
          color='secondary'
          label='State'
          disabled
          value={Formik.values.state.state || ''}
          onChange={(e, value) => Formik.setFieldValue('state', value)}
          onBlur={Formik.handleBlur('state')}
          fullWidth
          // required
          // error={isNotBlank(Formik.touched.address && Formik.errors.address)}
          // helperText={Formik.errors.address}
        />

        <TextField
          id='country'
          type='text'
          variant='outlined'
          color='secondary'
          label='Country'
          disabled
          value={Formik.values.country.country || ''}
          onChange={(e, value) => Formik.setFieldValue('country', value)}
          onBlur={Formik.handleBlur('country')}
          fullWidth
          // required
          // error={isNotBlank(Formik.touched.address && Formik.errors.address)}
          // helperText={Formik.errors.address}
        />

        {/* <Autocomplete
          id='state-autocomplete'
          sx={{
            ...autoCompleteCustomStyle
          }}
          fullWidth
          required
          disabled
          disablePortal
          autoHighlight
          options={states}
          getOptionLabel={(option) => option.state || ''}
          isOptionEqualToValue={(option, value) => option._id === value._id || isBlank(value)}
          value={Formik.values.city && Formik.values.city.state ? Formik.values.city.state : ''}
          onChange={(e, value) => Formik.setFieldValue('state', value)}
          onBlur={Formik.handleBlur('state')}
          renderInput={(params) => (
            <TextField
              {...params}
              id='state'
              name='state'
              type='text'
              variant='outlined'
              color='secondary'
              label='State'
              required
              fullWidth
              error={isNotBlank(Formik.touched.state && Formik.errors.state)}
              helperText={Formik.errors.state}
            />
          )}
        />
        <Autocomplete
          id='country-autocomplete'
          sx={{
            ...autoCompleteCustomStyle
          }}
          fullWidth
          required
          disabled
          disablePortal
          autoHighlight
          options={countries}
          getOptionLabel={(option) => option.country || ''}
          isOptionEqualToValue={(option, value) => option._id === value._id || isBlank(value)}
          value={Formik.values.city && Formik.values.city.country ? Formik.values.city.country : ''}
          onChange={(e, value) => Formik.setFieldValue('country', value)}
          onBlur={Formik.handleBlur('country')}
          renderInput={(params) => (
            <TextField
              {...params}
              id='country'
              name='country'
              type='text'
              variant='outlined'
              color='secondary'
              label='Country'
              required
              fullWidth
              error={isNotBlank(Formik.touched.country && Formik.errors.country)}
              helperText={Formik.errors.country}
            />
          )}
        /> */}
      </Stack>
      <Stack spacing={2} direction='row' sx={{ marginBottom: 4 }}>
        <TextField
          id='parentOrSpouseName'
          type='text'
          variant='outlined'
          color='secondary'
          label='Guardian Or Spouse Name'
          value={Formik.values.parentOrSpouseName}
          onChange={Formik.handleChange}
          onBlur={Formik.handleBlur}
          required
          fullWidth
          error={isNotBlank(Formik.touched.parentOrSpouseName && Formik.errors.parentOrSpouseName)}
          helperText={Formik.errors.parentOrSpouseName}
        />
        <TextField
          id='parentOrSpouseContact'
          type='mobile'
          variant='outlined'
          color='secondary'
          label='Guardian Or Spouse Mobile'
          value={Formik.values.parentOrSpouseContact}
          onChange={Formik.handleChange}
          onBlur={Formik.handleBlur}
          required
          fullWidth
          error={isNotBlank(Formik.touched.parentOrSpouseContact && Formik.errors.parentOrSpouseContact)}
          helperText={Formik.errors.parentOrSpouseContact}
        />
        <Autocomplete
          id='emergencyContactRelation-autocomplete'
          sx={{ ...autoCompleteCustomStyle }}
          required
          fullWidth
          disablePortal
          autoHighlight
          options={relations}
          getOptionLabel={(option) => (option.relation ? option.relation : '')}
          isOptionEqualToValue={(option, value) => option._id === value._id || isBlank(value)}
          value={Formik.values.emergencyContactRelation || null}
          onChange={(e, value) => Formik.setFieldValue('emergencyContactRelation', value)}
          onBlur={Formik.handleBlur('emergencyContactRelation')}
          renderInput={(params) => (
            <TextField
              {...params}
              id='emergencyContactRelation'
              name='emergencyContactRelation'
              type='text'
              variant='outlined'
              color='secondary'
              label='Relation'
              required
              fullWidth
              error={isNotBlank(Formik.touched.emergencyContactRelation && Formik.errors.emergencyContactRelation)}
              helperText={Formik.errors.emergencyContactRelation}
            />
          )}
        />

        <Autocomplete
          id='empty-autocomplete'
          sx={{ ...autoCompleteCustomStyle }}
          required
          fullWidth
          options={['']}
          renderInput={(params) => <TextField {...params} id='empty' required fullWidth hidden />}
        />
      </Stack>

      {actionRole.role === 'Student' && (
        <Stack spacing={2} direction='row' sx={{ marginBottom: 4 }}>
          <TextField
            id='childId'
            sx={{ maxWidth: defaultFieldWidth, ...autoCompleteCustomStyle }}
            type='text'
            variant='outlined'
            color='secondary'
            label='Child ID'
            value={Formik.values.childId}
            onChange={Formik.handleChange}
            onBlur={Formik.handleBlur}
            fullWidth
          />

          <TextField
            id='admissionNo'
            sx={{ maxWidth: defaultFieldWidth, ...autoCompleteCustomStyle }}
            type='text'
            variant='outlined'
            color='secondary'
            label='Admission Number'
            value={Formik.values.admissionNo}
            onChange={Formik.handleChange}
            onBlur={Formik.handleBlur}
            fullWidth
          />

          <TextField
            id='penNo'
            sx={{ maxWidth: defaultFieldWidth, ...autoCompleteCustomStyle }}
            type='text'
            variant='outlined'
            color='secondary'
            label='Permanent Education Number'
            value={Formik.values.penNo}
            onChange={Formik.handleChange}
            onBlur={Formik.handleBlur}
            fullWidth
          />

          <Autocomplete
            id='empty-autocomplete'
            sx={{ ...autoCompleteCustomStyle }}
            required
            fullWidth
            options={['']}
            renderInput={(params) => <TextField {...params} id='empty' required fullWidth hidden />}
          />
        </Stack>
      )}
    </>
  );

  const teacherSpecificDetailsSection = () => (
    <Stack spacing={3} direction='column' sx={{ marginTop: 1, marginBottom: 3 }}>
      <Stack spacing={2} direction='row' sx={{ marginTop: 1, marginBottom: 4 }}>
        <Autocomplete
          id='subjectExpertise-autocomplete'
          sx={{
            maxWidth: '60%',
            ...autoCompleteCustomStyle
          }}
          fullWidth
          multiple
          required
          disablePortal
          autoHighlight
          options={subjects}
          getOptionLabel={(option) => (option.subject ? option.subject : '')}
          isOptionEqualToValue={(option, value) => option._id === value._id || isBlank(value)}
          // filterSelectedOptions
          value={(Formik.values.subjectExpertise.length > 0 && Formik.values.subjectExpertise) || []}
          defaultValue={[subjects[0]]}
          onChange={(e, value) => {
            console.log('🪵 : value:', value);
            Formik.setFieldValue('subjectExpertise', isNotBlank(value) ? value : []);
            if (isBlank(value)) {
              Formik.setFieldValue('classesTeaching', []);
            } else {
              const newArray = Formik.values.classesTeaching.filter((item) => value.includes(item.subject));
              Formik.setFieldValue('classesTeaching', newArray);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              id='subjectExpertise'
              name='subjectExpertise'
              type='text'
              variant='outlined'
              color='secondary'
              label='Subject Expertise'
              required
              fullWidth
              error={isNotBlank(Formik.touched.subjectExpertise && Formik.errors.subjectExpertise)}
              helperText={Formik.errors.subjectExpertise}
            />
          )}
        />
        {Formik.values.subjectExpertise.length > 0 && (
          <Button
            sx={{ maxWidth: '40%', maxHeight: 55 }}
            variant='outlined'
            color='primary'
            type='button'
            onClick={handleAddClassesTeaching}
          >
            {`Add Classes Taught By ${Formik.values.firstName || ''} Ji`}
          </Button>
        )}
      </Stack>

      <Stack spacing={2} direction='column'>
        {Formik.values.classesTeaching.length > 0 &&
          Formik.values.classesTeaching.map((item, index) => (
            <Card variant='outlined' key={`${index} card`}>
              <CardContent>
                <Stack key={`${index} first`} spacing={2} direction='row' sx={{ marginTop: 1 }}>
                  <Stack key={`${index} second`} spacing={2} sx={{ marginTop: 1, marginBottom: 4, width: '100%' }}>
                    <Stack key={`${index} third`} spacing={2} direction='row' sx={{ marginTop: 1, marginBottom: 4 }}>
                      <Autocomplete
                        id={`classesTeaching.class-autocomplete-${index}`}
                        sx={{ ...autoCompleteCustomStyle }}
                        fullWidth
                        required
                        disablePortal
                        autoHighlight
                        options={classes}
                        isOptionEqualToValue={(option, value) => option._id === value._id || isBlank(value)}
                        getOptionLabel={(option) => (option.class ? option.class : '')}
                        value={
                          Formik.values.classesTeaching.length > index
                            ? Formik.values.classesTeaching[index].class || null
                            : null
                          // || Formik.setFieldValue(`classesTeaching[${index}].class`, arrayOfClassesTeaching[0].class)
                        }
                        onChange={(e, value) => {
                          Formik.setFieldValue('classTeacherOfClass', null);
                          // const oldValues =
                          //   Formik.values.classesTeaching.length > index ? Formik.values.classesTeaching[index] : {};
                          // const newValues = { ...oldValues, class: value };
                          // let oldValuesArray = Formik.values.classesTeaching.length > index ? Formik.values.classesTeaching : [];
                          // oldValuesArray[index] = newValues;
                          // Formik.setFieldValue('classesTeaching', oldValuesArray);

                          // let tempArray = arrayOfClassesTeaching;
                          // tempArray[index] = newValues;
                          // setArrayOfClassesTeaching(tempArray);

                          Formik.setFieldValue(`classesTeaching[${index}].class`, value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id='classesTeaching'
                            name='classesTeaching'
                            type='text'
                            variant='outlined'
                            color='secondary'
                            label='Class Teaching'
                            required
                            fullWidth
                            error={isNotBlank(
                              Formik.touched.classesTeaching &&
                                Formik.touched.classesTeaching.length > index &&
                                Formik.touched.classesTeaching[index] &&
                                Formik.touched.classesTeaching[index].class &&
                                Formik.errors.classesTeaching &&
                                Formik.errors.classesTeaching.length > index &&
                                Formik.errors.classesTeaching[index] &&
                                Formik.errors.classesTeaching[index].class
                            )}
                            helperText={
                              Formik.errors.classesTeaching &&
                              Formik.errors.classesTeaching.length > index &&
                              Formik.errors.classesTeaching[index] &&
                              Formik.errors.classesTeaching[index].class
                            }
                          />
                        )}
                      />

                      <Autocomplete
                        id='classesTeaching.classMedium-autocomplete'
                        sx={{ ...autoCompleteCustomStyle }}
                        fullWidth
                        required
                        disablePortal
                        autoHighlight
                        options={mediums}
                        getOptionLabel={(option) => option.classMedium || ''}
                        isOptionEqualToValue={(option, value) => option._id === value._id || isBlank(value)}
                        value={
                          Formik.values.classesTeaching.length > index
                            ? Formik.values.classesTeaching[index].classMedium || null
                            : null
                          // || Formik.setFieldValue(`classesTeaching[${index}].classMedium`, arrayOfClassesTeaching[0].classMedium)
                        }
                        onChange={(e, value) => {
                          Formik.setFieldValue(`classesTeaching[${index}].classMedium`, value);

                          // const oldValues =
                          //   Formik.values.classesTeaching.length > index ? Formik.values.classesTeaching[index] : {};
                          // const newValues = { ...oldValues, classMedium: value };
                          // let oldValuesArray = Formik.values.classesTeaching.length > index ? Formik.values.classesTeaching : [];
                          // oldValuesArray[index] = newValues;
                          // Formik.setFieldValue('classesTeaching', oldValuesArray);
                          // let tempArray = arrayOfClassesTeaching;
                          // tempArray[index] = newValues;
                          // setArrayOfClassesTeaching(tempArray);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id='classesTeaching'
                            name='classesTeaching'
                            type='text'
                            variant='outlined'
                            color='secondary'
                            label='Class Medium'
                            required
                            fullWidth
                            error={isNotBlank(
                              Formik.touched.classesTeaching &&
                                Formik.touched.classesTeaching.length > index &&
                                Formik.touched.classesTeaching[index] &&
                                Formik.touched.classesTeaching[index].classMedium &&
                                Formik.errors.classesTeaching &&
                                Formik.errors.classesTeaching.length > index &&
                                Formik.errors.classesTeaching[index] &&
                                Formik.errors.classesTeaching[index].classMedium
                            )}
                            helperText={
                              Formik.errors.classesTeaching &&
                              Formik.errors.classesTeaching.length > index &&
                              Formik.errors.classesTeaching[index] &&
                              Formik.errors.classesTeaching[index].classMedium
                            }
                          />
                        )}
                      />
                      <Autocomplete
                        id='classesTeaching.classSection-autocomplete'
                        sx={{ ...autoCompleteCustomStyle }}
                        fullWidth
                        required
                        disablePortal
                        autoHighlight
                        options={sections}
                        getOptionLabel={(option) => option.classSection || ''}
                        isOptionEqualToValue={(option, value) => option._id === value._id || isBlank(value)}
                        value={
                          Formik.values.classesTeaching.length > index
                            ? Formik.values.classesTeaching[index].classSection || null
                            : null
                          // || Formik.setFieldValue(`classesTeaching[${index}].classMedium`, arrayOfClassesTeaching[0].classSection)
                        }
                        onChange={(e, value) => {
                          Formik.setFieldValue(`classesTeaching[${index}].classSection`, value);

                          // const oldValues =
                          //   Formik.values.classesTeaching.length > index ? Formik.values.classesTeaching[index] : {};
                          // const newValues = { ...oldValues, classSection: value };
                          // let oldValuesArray = Formik.values.classesTeaching.length > index ? Formik.values.classesTeaching : [];
                          // oldValuesArray[index] = newValues;
                          // Formik.setFieldValue('classesTeaching', oldValuesArray);
                          // let tempArray = arrayOfClassesTeaching;
                          // tempArray[index] = newValues;
                          // setArrayOfClassesTeaching(tempArray);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id='classesTeaching'
                            name='classesTeaching'
                            type='text'
                            variant='outlined'
                            color='secondary'
                            label='Section'
                            required
                            fullWidth
                            error={isNotBlank(
                              Formik.touched.classesTeaching &&
                                Formik.touched.classesTeaching.length > index &&
                                Formik.touched.classesTeaching[index] &&
                                Formik.touched.classesTeaching[index].classSection &&
                                Formik.errors.classesTeaching &&
                                Formik.errors.classesTeaching.length > index &&
                                Formik.errors.classesTeaching[index] &&
                                Formik.errors.classesTeaching[index].classSection
                            )}
                            helperText={
                              Formik.errors.classesTeaching &&
                              Formik.errors.classesTeaching.length > index &&
                              Formik.errors.classesTeaching[index] &&
                              Formik.errors.classesTeaching[index].classSection
                            }
                          />
                        )}
                      />
                    </Stack>
                    <Stack key={`${index} fourth`} spacing={2} direction='row' sx={{ marginTop: 1, marginBottom: 4 }}>
                      {(Formik.values.classesTeaching[index].class.class.indexOf('11') !== -1 ||
                        Formik.values.classesTeaching[index].class.class.indexOf('12') !== -1) && (
                        <Autocomplete
                          id='classesTeaching.classStream-autocomplete'
                          sx={{ ...autoCompleteCustomStyle }}
                          fullWidth
                          // required
                          disablePortal
                          autoHighlight
                          options={streams}
                          getOptionLabel={(option) => (option.stream ? option.stream : '')}
                          isOptionEqualToValue={(option, value) => option._id === value._id || isBlank(value)}
                          value={
                            Formik.values.classesTeaching.length > index
                              ? Formik.values.classesTeaching[index].classStream || null
                              : null
                            // || Formik.setFieldValue(`classesTeaching[${index}].classStream`, arrayOfClassesTeaching[0].classStream)
                          }
                          onChange={(e, value) => {
                            Formik.setFieldValue(`classesTeaching[${index}].classStream`, value);

                            // const oldValues =
                            //   Formik.values.classesTeaching.length > index ? Formik.values.classesTeaching[index] : {};
                            // const newValues = { ...oldValues, classStream: value };
                            // let oldValuesArray = Formik.values.classesTeaching.length > index ? Formik.values.classesTeaching : [];
                            // oldValuesArray[index] = newValues;
                            // Formik.setFieldValue('classesTeaching', oldValuesArray);
                            // let tempArray = arrayOfClassesTeaching;
                            // tempArray[index] = newValues;
                            // setArrayOfClassesTeaching(tempArray);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id='classStream'
                              name='classStream'
                              type='text'
                              variant='outlined'
                              color='secondary'
                              label='Class Stream'
                              error={isNotBlank(
                                Formik.touched.classesTeaching &&
                                  Formik.touched.classesTeaching.length > index &&
                                  Formik.touched.classesTeaching[index] &&
                                  Formik.touched.classesTeaching[index].classStream &&
                                  Formik.errors.classesTeaching &&
                                  Formik.errors.classesTeaching.length > index &&
                                  Formik.errors.classesTeaching[index] &&
                                  Formik.errors.classesTeaching[index].classStream
                              )}
                              helperText={
                                Formik.errors.classesTeaching &&
                                Formik.errors.classesTeaching.length > index &&
                                Formik.errors.classesTeaching[index] &&
                                Formik.errors.classesTeaching[index].classStream
                              }
                            />
                          )}
                        />
                      )}

                      <Autocomplete
                        id='classesTeaching.classGenderGroup-autocomplete'
                        sx={{ ...autoCompleteCustomStyle }}
                        fullWidth
                        required
                        disablePortal
                        autoHighlight
                        options={classGenderGroups}
                        getOptionLabel={(option) => option.classGenderGroup || ''}
                        isOptionEqualToValue={(option, value) => option._id === value._id || isBlank(value)}
                        value={
                          Formik.values.classesTeaching.length > index
                            ? Formik.values.classesTeaching[index].classGenderGroup || null
                            : null
                          // || Formik.setFieldValue(
                          //   `classesTeaching[${index}].classGenderGroup`,
                          //   arrayOfClassesTeaching[0].classGenderGroup
                          // )
                        }
                        onChange={(e, value) => {
                          Formik.setFieldValue(`classesTeaching[${index}].classGenderGroup`, value);

                          // const oldValues =
                          //   Formik.values.classesTeaching.length > index ? Formik.values.classesTeaching[index] : {};
                          // const newValues = { ...oldValues, classGenderGroup: value };
                          // let oldValuesArray = Formik.values.classesTeaching.length > index ? Formik.values.classesTeaching : [];
                          // oldValuesArray[index] = newValues;
                          // Formik.setFieldValue('classesTeaching', oldValuesArray);
                          // let tempArray = arrayOfClassesTeaching;
                          // tempArray[index] = newValues;
                          // setArrayOfClassesTeaching(tempArray);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id='classesTeaching'
                            name='classesTeaching'
                            type='text'
                            variant='outlined'
                            color='secondary'
                            label='Class Group'
                            required
                            fullWidth
                            error={isNotBlank(
                              Formik.touched.classesTeaching &&
                                Formik.touched.classesTeaching.length > index &&
                                Formik.touched.classesTeaching[index] &&
                                Formik.touched.classesTeaching[index].classGenderGroup &&
                                Formik.errors.classesTeaching &&
                                Formik.errors.classesTeaching.length > index &&
                                Formik.errors.classesTeaching[index] &&
                                Formik.errors.classesTeaching[index].classGenderGroup
                            )}
                            helperText={
                              Formik.errors.classesTeaching &&
                              Formik.errors.classesTeaching.length > index &&
                              Formik.errors.classesTeaching[index] &&
                              Formik.errors.classesTeaching[index].classGenderGroup
                            }
                          />
                        )}
                      />

                      <Autocomplete
                        id='classesTeaching.subject-autocomplete'
                        sx={{ ...autoCompleteCustomStyle }}
                        fullWidth
                        required
                        disablePortal
                        autoHighlight
                        options={Formik.values.subjectExpertise}
                        getOptionLabel={(option) => option.subject || ''}
                        isOptionEqualToValue={(option, value) => option._id === value._id || isBlank(value)}
                        value={
                          Formik.values.classesTeaching.length > index
                            ? Formik.values.classesTeaching[index].subject || null
                            : null
                          // || Formik.setFieldValue(
                          //   `classesTeaching[${index}].subject`,
                          //   Formik.values.subjectExpertise.length > 0 ? Formik.values.subjectExpertise[0].subject : null
                          // )
                        }
                        onChange={(e, value) => {
                          Formik.setFieldValue(`classesTeaching[${index}].subject`, value);

                          // const oldValues =
                          //   Formik.values.classesTeaching.length > index ? Formik.values.classesTeaching[index] : {};
                          // const newValues = { ...oldValues, subject: value };
                          // let oldValuesArray = Formik.values.classesTeaching.length > index ? Formik.values.classesTeaching : [];
                          // oldValuesArray[index] = newValues;
                          // Formik.setFieldValue('classesTeaching', oldValuesArray);
                          // let tempArray = arrayOfClassesTeaching;
                          // tempArray[index] = newValues;
                          // setArrayOfClassesTeaching(tempArray);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id='classesTeaching'
                            name='classesTeaching'
                            type='text'
                            variant='outlined'
                            color='secondary'
                            label='Subject Teaching'
                            required
                            fullWidth
                            error={isNotBlank(
                              Formik.touched.classesTeaching &&
                                Formik.touched.classesTeaching.length > index &&
                                Formik.touched.classesTeaching[index] &&
                                Formik.touched.classesTeaching[index].subject &&
                                Formik.errors.classesTeaching &&
                                Formik.errors.classesTeaching.length > index &&
                                Formik.errors.classesTeaching[index] &&
                                Formik.errors.classesTeaching[index].subject
                            )}
                            helperText={
                              Formik.errors.classesTeaching &&
                              Formik.errors.classesTeaching.length > index &&
                              Formik.errors.classesTeaching[index] &&
                              Formik.errors.classesTeaching[index].subject
                            }
                          />
                        )}
                      />
                    </Stack>
                  </Stack>
                  <IconButton
                    aria-label='delete'
                    size='large'
                    sx={{ maxWidth: '40px' }}
                    onClick={() => handleDeleteClassesTeaching(index)}
                  >
                    <DeleteIcon fontSize='inherit' />
                  </IconButton>
                </Stack>
              </CardContent>
            </Card>
          ))}
      </Stack>

      <Stack spacing={2} direction='row' sx={{ marginTop: 2, marginBottom: 4 }}>
        {Formik.values.classesTeaching.length > 0 && (
          <Autocomplete
            id='classTeacherOfClass-autocomplete'
            sx={{ maxWidth: defaultFieldWidth, ...autoCompleteCustomStyle }}
            required
            fullWidth
            disablePortal
            autoHighlight
            options={
              Formik.values.classesTeaching.length > 0
                ? [...new Set(Formik.values.classesTeaching.map((item) => item.class))]
                : []
            }
            getOptionLabel={(option) => option.class || ''}
            // isOptionEqualToValue={(option, value) => option._id === value._id || isBlank(value)}
            value={
              Formik.values.classTeacherOfClass || null
              // || Formik.setFieldValue('classTeacherOfClass', arrayOfClassesTeaching.map((item) => item.class)[0])
            }
            onChange={(e, value) => {
              Formik.setFieldValue('classTeacherOfClass', value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                id='classTeacherOfClass'
                name='classTeacherOfClass'
                type='text'
                variant='outlined'
                color='secondary'
                label='Class Teacher Of Class'
                required
                fullWidth
                error={isNotBlank(Formik.touched.classTeacherOfClass && Formik.errors.classTeacherOfClass)}
                helperText={Formik.errors.classTeacherOfClass}
              />
            )}
          />
        )}
      </Stack>
    </Stack>
  );

  const studentSpecificDetailsSection = () => (
    <>
      <Stack spacing={2} direction='row' sx={{ marginTop: 1, marginBottom: 4 }}>
        <Autocomplete
          id='classAttending-autocomplete'
          sx={{ ...autoCompleteCustomStyle }}
          fullWidth
          required
          disablePortal
          autoHighlight
          options={classes}
          isOptionEqualToValue={(option, value) => option._id === value._id || isBlank(value)}
          getOptionLabel={(option) => option.class || ''}
          value={Formik.values.classAttending || null}
          onChange={(e, value) => {
            Formik.setFieldValue('classAttending', value);
            Formik.setFieldValue('classStream', null);
          }}
          onBlur={Formik.handleBlur('classAttending')}
          renderInput={(params) => (
            <TextField
              {...params}
              id='classAttending'
              name='classAttending'
              type='text'
              variant='outlined'
              color='secondary'
              label='Class'
              required
              fullWidth
              error={isNotBlank(Formik.touched.classAttending && Formik.errors.classAttending)}
              helperText={Formik.errors.classAttending}
            />
          )}
        />

        <Autocomplete
          id='classMedium-autocomplete'
          sx={{ ...autoCompleteCustomStyle }}
          fullWidth
          required
          disablePortal
          autoHighlight
          options={mediums}
          getOptionLabel={(option) => option.classMedium || ''}
          isOptionEqualToValue={(option, value) => option._id === value._id || isBlank(value)}
          value={Formik.values.classMedium || null}
          onChange={(e, value) => Formik.setFieldValue('classMedium', value)}
          onBlur={Formik.handleBlur('classMedium')}
          renderInput={(params) => (
            <TextField
              {...params}
              id='classMedium'
              name='classMedium'
              type='text'
              variant='outlined'
              color='secondary'
              label='Medium'
              required
              fullWidth
              error={isNotBlank(Formik.touched.classMedium && Formik.errors.classMedium)}
              helperText={Formik.errors.classMedium}
            />
          )}
        />

        <Autocomplete
          id='classSection-autocomplete'
          sx={{ ...autoCompleteCustomStyle }}
          fullWidth
          required
          disablePortal
          autoHighlight
          options={sections}
          getOptionLabel={(option) => option.classSection || ''}
          isOptionEqualToValue={(option, value) => option._id === value._id || isBlank(value)}
          value={Formik.values.classSection || null}
          onChange={(e, value) => Formik.setFieldValue('classSection', value)}
          onBlur={Formik.handleBlur('classSection')}
          renderInput={(params) => (
            <TextField
              {...params}
              id='classSection'
              name='classSection'
              type='text'
              variant='outlined'
              color='secondary'
              label='Sections'
              required
              fullWidth
              error={isNotBlank(Formik.touched.classSection && Formik.errors.classSection)}
              helperText={Formik.errors.classSection}
            />
          )}
        />

        <Autocomplete
          id='classGenderGroup-autocomplete'
          sx={{ ...autoCompleteCustomStyle }}
          required
          disablePortal
          autoHighlight
          fullWidth
          options={classGenderGroups}
          getOptionLabel={(option) => option.classGenderGroup || ''}
          isOptionEqualToValue={(option, value) => option._id === value._id || isBlank(value)}
          value={Formik.values.classGenderGroup || null}
          onChange={(e, value) => Formik.setFieldValue('classGenderGroup', value)}
          onBlur={Formik.handleBlur('classGenderGroup')}
          renderInput={(params) => (
            <TextField
              {...params}
              id='classGenderGroup'
              name='classGenderGroup'
              type='text'
              variant='outlined'
              color='secondary'
              label='Class Group'
              required
              fullWidth
              error={isNotBlank(Formik.touched.classGenderGroup && Formik.errors.classGenderGroup)}
              helperText={Formik.errors.classGenderGroup}
            />
          )}
        />
      </Stack>

      <Stack spacing={2} direction='row' sx={{ marginTop: 1, marginBottom: 4 }}>
        {isSelectedClass11thOr12th() && (
          <Autocomplete
            id='classStream-autocomplete'
            sx={{ ...autoCompleteCustomStyle, maxWidth: defaultFieldWidth }}
            fullWidth
            required
            disablePortal
            autoHighlight
            options={streams}
            getOptionLabel={(option) => option.stream || ''}
            isOptionEqualToValue={(option, value) => option._id === value._id || isBlank(value)}
            value={Formik.values.classStream || null}
            onChange={(e, value) => Formik.setFieldValue('classStream', value)}
            onBlur={Formik.handleBlur('classStream')}
            renderInput={(params) => (
              <TextField
                {...params}
                id='classStream'
                name='classStream'
                type='text'
                variant='outlined'
                color='secondary'
                label='Stream'
                required
                fullWidth
                error={isNotBlank(Formik.touched.classStream && Formik.errors.classStream)}
                helperText={Formik.errors.classStream}
              />
            )}
          />
        )}

        <TextField
          id='previousSchool'
          type='text'
          variant='outlined'
          color='secondary'
          label='Previous School'
          value={Formik.values.previousSchool}
          sx={{ maxWidth: defaultFieldWidth }}
          onChange={Formik.handleChange}
          onBlur={Formik.handleBlur}
          fullWidth
        />

        <DatePicker
          id='joiningDate-datepicker'
          label='Date of Joining'
          sx={{ maxWidth: defaultFieldWidth }}
          value={Formik.values.joiningDate}
          onChange={(date) => Formik.setFieldValue('joiningDate', formattedDate({ date }))}
          onBlur={Formik.handleBlur('joiningDate')}
          fullWidth
          required
          variant='outlined'
          color='secondary'
          format='dd/MM/yyyy'
          slotProps={{
            layout: { sx: datePickerCustomStyle },
            textField: {
              id: 'joiningDate',
              name: 'joiningDate',
              variant: 'outlined',
              color: 'secondary',
              format: 'DD/MM/YYYY',
              fullWidth: true,
              value: Formik.values.joiningDate,
              InputLabelProps: { shrink: true },
              onChange: (date) => Formik.setFieldValue('joiningDate', formattedDate({ date })),
              onBlur: Formik.handleBlur('joiningDate'),
              error: isNotBlank(Formik.touched.joiningDate && Formik.errors.joiningDate),
              helperText: Formik.touched.joiningDate && Formik.errors.joiningDate
            }
          }}
        />
      </Stack>
    </>
  );

  const employeeSpecificDetailsSection = () =>
    actionRole.role !== 'Student' && (
      <>
        <div style={{ marginTop: '10px' }}>{formSubTitle('Employment Details')}</div>
        <Stack spacing={2} direction='row' sx={{ marginTop: 1, marginBottom: 4 }}>
          <DatePicker
            id='joiningDate-datepicker'
            label='Date of Joining'
            value={Formik.values.joiningDate}
            onChange={(date) => Formik.setFieldValue('joiningDate', formattedDate({ date }))}
            onBlur={Formik.handleBlur('joiningDate')}
            fullWidth
            required
            variant='outlined'
            color='secondary'
            format='dd/MM/yyyy'
            slotProps={{
              layout: { sx: datePickerCustomStyle },
              textField: {
                id: 'joiningDate',
                name: 'joiningDate',
                variant: 'outlined',
                color: 'secondary',
                format: 'DD/MM/YYYY',
                fullWidth: true,
                value: Formik.values.joiningDate,
                InputLabelProps: { shrink: true },
                onChange: (date) => Formik.setFieldValue('joiningDate', formattedDate({ date })),
                onBlur: Formik.handleBlur('joiningDate'),
                error: isNotBlank(Formik.touched.joiningDate && Formik.errors.joiningDate),
                helperText: Formik.touched.joiningDate && Formik.errors.joiningDate
              }
            }}
          />
          <Autocomplete
            id='qualification-autocomplete'
            sx={{ ...autoCompleteCustomStyle }}
            required
            fullWidth
            disablePortal
            autoHighlight
            options={qualifications}
            isOptionEqualToValue={(option, value) => option._id === value._id || isBlank(value)}
            getOptionLabel={(option) => (option.qualification ? option.qualification : '')}
            value={Formik.values.qualification || null}
            onChange={(e, value) => Formik.setFieldValue('qualification', value)}
            onBlur={Formik.handleBlur('qualification')}
            renderInput={(params) => (
              <TextField
                {...params}
                id='qualification'
                name='qualification'
                type='text'
                variant='outlined'
                color='secondary'
                label='Qualification'
                required
                error={isNotBlank(Formik.touched.qualification && Formik.errors.qualification)}
                helperText={Formik.errors.qualification}
              />
            )}
          />
          <Autocomplete
            id='employmentGrade-autocomplete'
            sx={{ ...autoCompleteCustomStyle }}
            fullWidth
            required
            disablePortal
            autoHighlight
            options={grades}
            isOptionEqualToValue={(option, value) => option._id === value._id || isBlank(value)}
            getOptionLabel={(option) => (option.employmentGrade ? option.employmentGrade : '')}
            value={Formik.values.employmentGrade || null}
            onChange={(e, value) => Formik.setFieldValue('employmentGrade', value)}
            renderInput={(params) => (
              <TextField
                {...params}
                id='employmentGrade'
                name='employmentGrade'
                type='text'
                variant='outlined'
                color='secondary'
                label='Grade'
                required
                error={isNotBlank(Formik.touched.employmentGrade && Formik.errors.employmentGrade)}
                helperText={Formik.errors.employmentGrade}
              />
            )}
          />
          <Autocomplete
            id='employmentType-autocomplete'
            sx={{ ...autoCompleteCustomStyle }}
            fullWidth
            required
            disablePortal
            autoHighlight
            options={employmentTypes}
            isOptionEqualToValue={(option, value) => option._id === value._id || isBlank(value)}
            getOptionLabel={(option) => (option.employmentType ? option.employmentType : '')}
            value={Formik.values.employmentType || null}
            onChange={(e, value) => Formik.setFieldValue('employmentType', value)}
            renderInput={(params) => (
              <TextField
                {...params}
                id='employmentType'
                name='employmentType'
                type='text'
                variant='outlined'
                color='secondary'
                label='Employment Type'
                required
                fullWidth
                error={isNotBlank(Formik.touched.employmentType && Formik.errors.employmentType)}
                helperText={Formik.errors.employmentType}
              />
            )}
          />
        </Stack>

        <Stack spacing={2} direction='row' sx={{ marginTop: 1, marginBottom: 4 }}>
          <Autocomplete
            id='employmentDesignation-autocomplete'
            sx={{ ...autoCompleteCustomStyle }}
            fullWidth
            required
            disablePortal
            autoHighlight
            options={designations}
            isOptionEqualToValue={(option, value) => option._id === value._id || isBlank(value)}
            getOptionLabel={(option) => (option.designation ? option.designation : '')}
            value={Formik.values.employmentDesignation || null}
            onChange={(e, value) => Formik.setFieldValue('employmentDesignation', value)}
            renderInput={(params) => (
              <TextField
                {...params}
                id='employmentDesignation'
                name='employmentDesignation'
                type='text'
                variant='outlined'
                color='secondary'
                label='Designation'
                required
                fullWidth
                error={isNotBlank(Formik.touched.employmentDesignation && Formik.errors.employmentDesignation)}
                helperText={Formik.errors.employmentDesignation}
              />
            )}
          />
          <TextField
            id='yearsOfExperience'
            sx={{ ...autoCompleteCustomStyle }}
            type='text'
            variant='outlined'
            color='secondary'
            label='Total Experience'
            required
            value={Formik.values.yearsOfExperience}
            onChange={Formik.handleChange}
            onBlur={Formik.handleBlur}
            fullWidth
            error={isNotBlank(Formik.touched.yearsOfExperience && Formik.errors.yearsOfExperience)}
            helperText={Formik.errors.yearsOfExperience}
          />
          <TextField
            id='previousEmployer'
            sx={{ ...autoCompleteCustomStyle }}
            type='text'
            variant='outlined'
            color='secondary'
            label='Previous Employer'
            value={Formik.values.previousEmployer}
            onChange={Formik.handleChange}
            onBlur={Formik.handleBlur}
            fullWidth
          />

          <Autocomplete
            id='previousDesignation-autocomplete'
            sx={{ ...autoCompleteCustomStyle }}
            fullWidth
            required
            disablePortal
            autoHighlight
            options={designations}
            isOptionEqualToValue={(option, value) => option._id === value._id || isBlank(value)}
            getOptionLabel={(option) => (option.designation ? option.designation : '')}
            value={Formik.values.previousDesignation || null}
            onChange={(e, value) => Formik.setFieldValue('previousDesignation', value)}
            renderInput={(params) => (
              <TextField
                {...params}
                id='previousDesignation'
                name='previousDesignation'
                type='text'
                variant='outlined'
                color='secondary'
                label='Previous Designation'
                fullWidth
              />
            )}
          />
        </Stack>
      </>
    );

  const academicDetailsSection = () => (
    <>
      {actionRole.role === 'Teacher' ||
        (actionRole.role === 'Student' && <div style={{ marginTop: '10px' }}>{formSubTitle('Academic Details')}</div>)}
      {actionRole.role === 'Teacher' && teacherSpecificDetailsSection()}
      {actionRole.role === 'Student' && studentSpecificDetailsSection()}
    </>
  );

  const governmentIdDetailsSection = () => (
    <>
      <div style={{ marginTop: '10px' }}>{formSubTitle('Government ID Details')}</div>
      {Formik.values.govIds.map((govId, index) => (
        <Stack
          key={`govId-${index}`}
          spacing={{ xs: 1, sm: 2 }}
          direction='row'
          useFlexGap
          sx={{ marginTop: 1, marginBottom: 4, flexWrap: 'wrap', flexGrow: 1 }}
        >
          <Autocomplete
            id={`govId-${index}.govIdType-autocomplete`}
            sx={{ maxWidth: defaultFieldWidth, ...autoCompleteCustomStyle }}
            fullWidth
            required
            disablePortal
            autoHighlight
            options={govIdTypes}
            isOptionEqualToValue={(option, value) => option === value || isBlank(value)}
            onChange={(e, value) => Formik.setFieldValue(`govIds[${index}].govIdType`, value)}
            value={govId.govIdType || null}
            renderInput={(params) => (
              <TextField
                {...params}
                id={`govIds[${index}].govIdType`}
                name={`govIds[${index}].govIdType`}
                type='text'
                variant='outlined'
                color='secondary'
                label='Gov Id Type'
                fullWidth
              />
            )}
          />

          {Formik.values.govIds[index].govIdType === 'Other' && (
            <TextField
              id={`govIds[${index}].govIdTypeOthersName`}
              name={`govIds[${index}].govIdTypeOthersName`}
              sx={{ maxWidth: defaultFieldWidth, ...autoCompleteCustomStyle }}
              type='text'
              variant='outlined'
              color='secondary'
              label='Custom Gov Id Type'
              value={govId.govIdTypeOthersName || ''}
              onChange={Formik.handleChange}
              onBlur={Formik.handleBlur}
              fullWidth
            />
          )}

          <TextField
            id={`govIds[${index}].govIdNumber`}
            name={`govIds[${index}].govIdNumber`}
            sx={{ maxWidth: defaultFieldWidth, ...autoCompleteCustomStyle }}
            type='text'
            variant='outlined'
            color='secondary'
            label={`${govId.govIdType === 'Other' ? govId.govIdTypeOthersName || '' : govId.govIdType || ''} Number`}
            value={govId.govIdNumber || ''}
            onChange={Formik.handleChange}
            onBlur={Formik.handleBlur}
            fullWidth
          />

          {index === 0 && (
            <Button
              sx={{ maxWidth: '20%', maxHeight: 55 }}
              variant='outlined'
              color='primary'
              type='button'
              onClick={handleAddMoreGovernmentIds}
            >
              {'Add More Government ID'}
            </Button>
          )}
        </Stack>
      ))}
    </>
  );

  const bankDetailsSection = () => (
    <>
      <div style={{ marginTop: '10px' }}>{formSubTitle('Bank Details')}</div>
      <Stack
        spacing={{ xs: 1, sm: 2 }}
        direction='row'
        useFlexGap
        sx={{ marginTop: 1, marginBottom: 4, flexWrap: 'wrap', flexGrow: 1 }}
      >
        <TextField
          id={'bankDetails.ifscCode'}
          name={'bankDetails.ifscCode'}
          sx={{ maxWidth: defaultFieldWidth, ...autoCompleteCustomStyle }}
          type='text'
          variant='outlined'
          color='secondary'
          label='IFSC Code'
          fullWidth
          value={Formik.values.bankDetails.ifscCode || ''}
          onChange={async (e) => {
            const value = e.target.value;

            // Update the form field value
            Formik.setFieldValue('bankDetails.ifscCode', value);

            try {
              // Check if the IFSC code is valid according to validation
              const isValidIfsc = ifscCodeRegex.test(value);
              if (isValidIfsc) {
                const values = await BankDetailAPI.get(value);
                if (isNotBlank(values) && values.success) {
                  const bankData = values.data;
                  Formik.setFieldValue('bankDetails.bankName', bankData.BANK);
                  Formik.setFieldValue('bankDetails.branchName', bankData.BRANCH);
                  Formik.setFieldValue('bankDetails.address', bankData.ADDRESS);
                  Formik.setFieldValue('bankDetails.city', bankData.CITY);
                  Formik.setFieldValue('bankDetails.state', bankData.STATE);
                }
              }
            } catch (error) {
              console.log('Error fetching bank details:', error);
            }
          }}
          onBlur={Formik.handleBlur}
          error={isNotBlank(
            Formik.touched.bankDetails &&
              Formik.touched.bankDetails.ifscCode &&
              Formik.errors.bankDetails &&
              Formik.errors.bankDetails.ifscCode
          )}
          helperText={Formik.errors.bankDetails && Formik.errors.bankDetails.ifscCode}
        />

        <TextField
          id={'bankDetails.accountNumber'}
          name={'bankDetails.accountNumber'}
          sx={{ maxWidth: defaultFieldWidth, ...autoCompleteCustomStyle }}
          type='text'
          variant='outlined'
          color='secondary'
          label='Account Number'
          value={Formik.values.bankDetails.accountNumber || ''}
          onChange={Formik.handleChange}
          onBlur={Formik.handleBlur}
          fullWidth
        />

        <TextField
          id={'bankDetails.bankName'}
          name={'bankDetails.bankName'}
          sx={{ maxWidth: defaultFieldWidth, ...autoCompleteCustomStyle }}
          type='text'
          variant='outlined'
          color='secondary'
          label='Bank Name'
          value={Formik.values.bankDetails.bankName || ''}
          onChange={Formik.handleChange}
          onBlur={Formik.handleBlur}
          fullWidth
        />

        <TextField
          id={'bankDetails.branchName'}
          name={'bankDetails.branchName'}
          sx={{ maxWidth: defaultFieldWidth, ...autoCompleteCustomStyle }}
          type='text'
          variant='outlined'
          color='secondary'
          label='Branch Name'
          value={Formik.values.bankDetails.branchName || ''}
          onChange={Formik.handleChange}
          onBlur={Formik.handleBlur}
          fullWidth
        />

        <TextField
          id={'bankDetails.address'}
          name={'bankDetails.address'}
          sx={{ maxWidth: defaultFieldWidth, ...autoCompleteCustomStyle }}
          type='text'
          variant='outlined'
          color='secondary'
          label='Address'
          value={Formik.values.bankDetails.address || ''}
          onChange={Formik.handleChange}
          onBlur={Formik.handleBlur}
          fullWidth
        />

        <TextField
          id={'bankDetails.city'}
          name={'bankDetails.city'}
          sx={{ maxWidth: defaultFieldWidth, ...autoCompleteCustomStyle }}
          type='text'
          variant='outlined'
          color='secondary'
          label='City'
          value={Formik.values.bankDetails.city || ''}
          onChange={Formik.handleChange}
          onBlur={Formik.handleBlur}
          fullWidth
        />

        <TextField
          id={'bankDetails.state'}
          name={'bankDetails.state'}
          sx={{ maxWidth: defaultFieldWidth, ...autoCompleteCustomStyle }}
          type='text'
          variant='outlined'
          color='secondary'
          label='State'
          value={Formik.values.bankDetails.state || ''}
          onChange={Formik.handleChange}
          onBlur={Formik.handleBlur}
          fullWidth
        />
      </Stack>
    </>
  );

  const showCredentialsButton = () => {
    if (isBlank(editUser)) {
      return false;
    }

    const requesterRole = currentUser.role.role || '';
    const show = isEqual(requesterRole, 'Super Admin') || isEqual(requesterRole, 'Admin');

    return show;
  };

  const showSendCredentialsButton = () => {
    if (isBlank(editUser)) {
      return false;
    }

    const requesterRole = currentUser.role.role || '';
    const show = isEqual(requesterRole, 'Admin Staff');

    return show;
  };

  const showDeleteButton = () => {
    if (isBlank(editUser)) {
      return false;
    }

    const requesterRole = currentUser.role.role || '';
    let show =
      isEqual(requesterRole, 'Admin Staff') || isEqual(requesterRole, 'Super Admin') || isEqual(requesterRole, 'Admin');

    const deletingUserRole = editUser.role.role || '';
    show = show && !isEqual(deletingUserRole, 'Super Admin') && !isEqual(deletingUserRole, 'Admin');
    return show;
  };

  const actionButtons = () => (
    <Stack
      spacing={2}
      direction='row'
      divider={verticalDivider()}
      justifyContent='space-between'
      alignItems='center'
      style={{ width: '100%' }}
    >
      {(showSendCredentialsButton() || showCredentialsButton()) && (
        <Button
          style={{ maxWidth: '200px' }}
          variant='contained'
          color='primary'
          type='button'
          onClick={handleShowCredentials}
        >
          {showSendCredentialsButton() ? 'Send Credentials' : 'Show Credentials'}
        </Button>
      )}

      <Stack
        spacing={2}
        direction='row'
        divider={verticalDivider()}
        justifyContent='flex-end'
        alignItems='center'
        style={{ width: '100%' }}
      >
        <Button style={{ maxWidth: '150px' }} variant='contained' color='primary' type='button' onClick={handleCancel}>
          Cancel
        </Button>
        {isBlank(editUser) && (
          <Button
            style={{ maxWidth: '150px' }}
            variant='contained'
            color='primary'
            type='button'
            onClick={handleResetForm}
          >
            Reset
          </Button>
        )}
        {isNotBlank(editUser) && showDeleteButton() && (
          <Button
            style={{ maxWidth: '150px' }}
            variant='contained'
            color='primary'
            type='button'
            onClick={handleDeleteUser}
          >
            Delete
          </Button>
        )}
        <Button
          style={{ maxWidth: '150px' }}
          variant='contained'
          color='primary'
          type='submit'
          onClick={Formik.handleSubmit}
        >
          {`${isBlank(editUser) ? 'Register' : 'Update'}`}
        </Button>
      </Stack>
    </Stack>
  );

  return (
    <>
      <Stack spacing={2} direction='column' divider={horizontalDivider()} justifyContent='space-between'>
        {formTitleAndCloseIcon()}
        <div style={{ overflowY: 'auto', height: 'auto', maxHeight: '90%', paddingRight: '10px' }}>
          <form onSubmit={Formik.handleSubmit}>
            {personalDetailsSection()}
            {horizontalDivider()}
            {academicDetailsSection()}
            {horizontalDivider()}
            {governmentIdDetailsSection()}
            {horizontalDivider()}
            {bankDetailsSection()}
            {horizontalDivider()}
            {employeeSpecificDetailsSection()}
          </form>
        </div>
        {actionButtons()}
      </Stack>

      {maxSubjectPerTeacherAlert()}
      {cancelRegistrationAlert()}
      {clearAllEntriesAlert()}
      {removeClassesTeachingDialog()}
      {deleteUserAlert()}
      {showCredentialsAlert()}
    </>
  );
};

const updateInitialValues = (editUser = null, role = '') => {
  const values = {
    username: '',
    password: '',
    hash: '',
    firstName: (editUser && editUser.firstName) || '',
    lastName: (editUser && editUser.lastName) || '',
    dob: dateFromTimestamp({ timestamp: editUser && editUser.dob, format: 'DD/MM/YYYY' }),
    city: (editUser && editUser.city) || '',
    state: (editUser && editUser.state) || (editUser && editUser.city && editUser && editUser.city.state) || '',
    country: (editUser && editUser.country) || (editUser && editUser.city && editUser && editUser.city.country) || '',
    address: (editUser && editUser.address) || '',
    mobile: (editUser && editUser.mobile) || '',
    email: (editUser && editUser.email) || '',
    image:
      (editUser && editUser.image) ||
      'https://dharaji-sms-file-store.nyc3.cdn.digitaloceanspaces.com/default-profile-picture.png',
    role: (editUser && editUser.role) || role,
    shift: (editUser && editUser.shift) || '',
    parentOrSpouseName: (editUser && editUser.parentOrSpouseName) || '',
    parentOrSpouseContact: (editUser && editUser.parentOrSpouseContact) || '',
    emergencyContactRelation: (editUser && editUser.emergencyContactRelation) || '',
    joiningDate: dateFromTimestamp({ timestamp: editUser && editUser.joiningDate, format: 'DD/MM/YYYY' }),
    yearsOfExperience: (editUser && editUser.yearsOfExperience) || '',
    qualification: (editUser && editUser.qualification) || '',
    previousDesignation: (editUser && editUser.previousDesignation) || null,
    previousSchool: (editUser && editUser.previousSchool) || '',
    previousEmployer: (editUser && editUser.previousEmployer) || '',
    middleName: (editUser && editUser.middleName) || '',
    classAttending: (editUser && editUser.classAttending) || '',
    classGenderGroup: (editUser && editUser.classGenderGroup) || '',
    classMedium: (editUser && editUser.classMedium) || '',
    classSection: (editUser && editUser.classSection) || '',
    classStream: (editUser && editUser.classStream) || '',
    classesTeaching: (editUser && editUser.classesTeaching) || [],
    classTeacherOfClass: (editUser && editUser.classTeacherOfClass) || '',
    employmentGrade: (editUser && editUser.employmentGrade) || '',
    employmentType: (editUser && editUser.employmentType) || '',
    employmentDesignation: (editUser && editUser.employmentDesignation) || '',
    subjectExpertise: (editUser && editUser.subjectExpertise) || [],
    childId: (editUser && editUser.childId) || '',
    admissionNo: (editUser && editUser.admissionNo) || '',
    penNo: (editUser && editUser.penNo) || '',
    govIds:
      editUser && editUser.govIds.length > 0
        ? editUser.govIds
        : [{ govIdNumber: '', govIdType: '', govIdTypeOthersName: '' }],
    bankDetails: (editUser && editUser.bankDetails) || {
      ifscCode: '',
      accountNumber: '',
      bankName: '',
      branchName: '',
      address: '',
      city: '',
      state: ''
    }
  };

  return values;
};

export default UserRegistrationForm;
