import { KEYS } from '../constants';
import { isNotBlank } from '../utils';
import { api } from './configs/axiosConfigs';
import { defineCancelApiObject } from './configs/axiosUtils';
import endPoints from './configs/endPoints';
import methods from './configs/methods';

export const QualificationsAPI = {
  get: async (refresh = false, cancel = false) => {
    try {
      if (refresh) {
        localStorage.removeItem(KEYS.QUALIFICATIONS);
      }

      let qualifications = [];
      const qualificationsJSONString = localStorage.getItem(KEYS.QUALIFICATIONS);

      if (isNotBlank(qualificationsJSONString)) {
        qualifications = JSON.parse(qualificationsJSONString);
      } else {
        const response = await api.request({
          url: endPoints.getAllQualifications,
          method: methods.GET,
          signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        qualifications = response.data;
        localStorage.setItem(KEYS.QUALIFICATIONS, JSON.stringify(qualifications));
      }

      return qualifications;
    } catch (error) {
      console.log('🪵 : get: : error:', JSON.stringify(error));
    }
  }
};

const cancelApiObject = defineCancelApiObject(QualificationsAPI);
