import React from 'react';
import styles from './Navigation.module.scss';
import NavigationData from '../../data/home/nav.json';
import { Link, useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';

// import Button from '../UI/button';
// import Logo from './Logo';

const Navigation = ({ menuItemSelected }) => {
  const history = useHistory();
  const location = useLocation();

  const menuItemSelectedLocal = (menuName, redirectPath) => {
    if (menuItemSelected) {
      menuItemSelected(menuName, redirectPath);
    }
  };

  const handleClick = (e, redirectPath, menuName) => {
    e.preventDefault();
    e.stopPropagation();
    menuItemSelectedLocal(menuName, redirectPath);

    if (!location.pathname.includes(redirectPath)) {
      setTimeout(() => history.push(redirectPath), 300);
    }
  };

  return (
    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'baseline', width: '100%' }}>
      <nav style={{ flexDirection: 'row', display: 'flex', alignItems: 'baseline' }}>
        <ul className={`${styles.mainMenu} d-flex justify-content-center align-items-center`}>
          <li>
            <Link to={NavigationData.n1} onClick={(e) => handleClick(e, NavigationData.n1, NavigationData.n1)}>
              {NavigationData.n1}
            </Link>
          </li>
          <li>
            <Link to={NavigationData.n1} onClick={(e) => handleClick(e, NavigationData.n1, NavigationData.n2)}>
              {NavigationData.n2}
            </Link>
          </li>
          <li>
            <Link to={NavigationData.n1} onClick={(e) => handleClick(e, NavigationData.n1, NavigationData.n3)}>
              {NavigationData.n3}
            </Link>
          </li>
          <li>
            <Link to={NavigationData.n1} onClick={(e) => handleClick(e, NavigationData.n1, NavigationData.n4)}>
              {NavigationData.n4}
            </Link>
          </li>
          <li>
            <Link to={NavigationData.n1} onClick={(e) => handleClick(e, NavigationData.n1, NavigationData.n5)}>
              {NavigationData.n5}
            </Link>
          </li>
          <li>
            <Link to={NavigationData.n6}>{NavigationData.n6}</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;
