import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import ScrollToTop from '../helpers/ScrollToTop';
import Dashboard from './pages/Dashboard';
import Dates from './pages/Dates';
import FacilityInfra from './pages/FacilityInfra';
import News from './pages/News';
import App from '../App';
import PrivateRoute from '../Guards/PrivateRoute';
import About from './pages/About';
import Contact from './pages/Contact';
import Logo from './pages/Logo';
import Gallery from './pages/Gallery';
import Welcome from './pages/Welcome';
import Admissions from './pages/Admissions';
import Registration from './pages/Registration';
import { Router, useHistory } from 'react-router-dom/cjs/react-router-dom';
import { getCurrentLoggedInUser, isNotBlank } from '../utils';
import Salary from './pages/Salary';
import Fees from './pages/Fees';
import FeesPerClass from './pages/FeesPerClass';
import LandingPage from './pages/LandingPage';

const Admin = ({ match }) => {
  // const [currentUser, setCurrentUser] = useState();
  const [isITStaff, setIsITStaff] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setTimeout(async () => {
      try {
        const user = await getCurrentLoggedInUser();
        if (isNotBlank(user)) {
          // setCurrentUser(user);
          setIsITStaff(user.role.role === 'IT Staff');
        }
      } catch (error) {
        console.log('🪵 : useEffect : error:', error);
      }
    }, 0);
  }, []);

  return (
    <Router history={history}>
      <ScrollToTop>
        <Switch>
          <PrivateRoute path={`${match.path}/users`}>
            <Registration />{' '}
          </PrivateRoute>
          <PrivateRoute path={`${match.path}/fees`}>
            <Fees />
          </PrivateRoute>
          <PrivateRoute path={`${match.path}/feesPerClass`}>
            <FeesPerClass />
          </PrivateRoute>
          <PrivateRoute path={`${match.path}/salary`}>
            <Salary />
          </PrivateRoute>
          <PrivateRoute path={`${match.path}/administration`}>
            <Dashboard />
          </PrivateRoute>
          <PrivateRoute path={`${match.path}/dates`}>
            <Dates />
          </PrivateRoute>
          <PrivateRoute path={`${match.path}/facility-infra`}>
            <FacilityInfra />{' '}
          </PrivateRoute>
          <PrivateRoute path={`${match.path}/news`}>
            <News />{' '}
          </PrivateRoute>
          <PrivateRoute path={`${match.path}/about`}>
            <About />{' '}
          </PrivateRoute>
          <PrivateRoute path={`${match.path}/contact`}>
            <Contact />{' '}
          </PrivateRoute>
          <PrivateRoute path={`${match.path}/gallery`}>
            <Gallery />{' '}
          </PrivateRoute>
          <PrivateRoute path={`${match.path}/logo`}>
            <Logo />{' '}
          </PrivateRoute>
          <PrivateRoute path={`${match.path}/landing`}>
            <LandingPage />{' '}
          </PrivateRoute>
          <PrivateRoute path={`${match.path}/welcome`}>
            <Welcome />{' '}
          </PrivateRoute>
          <PrivateRoute path={`${match.path}/admissions`}>
            <Admissions />{' '}
          </PrivateRoute>
          <PrivateRoute path={`${match.path}`}>
            <Registration />{' '}
          </PrivateRoute>

          {isITStaff && (
            <>
              <PrivateRoute path={`${match.path}/masters`}>
                {/* Add all the master components here which can only be accessed by IT staff */}
              </PrivateRoute>
            </>
          )}

          <Route path={'/'} component={App} />
        </Switch>

        {/*
        <Switch>
        <Route path={`admin/administration`} component={Dashboard} />
          <Route path={`admin/dates`} component={Dates} />
          <Route path={`admin/facility-infra`} component={FacilityInfra} />
          <Route path={`admin/news`} component={News} />
          <Route path={`admin`} component={Dashboard} />
          <Route path={`/`} component={App} />
        </Switch> */}
      </ScrollToTop>
    </Router>
  );
};

export default Admin;
