import React, { StrictMode } from 'react';
import App from './App';
import axios from 'axios';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { unregister } from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/style.scss';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// Material UI //
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { KEYS } from './constants';

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(KEYS.TOKEN);

    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
