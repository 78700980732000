import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import toast, { Toaster } from 'react-hot-toast';
import LoadingIndicator from '../../../helpers/LoadingIndicator';
import { Box, Modal, Button, TextField, Autocomplete, Stack } from '@mui/material';
import { UsersAPI } from '../../../apis';
import { getCurrentLoggedInUser, isNotBlank, autoCompleteCustomStyle } from '../../../utils';
import { BOX_STYLE, GRID_STYLE, KEYS } from '../../../constants';
import { useHistory } from 'react-router-dom';
import { EmptyGridOverlay } from '../../../helpers/EmptyGridOverlay';
import { loadModelData } from '../userRegistration/LoadModelData';
import FeesCollectionForm from './FeesCollectionForm';
import ProfileImage from '../../../components/UI/profile-image';

const FeesCollectionList = () => {
  const history = useHistory();

  const [tempSearchParams, setTempSearchParams] = useState();
  const [studentRole, setStudentRole] = useState();
  const [modelData, setModelData] = useState({});
  const [users, setUsers] = useState([]);
  const [editUser, setEditUser] = useState();
  const [loading, setLoading] = useState(false);
  const [showAddUpdateUserDialog, setShowAddUpdateUserDialog] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const [searchString, setSearchString] = useState();
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedStream, setSelectedStream] = useState(null);
  const [selectedMedium, setSelectedMedium] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedGenderGroup, setSelectedGenderGroup] = useState(null);

  const classes = modelData.classes;
  const classGenderGroups = modelData.classGenderGroups;
  const mediums = modelData.classMediums;
  const sections = modelData.classSections;
  const streams = modelData.streams;
  const class11 = classes && classes.find((c) => c.class === '11th Grade');
  const class12 = classes && classes.find((c) => c.class === '12th Grade');

  const columns = [
    {
      field: 'image',
      headerName: 'Profile',
      sortable: false,
      width: 90,
      renderCell: (params) => <ProfileImage src={params.row.image} alt={params.row.firstName} />
    },
    {
      field: 'fullName',
      headerName: 'Full name',
      valueGetter: (params) =>
        `${params.row.firstName || ''} ${params.row.middleName || ''}  ${params.row.lastName || ''}`,
      editable: false,
      width: 250
    },
    {
      field: 'parentOrSpouseName',
      headerName: 'Guardian Name',
      editable: false,
      width: 250
    },
    {
      field: 'mobile',
      headerName: 'Mobile',
      editable: false,
      width: 150
    },
    {
      field: 'classAttending',
      headerName: 'Class',
      valueGetter: (params) => (params.row.classAttending && `${params.row.classAttending.class || ''}`) || '',
      editable: false,
      width: 150
    },
    {
      field: 'classMedium',
      headerName: 'Medium',
      valueGetter: (params) => `${params.row.classMedium.classMedium || ''}`,
      editable: false,
      width: 150
    },
    {
      field: 'classSection',
      headerName: 'Section',
      valueGetter: (params) => (params.row.classSection && `${params.row.classSection.classSection || ''}`) || '',
      editable: false,
      width: 100
    },
    {
      field: 'classGenderGroup',
      headerName: 'Gender Group',
      valueGetter: (params) =>
        (params.row.classGenderGroup && `${params.row.classGenderGroup.classGenderGroup || ''}`) || '',
      editable: false,
      width: 150
    }
  ];

  useEffect(() => {
    setTimeout(async () => {
      await loadData();
      await loadCurrentUser();
      await parseSearchQuery();
    }, 0);
  }, []);

  useEffect(() => {
    setTimeout(async () => {
      if (isNotBlank(tempSearchParams)) {
        await loadUsers();
      }
    }, 0);
  }, [tempSearchParams]);

  const loadCurrentUser = useCallback(async () => {
    try {
      const user = await getCurrentLoggedInUser();
      if (isNotBlank(user)) {
        setCurrentUser(user);
      } else {
        await UsersAPI.logout();
        history.push('/');
      }
    } catch (error) {
      console.log('Error loading current user:', error);
      await UsersAPI.logout();
      history.push('/');
    }
  }, [history]);

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await loadModelData();
      setModelData(data);
      parseRoles(data);
    } catch (error) {
      console.log('Error loading data:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const loadUsers = async (refresh = false) => {
    try {
      setLoading(true);
      let searchParams = studentRole ? { role: studentRole } : {};
      searchParams = searchString ? { ...searchParams, query: searchString } : searchParams;
      searchParams = selectedClass ? { ...searchParams, classAttending: selectedClass } : searchParams;
      searchParams = selectedMedium ? { ...searchParams, classMedium: selectedMedium } : searchParams;
      searchParams = selectedSection ? { ...searchParams, classSection: selectedSection } : searchParams;
      searchParams = selectedStream ? { ...searchParams, classStream: selectedStream } : searchParams;
      searchParams = selectedGenderGroup ? { ...searchParams, classGenderGroup: selectedGenderGroup } : searchParams;

      const response = await UsersAPI.search(searchParams, refresh);

      if (response.success) {
        localStorage.setItem(KEYS.TEMP_SEARCH_PARAM, JSON.stringify(searchParams));
        setUsers(response.users || []);
      } else {
        setUsers([]);
        console.log('\n▶️ -> loadUsers -> error: response', response);
      }
    } catch (error) {
      setUsers([]);
      console.log('\n▶️ -> loadUsers -> error:', error);
    } finally {
      setLoading(false);
    }
  };

  const parseRoles = useCallback((data) => {
    if (isNotBlank(data) && isNotBlank(data.roles)) {
      const studentRoleLocal = data.roles.find((role) => role.role === 'Student');
      if (studentRoleLocal) {
        setStudentRole(studentRoleLocal._id);
      }
    }
  }, []);

  const parseSearchQuery = useCallback(async () => {
    try {
      setLoading(true);
      const tempSearchParamsString = localStorage.getItem(KEYS.TEMP_SEARCH_PARAM);
      if (tempSearchParamsString) {
        const tempSearchParamsObject = JSON.parse(tempSearchParamsString);

        if (tempSearchParamsObject) {
          if ('query' in tempSearchParamsObject) {
            setSearchString(tempSearchParamsObject.query);
          }
          if ('role' in tempSearchParamsObject) {
            setStudentRole(tempSearchParamsObject.role);
          }
          if ('classAttending' in tempSearchParamsObject) {
            setSelectedClass(tempSearchParamsObject.classAttending);
          }
          if ('classMedium' in tempSearchParamsObject) {
            setSelectedMedium(tempSearchParamsObject.classMedium);
          }
          if ('classSection' in tempSearchParamsObject) {
            setSelectedSection(tempSearchParamsObject.classSection);
          }
          if ('classStream' in tempSearchParamsObject) {
            setSelectedStream(tempSearchParamsObject.classStream);
          }
          if ('classGenderGroup' in tempSearchParamsObject) {
            setSelectedGenderGroup(tempSearchParamsObject.classGenderGroup);
          }

          setTempSearchParams(tempSearchParamsObject);
        }
      }
    } catch (error) {
      console.log('\n▶️ -> parseSearchQuery -> error:', error);
      localStorage.removeItem(KEYS.TEMP_SEARCH_PARAM);
    } finally {
      setLoading(false);
    }
  }, []);

  const onClickSearch = () => {
    loadUsers();
  };

  const onClickRow = (content) => {
    const user = content.row;
    history.push('/admin/fees/student-fees-detail', { selectedUser: user });
  };

  const closeAddUpdateUserDialog = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setShowAddUpdateUserDialog(false);
    // setActionRole(null);
    // setEditUser(null);
  };

  const handleResponseCallback = (response = { success: false, message: '' }) => {
    if (response.success) {
      closeAddUpdateUserDialog();
      setLoading(false);
      setEditUser(null);
      toast.success(response.message);
    } else {
      toast.error(response.message);

      if (response.cancel) {
        closeAddUpdateUserDialog();
      }
    }
  };

  const dataGrid = () => (
    <div style={{ minHeight: 400, width: '100%' }}>
      <DataGrid
        getRowId={(row) => row._id.valueOf()}
        slots={{
          noRowsOverlay: EmptyGridOverlay
        }}
        rows={users}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 13
            }
          }
        }}
        onRowClick={onClickRow}
        pageSizeOptions={[13]}
        sx={GRID_STYLE}
      />
    </div>
  );

  const addUpdateUserDialog = () => {
    return (
      <Modal
        open={showAddUpdateUserDialog}
        onClose={closeAddUpdateUserDialog}
        aria-labelledby='parent-modal-title'
        aria-describedby='parent-modal-description'
      >
        <Box sx={BOX_STYLE}>
          <FeesCollectionForm
            users={users}
            editUser={editUser}
            modelData={modelData}
            responseCallBack={handleResponseCallback}
            setLoading={setLoading}
            currentUser={currentUser}
          />
        </Box>
      </Modal>
    );
  };

  const searchOptions = () => (
    <Stack spacing={2} direction='column' sx={{ marginTop: 1, marginBottom: 4 }}>
      <Stack spacing={2} direction='row' sx={{ marginTop: 1, marginBottom: 4 }}>
        <TextField
          id='search-student'
          label='Name, Email, Mobile'
          value={searchString || ''}
          onChange={(event) => {
            const value = event.target.value;
            setSearchString(value || '');
          }}
          onKeyPress={(event) => {
            const value = event.target.value;
            const key = event.key;

            if (key === 'Enter' && isNotBlank(value)) {
              loadUsers();
            }
          }}
          variant='outlined'
          fullWidth
          sx={{
            marginLeft: '0px',
            marginRight: '5px',
            ...autoCompleteCustomStyle
          }}
        />
        {classes && (
          <Autocomplete
            id='classAttending-autocomplete'
            options={classes}
            getOptionLabel={(option) => option.class || ''}
            fullWidth
            sx={{
              marginLeft: '5px',
              marginRight: '5px',
              ...autoCompleteCustomStyle
            }}
            value={classes ? classes.find((item) => item._id === selectedClass) : null}
            onChange={(e, value) => {
              setSelectedClass(value && value._id);
              setSelectedStream(null);
            }}
            renderInput={(params) => <TextField {...params} label='Class' />}
          />
        )}
        {streams && (selectedClass === class11._id || selectedClass === class12._id) && (
          <Autocomplete
            id='streams-autocomplete'
            options={streams}
            getOptionLabel={(option) => option.stream || ''}
            fullWidth
            sx={{
              marginLeft: '5px',
              marginRight: '5px',
              ...autoCompleteCustomStyle
            }}
            value={streams ? streams.find((item) => item._id === selectedStream) : null}
            onChange={(e, value) => setSelectedStream(value && value._id)}
            renderInput={(params) => <TextField {...params} label='Stream' />}
          />
        )}
      </Stack>
      <Stack spacing={2} direction='row' sx={{ marginTop: 1, marginBottom: 4 }}>
        {mediums && (
          <Autocomplete
            id='medium-autocomplete'
            options={mediums}
            getOptionLabel={(option) => option.classMedium || ''}
            fullWidth
            sx={{
              marginLeft: '5px',
              marginRight: '5px',
              ...autoCompleteCustomStyle
            }}
            value={mediums ? mediums.find((item) => item._id === selectedMedium) : null}
            onChange={(e, value) => setSelectedMedium(value && value._id)}
            renderInput={(params) => <TextField {...params} label='Medium' />}
          />
        )}
        {sections && (
          <Autocomplete
            id='section-autocomplete'
            options={sections}
            getOptionLabel={(option) => option.classSection || ''}
            fullWidth
            sx={{
              marginLeft: '5px',
              marginRight: '5px',
              ...autoCompleteCustomStyle
            }}
            value={sections ? sections.find((item) => item._id === selectedSection) : null}
            onChange={(e, value) => setSelectedSection(value && value._id)}
            renderInput={(params) => <TextField {...params} label='Section' />}
          />
        )}
        {classGenderGroups && (
          <Autocomplete
            id='classGenderGroup-autocomplete'
            options={classGenderGroups}
            getOptionLabel={(option) => option.classGenderGroup || ''}
            fullWidth
            sx={{
              marginLeft: '5px',
              marginRight: '5px',
              ...autoCompleteCustomStyle
            }}
            value={classGenderGroups ? classGenderGroups.find((item) => item._id === selectedGenderGroup) : null}
            onChange={(e, value) => setSelectedGenderGroup(value && value._id)}
            renderInput={(params) => <TextField {...params} label='Gender Group' />}
          />
        )}
        <Button
          style={{ maxWidth: '200px', marginLeft: '10px', marginRight: '0px', height: '55px' }}
          variant='contained'
          onClick={onClickSearch}
        >
          Search
        </Button>
      </Stack>
    </Stack>
  );

  return (
    <Fragment>
      <div key={'Fees-Collection'} className='p-3 card w-100'>
        <div className='d-flex flex-column justify-content-between'>
          <h2 style={{ color: 'gray' }}>Fees Collection</h2>
          {searchOptions()}
          {dataGrid()}
          {addUpdateUserDialog()}
        </div>
      </div>
      <Toaster />
      <LoadingIndicator open={loading} />
    </Fragment>
  );
};

export default memo(FeesCollectionList);
