// import React from "react";
// import { Link } from "react-router-dom";

// const Facilitiessingle = ({ data, styles, sliderClass }) => {
//   return (
//     <div
//       className={`col-12 col-sm-6 col-lg-4 ${sliderClass ? sliderClass : ""}`}
//     >
//       <div className={styles.blog}>
//         <div className={`${styles.thumb} facilities-img`}>
//           <Link to="#">
//             <img
//               src={require(`../../../assets/upload/${data.imgUrl}`)}
//               alt=""
//             />
//           </Link>
//         </div>

//       </div>
//     </div>
//   );
// };

// export default Facilitiessingle;

import React from 'react';
import { Link } from 'react-router-dom';

const Facilitiessingle = ({ data, styles, sliderClass }) => {
  return (
    <div className={`col-12 col-sm-6 col-lg-4 ${sliderClass || ''}`}>
      <div className={styles.blog}>
        <div className={`${styles.thumb} facilities-img`}>
          <Link to='#'>
            <img
              src={data.imgUrl}
              height='350px'
              alt='Facilities Image'
              style={{
                objectFit: 'cover',
                objectPosition: 'center'
              }}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Facilitiessingle;
