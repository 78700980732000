import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import NavigationData from '../../../data/home/nav.json';

const MobileNavMenu = ({ styles, menuItemSelected }) => {
  const history = useHistory();
  const location = useLocation();

  const menuItemSelectedLocal = (menuName, redirectPath) => {
    if (menuItemSelected) {
      menuItemSelected(menuName, redirectPath);
    }
  };

  const handleClick = (e, redirectPath, menuName) => {
    e.preventDefault();
    e.stopPropagation();
    menuItemSelectedLocal(menuName, redirectPath);

    if (!location.pathname.includes(redirectPath)) {
      setTimeout(() => history.push(redirectPath), 300);
    }
  };

  return (
    <nav className={styles.offcanvasNavigation} id='offcanvas-navigation'>
      <ul>
        <li>
          <Link to={NavigationData.n1} onClick={(e) => handleClick(e, NavigationData.n1, NavigationData.n1)}>
            {NavigationData.n1}
          </Link>
        </li>
        <li>
          <Link to={NavigationData.n1} onClick={(e) => handleClick(e, NavigationData.n1, NavigationData.n2)}>
            {NavigationData.n2}
          </Link>
        </li>
        <li>
          <Link to={NavigationData.n1} onClick={(e) => handleClick(e, NavigationData.n1, NavigationData.n3)}>
            {NavigationData.n3}
          </Link>
        </li>
        <li>
          <Link to={NavigationData.n1} onClick={(e) => handleClick(e, NavigationData.n1, NavigationData.n4)}>
            {NavigationData.n4}
          </Link>
        </li>
        <li>
          <Link to={NavigationData.n1} onClick={(e) => handleClick(e, NavigationData.n1, NavigationData.n5)}>
            {NavigationData.n5}
          </Link>
        </li>
        <li>
          <Link to={NavigationData.n6}>{NavigationData.n6}</Link>
        </li>
      </ul>
    </nav>
  );
};

export default MobileNavMenu;
