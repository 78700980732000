import * as yup from 'yup';
import 'yup-phone-lite';
import { ifscCodeRegex } from '../constants';

const phoneRegExp = /^^[6789]\d{9}$/;

export const addUserSchema = yup.object({
  firstName: yup
    .string()
    .min(2, 'First name must be at least 2 characters!')
    .max(30, 'First name must be at most 30 characters!')
    .required('First Name is required!'),
  lastName: yup
    .string()
    .min(2, 'Last name must be at least 2 characters!')
    .max(30, 'Last name must be at most 30 characters!')
    .required('Last Name is required!'),
  email: yup.string().email('Please enter valid email address!'),
  dob: yup
    .date()
    .typeError('The value must be a date (DD/MM/YYYY)')
    .test('dob', 'Age should be more than 2.5 years!', function (value) {
      const currentDate = new Date();
      const dob = new Date(value);
      const ageDifferenceInMilliseconds = currentDate - dob;
      const ageDifferenceInYears = ageDifferenceInMilliseconds / (1000 * 60 * 60 * 24 * 365);
      return ageDifferenceInYears >= 2.5 && ageDifferenceInYears <= 100;
    })
    .required('Date of birth is required!'),
  city: yup.object().required('City is required!'),
  state: yup.object().required('State is required!'),
  country: yup.object().required('Country is required!'),
  address: yup.string().min(2).max(400).required('Address is required!'),
  mobile: yup
    .string()
    .phone('IN', 'Please enter valid Indian mobile number!')
    .length(10, 'Mobile must be exact 10 digits.')
    .matches(phoneRegExp, 'Please enter valid Indian mobile number!')
    .required('Mobile is required!'),
  image: yup.string().url().required('Profile image is required!'),
  role: yup.object().required('Role is required!'),
  shift: yup.string().required('Shift is required!'),
  parentOrSpouseName: yup.string().min(2).max(100).required('This filed is required!'),
  parentOrSpouseContact: yup
    .string()
    .phone('IN', 'Please enter valid Indian mobile number!')
    .length(10, 'Mobile must be exact 10 digits.')
    .matches(phoneRegExp, 'Please enter valid Indian mobile number!')
    .required('Parent and Spouse mobile is required!'),
  emergencyContactRelation: yup.object().required('Relation filed is required!'),
  class: yup
    .string()
    .min(2, 'Class must be at least 2 characters!')
    .max(30, 'Class must be at most 30 characters!')
    .required('Class is required!'),
  classAttending: yup
    .string()
    .min(2, 'Class must be at least 2 characters!')
    .max(30, 'Class must be at most 30 characters!')
    .required('Class is required!'),
  classMedium: yup
    .string()
    .min(2, 'Medium must be at least 2 characters!')
    .max(30, 'Medium must be at most 30 characters!')
    .required('Medium is required!'),
  classSection: yup
    .string()
    .min(2, 'Section must be at least 2 characters!')
    .max(30, 'Section must be at most 30 characters!')
    .required('Section is required!'),
  // classStream: yup
  //   .string()
  //   .min(2, 'Stream must be at least 2 characters!')
  //   .max(30, 'Stream must be at most 30 characters!')
  //   .required('Stream is required!'),
  classGenderGroup: yup
    .string()
    .min(2, 'Class group must be at least 2 characters!')
    .max(30, 'Class group must be at most 30 characters!')
    .required('Class group is required!'),
  classesTeaching: yup
    .array('This is required field.')
    .of(
      yup.object().shape({
        class: yup.string().required('Class grade is required!'),
        classGenderGroup: yup.string().required('Group grade is required!'),
        classMedium: yup.string().required('Medium grade is required!'),
        classSection: yup.string().required('Section grade is required!'),
        classStream: yup.string().required('Stream grade is required!'),
        subject: yup.string().required('Subject grade is required!')
      })
    )
    .min(1),
  classTeacherOfClass: yup
    .string()
    .min(2, 'Class must be at least 2 characters!')
    .max(30, 'Class must be at most 30 characters!')
    .required('Class is required!'),
  subjectExpertise: yup.array('This is required field.').of(yup.string()).min(1),
  employmentGrade: yup.string().required('Employee grade is required!'),
  employmentType: yup.string().required('Employee type is required!'),
  employmentDesignation: yup.string().required('Employee designation is required!')
});

const commonObject = {
  firstName: yup
    .string()
    .min(2, 'First name must be at least 2 characters!')
    .max(30, 'First name must be at most 30 characters!')
    .required('First Name is required!'),
  lastName: yup
    .string()
    .min(2, 'Last name must be at least 2 characters!')
    .max(30, 'Last name must be at most 30 characters!')
    .required('Last Name is required!'),
  email: yup.string().email('Please enter valid email address!'),
  dob: yup
    .date()
    .typeError('The value must be a date (DD/MM/YYYY)')
    .test('dob', 'Age should be more than 2.5 years!', function (value) {
      const currentDate = new Date();
      const dob = new Date(value);
      const ageDifferenceInMilliseconds = currentDate - dob;
      const ageDifferenceInYears = ageDifferenceInMilliseconds / (1000 * 60 * 60 * 24 * 365);
      return ageDifferenceInYears >= 2.5 && ageDifferenceInYears <= 100;
    })
    .required('Date of birth is required!'),
  city: yup.object().required('City is required!'),
  state: yup.object().required('State is required!'),
  country: yup.object().required('Country is required!'),
  address: yup.string().min(2).max(200).required('Address is required!'),
  mobile: yup
    .string()
    .phone('IN', 'Please enter valid Indian mobile number!')
    .length(10, 'Mobile must be exact 10 digits.')
    .matches(phoneRegExp, 'Please enter valid Indian mobile number!')
    .required('Mobile is required!'),
  image: yup.string().url().required('Profile image is required!'),
  role: yup.object().required('Role is required!'),
  shift: yup.object().required('Shift is required!'),
  parentOrSpouseName: yup.string().min(2).max(100).required('This filed is required!'),
  parentOrSpouseContact: yup
    .string()
    .phone('IN', 'Please enter valid Indian mobile number!')
    .length(10, 'Mobile must be exact 10 digits.')
    .matches(phoneRegExp, 'Please enter valid Indian mobile number!')
    .required('Parent and Spouse mobile is required!'),
  emergencyContactRelation: yup.object().required('Relation filed is required!'),
  joiningDate: yup
    .date()
    .typeError('The value must be a date (DD/MM/YYYY)')
    // .test('joiningDate', 'Joining date should be more than 2.5 years!', function (value) {
    //   const currentDate = new Date();
    //   const dob = new Date(value);
    //   const ageDifferenceInMilliseconds = currentDate - dob;
    //   const ageDifferenceInYears = ageDifferenceInMilliseconds / (1000 * 60 * 60 * 24 * 365);
    //   return ageDifferenceInYears >= 2.5 && ageDifferenceInYears <= 100;
    // })
    .required('Joining date is required!'),
  bankDetails: yup.object().shape({
    ifscCode: yup.string().matches(ifscCodeRegex, 'Invalid IFSC code format')
  })
};

export const studentValidationSchema = yup.object({
  ...commonObject,
  classAttending: yup.object().required('Class is required!'),
  classMedium: yup.object().required('Medium is required!'),
  classSection: yup.object().required('Section is required!'),
  // classStream: yup.object().required('Stream is required!'),
  classGenderGroup: yup.object().required('Class group is required!')
});

export const employeeValidationSchema = yup.object({
  ...commonObject,
  employmentGrade: yup.object().required('Employee grade is required!'),
  employmentType: yup.object().required('Employee type is required!'),
  employmentDesignation: yup.object().required('Employee designation is required!'),
  yearsOfExperience: yup
    .number()
    .integer('Years of experience must be a whole number')
    .min(0, 'Years of experience must be a positive number')
    .max(50, 'Years of experience cannot exceed 50')
    .required('Years of experience is required!'),
  qualification: yup.object().required('Qualification is required!')
});

export const teacherValidationSchema = yup.object({
  ...employeeValidationSchema.fields,
  classesTeaching: yup
    .array('This is required field.')
    .of(
      yup.object().shape({
        class: yup.object().required('Class grade is required!'),
        classGenderGroup: yup.object().required('Group grade is required!'),
        classMedium: yup.object().required('Medium grade is required!'),
        classSection: yup.object().required('Section grade is required!'),
        subject: yup.object().required('Subject grade is required!')
        // classStream: yup.object().when(['class'], {
        //   is: (classValue) => {
        //     return classValue.class && (classValue.class.includes('12') || classValue.class.includes('11'));
        //   },
        //   then: yup.object().required('Stream is required for this class!'),
        //   otherwise: yup.object() // For other classes, stream is optional
        // })
      })
    )
    .min(1),
  classTeacherOfClass: yup.object().required('Class is required!'),
  subjectExpertise: yup.array('This is required field.').of(yup.object()).min(1)
});

export const schemaForUserType = (userType) => {
  if (userType === 'Student') {
    return studentValidationSchema;
  } else if (userType === 'Teacher') {
    return teacherValidationSchema;
  }

  return employeeValidationSchema;
};
