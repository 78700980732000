import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import toast, { Toaster } from 'react-hot-toast';
import LoadingIndicator from '../../../helpers/LoadingIndicator';
import { Box, Modal, Button } from '@mui/material';
import { FeesPerClassAPI, UsersAPI } from '../../../apis';
import { getCurrentLoggedInUser, isNotBlank } from '../../../utils';
import { BOX_STYLE, GRID_STYLE } from '../../../constants';
import { useHistory } from 'react-router-dom';
import { EmptyGridOverlay } from '../../../helpers/EmptyGridOverlay';
import { loadModelData } from '../userRegistration/LoadModelData';
import FeesPerClassForm from './FeesPerClassForm';

const FeesPerClassList = () => {
  const history = useHistory();
  const [modelData, setModelData] = useState({});
  const [feesPerClassRecords, setFeesPerClassRecords] = useState([]);
  const [editFeesPerClass, setEditFeesPerClass] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showAddUpdateUserDialog, setShowAddUpdateUserDialog] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const columns = [
    {
      field: 'feesForClass',
      headerName: 'Class',
      valueGetter: (params) => params.row.feesForClass.class || '-',
      width: 150
    },
    {
      field: 'classMedium',
      headerName: 'Medium',
      valueGetter: (params) => params.row.classMedium.classMedium || '-',
      width: 150
    },
    {
      field: 'classStream',
      headerName: 'Stream',
      valueGetter: (params) => (params.row.classStream && params.row.classStream.stream) || '-',
      width: 250
    },
    {
      field: 'feesType',
      headerName: 'Total Fees',
      valueGetter: (params) =>
        (params.row.feesTypes && params.row.feesTypes.reduce((total, fee) => total + fee.amount, 0)) || '-',
      width: 150
    }
  ];

  const loadCurrentUser = useCallback(async () => {
    try {
      const user = await getCurrentLoggedInUser();
      if (isNotBlank(user)) {
        setCurrentUser(user);
      } else {
        await UsersAPI.logout();
        history.push('/');
      }
    } catch (error) {
      console.log('Error loading current user:', error);
      await UsersAPI.logout();
      history.push('/');
    }
  }, [history]);

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await loadModelData();
      setModelData(data);
      await loadFeesForClass();
    } catch (error) {
      console.log('Error loading data:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const loadFeesForClass = useCallback(async (refresh = false) => {
    setLoading(true);
    try {
      const response = await FeesPerClassAPI.get(refresh);
      if (response.success) {
        setFeesPerClassRecords(response.feesPerClass || []);
      }
    } catch (error) {
      console.log('Error loading fees for class:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadData();
    loadCurrentUser();
  }, [loadData, loadCurrentUser]);

  const handleRowClick = (content) => {
    setEditFeesPerClass(content.row);
    setShowAddUpdateUserDialog(true);
  };

  const closeAddUpdateUserDialog = (event, reason) => {
    if (reason !== 'backdropClick') {
      setShowAddUpdateUserDialog(false);
    }

    setEditFeesPerClass(null);
  };

  const handleResponseCallback = async (response) => {
    if (response.success) {
      closeAddUpdateUserDialog();
      await loadFeesForClass(true);
      toast.success(response.message);
    } else {
      if (response.cancel) {
        closeAddUpdateUserDialog();
      }
      toast.error(response.message);
    }
  };

  const renderDataGrid = () => (
    <div style={{ minHeight: 400, width: '100%' }}>
      <DataGrid
        getRowId={(row) => row._id.valueOf()}
        slots={{ noRowsOverlay: EmptyGridOverlay }}
        rows={feesPerClassRecords}
        columns={columns}
        initialState={{ pagination: { paginationModel: { pageSize: 13 } } }}
        onRowClick={handleRowClick}
        pageSizeOptions={[13]}
        sx={GRID_STYLE}
      />
    </div>
  );

  const renderAddUpdateUserDialog = () => (
    <Modal
      open={showAddUpdateUserDialog}
      onClose={closeAddUpdateUserDialog}
      aria-labelledby='parent-modal-title'
      aria-describedby='parent-modal-description'
    >
      <Box sx={BOX_STYLE}>
        <FeesPerClassForm
          feesPerClassRecords={feesPerClassRecords}
          editFeesPerClass={editFeesPerClass}
          modelData={modelData}
          responseCallBack={handleResponseCallback}
          setLoading={setLoading}
          currentUser={currentUser}
        />
      </Box>
    </Modal>
  );

  const handleRefreshClick = () => {
    loadFeesForClass(true);
  };

  const handleAddFeesClick = () => {
    setShowAddUpdateUserDialog(true);
  };

  const renderActionButtons = () => (
    <div className='d-flex flex-row justify-content-start' style={{ height: '60px', margin: '10px 0' }}>
      <Button variant='contained' onClick={handleAddFeesClick} style={{ maxWidth: '200px', marginRight: '10px' }}>
        Add Fees For Class
      </Button>
      <Button variant='contained' onClick={handleRefreshClick} style={{ maxWidth: '200px', marginLeft: '10px' }}>
        Refresh Classes
      </Button>
    </div>
  );

  return (
    <Fragment>
      <div key='Fees-Collection' className='p-3 card w-100'>
        <div className='d-flex flex-column justify-content-between'>
          <h2 style={{ color: 'gray' }}>Fees Per Class</h2>
          {renderActionButtons()}
          {renderDataGrid()}
          {renderAddUpdateUserDialog()}
        </div>
      </div>
      <Toaster />
      <LoadingIndicator open={loading} />
    </Fragment>
  );
};

export default FeesPerClassList;
