import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import Swiper from 'react-id-swiper';
import sliderDataOld from '../../../data/home/sliderData.json';
import HeroSliderTwoSingle from '../../../components/hero-sliders/hero-slider-two';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';

import styles from './HeroSliderTwo.module.scss';
import './Style.css';
import { LandingImagesAPI } from '../../../apis/LandingImagesAPI';
import LoadingIndicator from '../../../helpers/LoadingIndicator';

const HeroSlider = forwardRef((_props, ref) => {
  const [loading, setLoading] = useState(false);
  const [sliderData, setSliderData] = useState(sliderDataOld);

  const params = {
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    watchSlidesVisibility: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },

    renderPrevButton: () => (
      <button className={`${styles.swiperButtonPrev} ${styles.htSwiperButtonNav} swiper-button-prev`}>
        <IoIosArrowBack />
      </button>
    ),

    renderNextButton: () => (
      <button className={`${styles.swiperButtonNext} ${styles.htSwiperButtonNav} swiper-button-next`}>
        <IoIosArrowForward />
      </button>
    )
  };

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await LandingImagesAPI.getAll();
      const landingInfo = (data && data.landingInfo) || [];
      setSliderData(landingInfo);
    } catch (error) {
      console.log('Error loading data:', error);
      setSliderData([]);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className={`hero-slider bg-img ${styles.heroSlider} ${styles.sliderTwo}`} ref={ref}>
      <div className='slideActivation'>
        <Swiper {...params}>
          {sliderData &&
            sliderData.map((single, key) => (
              <HeroSliderTwoSingle
                data={single}
                key={single._id || key}
                styles={styles}
                sliderClassName='swiper-slide'
              />
            ))}
        </Swiper>
      </div>
      <LoadingIndicator open={loading} />
    </div>
  );
});

HeroSlider.displayName = 'HeroSlider';
export default HeroSlider;
