import axios from 'axios';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import Swiper from 'react-id-swiper';
import eventIcon from '../../../assets/images/banner/upcoming-event.jpg';
import { baseURL } from '../../../constants';
import { formattedDate } from '../../../utils';
import './Upcomingevent.css';

const UpComingEvent = forwardRef((_props, ref) => {
  const params = {
    direction: 'vertical',
    loop: true,
    grabCursor: true,
    slidesPerView: 1,
    spaceBetween: 30,
    autoplay: {
      delay: 10000,
      disableOnInteraction: true
    }
  };

  console.log('rerendering');

  let getUrl = `${baseURL}/api/dates/all`;

  const [upcomingDataSlider, setUpcomingDataSlider] = useState([]);

  function getMonthYear(dtStr) {
    const dateObject = new Date(dtStr);
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    const monthNum = dateObject.getMonth();
    const year = dateObject.getFullYear().toString().slice(2, 4);
    return months[monthNum] + '-' + year;
  }

  function getDay(dtStr) {
    const day = new Date(dtStr).getDate();
    return day;
  }

  useEffect(() => {
    axios
      .get(getUrl)
      .then((resp) => {
        if (resp.data.success) {
          setUpcomingDataSlider(resp.data.data);
        }
      })
      .catch((e) => {
        console.log('Error ', e);
      });
  }, []);

  const formattedDateLocal = useCallback((eventDate) => {
    const dateFormatted = formattedDate({ date: new Date(eventDate), format: 'MMM DD YYYY', isString: true });
    return dateFormatted;
  }, []);

  const swiperItems = useCallback(() => {
    if (upcomingDataSlider && upcomingDataSlider.length > 0) {
      return upcomingDataSlider.map((single, key) => (
        <div className='col-lg-12 mt--5 swiper-slide' key={`key + ${key}`}>
          <h2>{single.title}</h2>
          <div className='event-img'>
            <img
              src={eventIcon}
              className='event-image-img'
              alt='event-img'
              style={{ objectFit: 'cover', objectPosition: 'center' }}
            />
            <div className='event-img-overlay'></div>
            <div>
              <span className='event-date1'>{formattedDateLocal(single.date)}</span>
              <div>
                <div className='event-img-text p-3'>
                  <h3 className='pb-0 mb-0 text-white'>{single.title}</h3>
                  <div className='border-title-img'></div>
                  <p className='mt-3 text-white'>{single.content}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ));
    }
  }, [upcomingDataSlider, formattedDateLocal]);

  return (
    upcomingDataSlider &&
    upcomingDataSlider.length > 0 && (
      <div className='row justify-content-center align-items-center mt-5' ref={ref}>
        <div className='col-10 upcomingevent pt-5'>
          <div className='row upcoming-even-wrapper'>
            <div className='col-lg-6'>
              <div className='swiper-wrapper' style={{ height: 360 }}>
                <Swiper {...params} classNam={'swiper-container'}>
                  {swiperItems()}
                </Swiper>
              </div>
            </div>
            <div className='col-lg-6 mt-5 mt-lg-0'>
              <h2>{'Upcoming Events'}</h2>
              <div className='container-event d-flex flex-column'>
                {upcomingDataSlider.map((single, key) => {
                  return (
                    <div className='event-date-list' key={key}>
                      <div className='row event-list d-flex'>
                        <div className='col-3 col-lg-2 event-date'>
                          <div className='event-date-color'>
                            <div className='d-flex flex-column align-items-center justify-content-center event-border-dash  m-1 '>
                              <span className='event-date1'>{getDay(single.date)}</span>
                              <span style={{ textTransform: 'capitalize' }} className='event-date2'>
                                {getMonthYear(single.date)}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className='col-9 col-lg-10 event-text'>
                          <h3 className='pb-0 mb-0'>{single.title}</h3>
                          <div className='border-title'></div>
                          <p className='mt-md-3'>{single.content}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
});

UpComingEvent.displayName = 'UpComingEvent';
export default UpComingEvent;
