import axios from 'axios';
import React, { useEffect, useState, useMemo } from 'react';
import { GiTeacher, GiBookshelf } from 'react-icons/gi';
import { SiGoogleclassroom } from 'react-icons/si';
import { RiCalendarLine } from 'react-icons/ri';
import './Parallax.css';
import { baseURL } from '../../../constants';

function Parallax() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await axios.get(`${baseURL}/api/about/all`);
        if (response.success) {
          setData(response.data[0]);
        }
      } catch (e) {
        console.error('Error fetching data:', e);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const icons = useMemo(
    () => [
      { icon: <GiTeacher />, label: 'Teachers', value: (data && data.teachers) || '-' },
      { icon: <SiGoogleclassroom />, label: 'Students', value: (data && data.students) || '-' },
      { icon: <GiBookshelf />, label: 'Courses', value: (data && data.courses) || '-' },
      { icon: <RiCalendarLine />, label: 'Years Exp.', value: (data && data.years) || '-' }
    ],
    [data]
  );

  if (loading) {
    return <div>Loading...</div>; // Replace with a better loading indicator if needed
  }

  return (
    <div className='row justify-content-center align-items-center mt-5'>
      <div className='col-10 parallax'>
        <div className='row justify-content-center align-items-center m-5 p-4'>
          {icons.map((item, idx) => (
            <div key={idx} className='col-lg-3 d-flex justify-content-center'>
              <div className='parallax-icon'>{item.icon}</div>
              <div className='ml-2'>
                <p className='parallax-number'>{item.value}</p>
                <p className='parallax-text'>{item.label}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Parallax;
