import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import Login from '../containers/login/Login';
import Breadcrumb from '../components/UI/breadcrumb';
import LayoutOne from '../layouts';

const LoginPage = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>Login</title>
        <meta name='description' content='Login page of event management service.' />
      </MetaTags>
      <LayoutOne footerBg='white'>
        <Breadcrumb title='Login' />
        <div style={{ marginBottom: '30px' }}></div>
        <Login />
      </LayoutOne>
    </Fragment>
  );
};

export default LoginPage;
