import * as yup from 'yup';

export const feesCollectionSchema = yup.object({
  feesType: yup.string().required('Fees type is required!'),
  receiptAmount: yup
    .number()
    .typeError('Amount must be a number')
    .required('Amount is required!')
    .moreThan(-100000, 'You can not refund more than 1,00,000 Rupees')
    .lessThan(500001, 'Amount must be less than or equal to 5,00,000'),
  paymentMethod: yup.string().required('Payment method is required!')
});
