import axios from 'axios';
import { notification } from 'antd';
import { isNotBlank } from '../../utils';
import { baseURL, KEYS } from '../../constants';

const errorHandler = (error) => {
  const statusCode = error.response && error.response.status;

  if (error.code === 'ERR_CANCELED') {
    notification.error({
      placement: 'bottomRight',
      description: 'API canceled!'
    });

    return Promise.resolve();
  }

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error);
  }

  return Promise.reject(error);
};

export const api = axios.create({
  baseURL: baseURL,
  timeout: process.env.REACT_APP_TIMEOUT
});

api.interceptors.response.use(
  (res) => {
    res.durationInMs = new Date().getTime() - res.config.meta.requestStartedAt;
    return res;
  },
  (error) => {
    error.durationInMs = new Date().getTime() - error.config.meta.requestStartedAt;
    return errorHandler(error);
  }
);

api.interceptors.request.use((req) => {
  req.meta = req.meta || {};
  req.meta.requestStartedAt = new Date().getTime();

  const token = localStorage.getItem(KEYS.TOKEN);
  if (isNotBlank(token)) {
    req.headers.Authorization = token;
  }

  return req;
});
