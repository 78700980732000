import { KEYS } from '../constants';
import { isNotBlank } from '../utils';
import { api } from './configs/axiosConfigs';
import { defineCancelApiObject } from './configs/axiosUtils';
import endPoints from './configs/endPoints';
import methods from './configs/methods';

export const LogoAPI = {
  get: async (refresh = false, cancel = false) => {
    try {
      if (refresh) {
        localStorage.removeItem(KEYS.LOGO_KEY);
      }

      const logoJSONString = localStorage.getItem(KEYS.LOGO_KEY);
      if (logoJSONString && logoJSONString.trim().length > 0) {
        const logoDetails = JSON.parse(logoJSONString);
        return { success: true, message: 'Success', logoUrl: logoDetails || '' };
      } else {
        const response = await api.request({
          url: endPoints.getLogo,
          method: methods.GET,
          signal: cancel ? cancelApiObject['get'].handleRequestCancellation().signal : undefined
        });

        if (response.data.success) {
          if (isNotBlank(response.data.data)) {
            const firstObject = response.data.data[0];
            const logoUrl = firstObject.imgUrl || '';
            localStorage.setItem(KEYS.LOGO_KEY, JSON.stringify(logoUrl));
            return { success: true, message: response.data.message, logoUrl };
          }
        }

        return { success: false, message: response.data.message };
      }
    } catch (error) {
      console.log('🪵 : get: : error:', JSON.stringify(error));
    }
  }
};

const cancelApiObject = defineCancelApiObject(LogoAPI);
