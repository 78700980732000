import React, { useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { KEYS } from '../constants';

const PrivateRoute = ({ children, rest }) => {
  const [isAuth, setAuthentication] = useState(false);
  const [authChecked, setAuthCheck] = useState(false);

  if (!authChecked && localStorage.getItem(KEYS.TOKEN)) {
    setAuthentication(true);
    setAuthCheck(true);
  }

  // if(authChecked){
  return (
    <Route
      {...rest}
      render={() => {
        return isAuth === true ? children : <Redirect to='/login' />;
      }}
    />
  );
  // }

  // if(authChecked){
  // return(
  //    <>
  //        {/* {isAuth?(children):(<Redirect to="/login" />)}
  //         */}
  //         {component}
  //    </>
  //    )
};

export default PrivateRoute;
