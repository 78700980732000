import React, { Fragment } from 'react';

const AdmissionCard = ({ data, del, edit }) => {
  const name = `Name: ${data.fullName}`;
  const email = `Email: ${data.email}`;
  const mobile = `Mobile: ${data.mobile}`;
  const message = `Message: ${data.message}`;

  return (
    <Fragment>
      <div key={data._id} className='p-3 card mt-3' style={{ width: '100%' }}>
        <div className='d-flex flex-row justify-content-between'>
          <div className='d-flex flex-column justify-content-center'>
            <p className='w-auto'>{name}</p>
            <p className='w-auto'>{email}</p>
            <p className='w-auto'>{mobile}</p>
            <p className='w-auto'>{message}</p>
          </div>
          <div className='w-auto d-flex flex-column justify-content-center align-items-center'>
            <button className='btn btn-edit btn-sm btn-primary' onClick={() => edit(data._id)}>
              Edit
            </button>
            <br />
            <button className='btn btn-delete btn-sm btn-danger' onClick={() => del(data._id)}>
              Delete
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AdmissionCard;
