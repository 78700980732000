import React, { useEffect, useState } from 'react';
import Swiper from 'react-id-swiper';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import Title from '../../../data/home/newstitle.json';
import { BsAlarm } from 'react-icons/bs';
import { MdMessage } from 'react-icons/md';
import './News.css';
import axios from 'axios';
import { baseURL } from '../../../constants';

function News({
  loop = true,
  grabCursor = true,
  autoplayDelay = 10000,
  autoplayDisableOnInteraction = true,
  breakpoints = {
    1024: { slidesPerView: 3 },
    768: { slidesPerView: 2 },
    640: { slidesPerView: 2 },
    320: { slidesPerView: 1 }
  },
  watchSlidesVisibility = true,
  navigation = {
    nextEl: '.news-slider-button-next #nextbtn',
    prevEl: '.news-slider-button-prev'
  }
}) {
  const [swiper, updateSwiper] = useState(null);
  const [newsDetails, setNewsDetails] = useState();
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (!dataLoaded) {
      const getUrl = `${baseURL}/api/news/all`;
      axios
        .get(getUrl)
        .then((resp) => {
          if (resp.data.success) {
            const localData = resp.data.data;
            if (localData && localData.length > 0) {
              setNewsDetails(localData);
              setDataLoaded(true);
            }
          }
        })
        .catch((e) => {
          console.log('Error ', e);
        });
    }
  }, [dataLoaded]);

  const params = {
    loop,
    grabCursor,
    autoplay: {
      delay: autoplayDelay,
      disableOnInteraction: autoplayDisableOnInteraction
    },
    breakpoints,
    watchSlidesVisibility,
    navigation
  };

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  const swiperSlide = (single, key) => {
    return (
      <div className='col-lg-4 col-md-6 col-sm-12 portfolio-item filter-1' key={key}>
        <div className='card news-card-size'>
          <img src={single.imgUrl} alt=' ' className='img-fluid news-img' />
          <div className='card-body portfolio-text p-2'>
            <h3 className='card-title news-title pl-2 mt-2'>{single.title}</h3>
            <div className='pl-2 news-event-text-wrapper'>
              <div className='news-event-text-lines'>
                <span className='icon-link'>
                  <span className='mr-1 news-icon'>
                    <BsAlarm />
                  </span>
                </span>
                <span className='icon-link'>
                  <span className='ml-2 mr-1 news-icon'>
                    <MdMessage />
                  </span>
                  {single.content}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const swiperItems = () => {
    if (newsDetails && newsDetails.length > 0) {
      return newsDetails.map((single, index) => swiperSlide(single, single._id || index));
    }

    return [];
  };

  return (
    <div className='row justify-content-center align-items-center mt-5'>
      <div className='col-10 news'>
        <div className='news-slider-heading'>
          <div className='news-title'>
            <h2 className='ml-1 mt-5 mb-1'>{Title.title}</h2>
            <div className='news-title-border mb-3 ml-1'></div>
          </div>
          <div className='arrow-btn'>
            <span onClick={goNext}>
              <IoIosArrowBack />
            </span>
            <span onClick={goPrev}>
              <IoIosArrowForward />
            </span>
          </div>
        </div>

        <div className='row news-slider-row news-slider-bottom-margin'>
          {dataLoaded && (
            <Swiper getSwiper={updateSwiper} {...params}>
              {swiperItems()}
            </Swiper>
          )}
        </div>
      </div>
    </div>
  );
}
export default News;
