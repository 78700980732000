import React, { Fragment, useState } from 'react';
import { useFormik } from 'formik';
import './contactUsPage.css';
import LayoutOne from '../../../layouts';
import Breadcrumb from '../../../components/UI/breadcrumb';
import { MetaTags } from 'react-meta-tags';
import axios from 'axios';
import { admissionsSchema } from '../../../yupSchema/admissionsSchema';
import toast, { Toaster } from 'react-hot-toast';
import { baseURL } from '../../../constants';

const ContactUsPage = () => {
  // const [fullName, setFullName] = useState('');
  // const [email, setEmail] = useState('');
  // const [mobile, setMobile] = useState('');
  // const [message, setMessage] = useState('');
  // const [baseUrl, setBaseUrl] = useState(environment.baseUrl);

  const [errMessage, setErrorMessage] = useState('');
  const [url] = useState(`${baseURL}/api/admissions/create-new`);

  const initialValues = {
    fullName: '',
    email: '',
    mobile: '',
    message: ''
  };

  const Formik = useFormik({
    initialValues,
    validationSchema: admissionsSchema,
    onSubmit: (values) => {
      const fd = new FormData();
      fd.append('fullName', values.fullName);
      fd.append('email', values.email);
      fd.append('mobile', values.mobile);
      fd.append('message', values.message);
      axios
        .post(url, fd)
        .then((resp) => {
          console.log('🪵 : .then : resp:', resp);
          toast.success('Thank you for reaching out to us! We will get back to you as soon as possible.');
          Formik.resetForm();
        })
        .catch((err) => {
          console.log(err, 'error');
          toast.error(
            'Ops! Unfortunately we are not able to connect to our servers, please contact help desk for further assistance.'
          );

          console.log('🪵 : ContactUsPage : errMessage:', errMessage);

          if (err.response.status === 404) {
            setErrorMessage('Server Error, Try after sometime!');
          } else {
            setErrorMessage(err.response.data.message);
          }
        });
    }
  });

  return (
    <Fragment>
      <MetaTags>
        <title>Please Provide Details</title>
        <meta name='description' content='Admission of students' />
      </MetaTags>
      <LayoutOne footerBg='white'>
        <Breadcrumb title='Admission' />
        <div className='contact-us-container'>
          <form className='contact-us-form' onSubmit={Formik.handleSubmit} method='POST'>
            <div className='mb-3'>
              <label className='form-label'>Full Name</label>
              <input
                type='fullName'
                name='fullName'
                className='form-control'
                id='fullName'
                aria-describedby='fullNameHelp'
                value={Formik.values.fullName}
                onChange={Formik.handleChange}
                onBlur={Formik.handleBlur}
                placeholder='Full Name *'
              />
              {Formik.touched.fullName && Formik.errors.fullName ? (
                <p className='form-error'>{Formik.errors.fullName}</p>
              ) : null}
            </div>

            <div className='mb-3'>
              <label className='form-label'>Email</label>
              <input
                type='email'
                name='email'
                className='form-control'
                id='email'
                aria-describedby='emailHelp'
                value={Formik.values.email}
                onChange={Formik.handleChange}
                onBlur={Formik.handleBlur}
                placeholder='Email *'
              />
              {Formik.touched.email && Formik.errors.email ? <p className='form-error'>{Formik.errors.email}</p> : null}
            </div>

            <div className='mb-3'>
              <label className='form-label'>Mobile</label>
              <input
                type='mobile'
                name='mobile'
                className='form-control'
                id='mobile'
                aria-describedby='mobileHelp'
                value={Formik.values.mobile}
                onChange={Formik.handleChange}
                onBlur={Formik.handleBlur}
                placeholder='Mobile *'
              />
              {Formik.touched.mobile && Formik.errors.mobile ? (
                <p className='form-error'>{Formik.errors.mobile}</p>
              ) : null}
            </div>

            <div className='mb-3'>
              <label className='form-label'>Message</label>
              <input
                type='message'
                name='message'
                className='form-control'
                id='message'
                aria-describedby='messageHelp'
                value={Formik.values.message}
                onChange={Formik.handleChange}
                onBlur={Formik.handleBlur}
                placeholder='Message'
              />
              {Formik.touched.message && Formik.errors.message ? (
                <p className='form-error'>{Formik.errors.message}</p>
              ) : null}
            </div>

            <button type='submit' className='btn btn-dark'>
              Submit
            </button>
          </form>
        </div>
      </LayoutOne>
      <Toaster />
    </Fragment>
  );
};

export default ContactUsPage;
