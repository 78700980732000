import React from 'react';
import './profile-image.scss';
import Avatar from '@mui/material/Avatar';
import SecureImage from '../../../helpers/SecureImage';

const ProfileImage = (props) => {
  const { src, alt } = props;

  if (src) {
    return <SecureImage src={src || ''} className='circular-image' alt={alt || 'Image'} />;
  }

  return <Avatar src={''} alt={alt || 'Image'} />;
};

export default ProfileImage;
