import {
  ClassesAPI,
  ClassGenderGroupsAPI,
  StreamsAPI,
  ClassMediumsAPI,
  ClassSectionsAPI,
  CitiesAPI,
  StatesAPI,
  CountriesAPI,
  DesignationsAPI,
  EmployeeGradesAPI,
  EmploymentTypesAPI,
  GendersAPI,
  RelationsAPI,
  RolesAPI,
  SubjectsAPI,
  WorkShiftsAPI,
  QualificationsAPI
} from '../../../apis';
import { KEYS } from '../../../constants';
import { isBlank } from '../../../utils';

const getQualifications = async () => {
  try {
    let qualifications = localStorage.getItem(KEYS.QUALIFICATIONS);

    if (isBlank(qualifications)) {
      const response = await QualificationsAPI.get();
      qualifications = response.data || [];
      localStorage.setItem(KEYS.QUALIFICATIONS, JSON.stringify(qualifications));
    } else {
      qualifications = JSON.parse(qualifications);
    }

    // console.log('🪵 : getAllQualifications : qualifications:', qualifications);
    return qualifications;
  } catch (error) {
    console.error(`Request getAllQualifications error ${JSON.stringify(error)}`);
    return [];
  }
};

const getAllClasses = async () => {
  try {
    let classes = localStorage.getItem(KEYS.CLASSES);

    if (isBlank(classes)) {
      const response = await ClassesAPI.get();
      classes = response.data || [];
      localStorage.setItem(KEYS.CLASSES, JSON.stringify(classes));
    } else {
      classes = JSON.parse(classes);
    }

    // console.log('🪵 : getAllClasses : classes:', classes);
    return classes;
  } catch (error) {
    console.error(`Request getAllClasses error ${JSON.stringify(error)}`);
    return [];
  }
};

const getAllClassGenderGroups = async () => {
  try {
    let classGenderGroup = localStorage.getItem(KEYS.CLASS_GENDER_GROUPS);

    if (isBlank(classGenderGroup)) {
      const response = await ClassGenderGroupsAPI.get();
      classGenderGroup = response.data || [];
      localStorage.setItem(KEYS.CLASS_GENDER_GROUPS, JSON.stringify(classGenderGroup));
    } else {
      classGenderGroup = JSON.parse(classGenderGroup);
    }

    // console.log('🪵 : getAllClassGenderGroups : classGenderGroup:', classGenderGroup);
    return classGenderGroup;
  } catch (error) {
    console.error(`Request getAllClassGenderGroups error ${JSON.stringify(error)}`);
    return [];
  }
};

const getAllStreams = async () => {
  try {
    let streams = localStorage.getItem(KEYS.STREAMS);

    if (isBlank(streams)) {
      const response = await StreamsAPI.get();
      streams = response.data || [];
      localStorage.setItem(KEYS.STREAMS, JSON.stringify(streams));
    } else {
      streams = JSON.parse(streams);
    }

    // console.log('🪵 : getAllStreams : response:', streams);
    return streams;
  } catch (error) {
    console.error(`Request getAllStreams error ${JSON.stringify(error)}`);
    return [];
  }
};

const getAllClassMediums = async () => {
  try {
    let mediums = localStorage.getItem(KEYS.CLASS_MEDIUMS);

    if (isBlank(mediums)) {
      const response = await ClassMediumsAPI.get();
      mediums = response.data || [];
      localStorage.setItem(KEYS.CLASS_MEDIUMS, JSON.stringify(mediums));
    } else {
      mediums = JSON.parse(mediums);
    }

    // console.log('🪵 : getAllClassMediums : response:', mediums);
    return mediums;
  } catch (error) {
    console.error(`Request getAllClassMediums error ${JSON.stringify(error)}`);
    return [];
  }
};

const getAllClassSections = async () => {
  try {
    let sections = localStorage.getItem(KEYS.CLASS_SECTIONS);
    if (isBlank(sections)) {
      const response = await ClassSectionsAPI.get();
      sections = response.data || [];
      localStorage.setItem(KEYS.CLASS_SECTIONS, JSON.stringify(sections));
    } else {
      sections = JSON.parse(sections);
    }

    // console.log('🪵 : getAllClassSections : response:', sections);
    return sections;
  } catch (error) {
    console.error(`Request getAllClassSections error ${JSON.stringify(error)}`);
    return [];
  }
};

const getAllCities = async () => {
  try {
    let cities = localStorage.getItem(KEYS.CITIES);
    if (isBlank(cities)) {
      const response = await CitiesAPI.get();
      cities = response.data || [];
      localStorage.setItem(KEYS.CITIES, JSON.stringify(cities));
    } else {
      cities = JSON.parse(cities);
    }

    // console.log('🪵 : getAllCities : response:', cities);
    return cities;
  } catch (error) {
    console.error(`Request getAllCities error ${JSON.stringify(error)}`);
    return [];
  }
};

const getAllStates = async () => {
  try {
    let states = localStorage.getItem(KEYS.STATES);
    if (isBlank(states)) {
      const response = await StatesAPI.get();
      states = response.data || [];
      localStorage.setItem(KEYS.STATES, JSON.stringify(states));
    } else {
      states = JSON.parse(states);
    }

    // console.log('🪵 : getAllStates : response:', states);
    return states;
  } catch (error) {
    console.error(`Request getAllStates error ${JSON.stringify(error)}`);
    return [];
  }
};

const getAllCountries = async () => {
  try {
    let countries = localStorage.getItem(KEYS.COUNTRIES);
    if (isBlank(countries)) {
      const response = await CountriesAPI.get();
      countries = response.data || [];
      localStorage.setItem(KEYS.COUNTRIES, JSON.stringify(countries));
    } else {
      countries = JSON.parse(countries);
    }

    // console.log('🪵 : getAllCountries : response:', countries);
    return countries;
  } catch (error) {
    console.error(`Request getAllCountries error ${JSON.stringify(error)}`);
    return [];
  }
};

const getAllDesignations = async () => {
  try {
    let designations = localStorage.getItem(KEYS.DESIGNATIONS);
    if (isBlank(designations)) {
      const response = await DesignationsAPI.get();
      designations = response.data || [];
      localStorage.setItem(KEYS.DESIGNATIONS, JSON.stringify(designations));
    } else {
      designations = JSON.parse(designations);
    }

    // console.log('🪵 : getAllDesignations : response:', designations);
    return designations;
  } catch (error) {
    console.error(`Request getAllDesignations error ${JSON.stringify(error)}`);
    return [];
  }
};

const getAllEmployeeGrades = async () => {
  try {
    let employeeGrades = localStorage.getItem(KEYS.EMPLOYEE_GRADES);
    if (isBlank(employeeGrades)) {
      const response = await EmployeeGradesAPI.get();
      employeeGrades = response.data || [];
      localStorage.setItem(KEYS.EMPLOYEE_GRADES, JSON.stringify(employeeGrades));
    } else {
      employeeGrades = JSON.parse(employeeGrades);
    }

    // console.log('🪵 : getAllEmployeeGrades : response:', employeeGrades);
    return employeeGrades;
  } catch (error) {
    console.error(`Request getAllEmployeeGrades error ${JSON.stringify(error)}`);
    return [];
  }
};

const getAllEmploymentTypes = async () => {
  try {
    let employmentTypes = localStorage.getItem(KEYS.EMPLOYMENT_TYPES);
    if (isBlank(employmentTypes)) {
      const response = await EmploymentTypesAPI.get();
      employmentTypes = response.data || [];
      localStorage.setItem(KEYS.EMPLOYMENT_TYPES, JSON.stringify(employmentTypes));
    } else {
      employmentTypes = JSON.parse(employmentTypes);
    }

    // console.log('🪵 : getAllEmploymentTypes : response:', employmentTypes);
    return employmentTypes;
  } catch (error) {
    console.error(`Request getAllEmploymentTypes error ${JSON.stringify(error)}`);
    return [];
  }
};

const getAllGenders = async () => {
  try {
    let genders = localStorage.getItem(KEYS.GENDERS);
    if (isBlank(genders)) {
      const response = await GendersAPI.get();
      genders = response.data || [];
      localStorage.setItem(KEYS.GENDERS, JSON.stringify(genders));
    } else {
      genders = JSON.parse(genders);
    }

    // console.log('🪵 : getAllGenders : response:', genders);
    return genders;
  } catch (error) {
    console.error(`Request getAllGenders error ${JSON.stringify(error)}`);
    return [];
  }
};

const getAllRelations = async () => {
  try {
    let relations = localStorage.getItem(KEYS.RELATIONS);
    if (isBlank(relations)) {
      const response = await RelationsAPI.get();
      relations = response.data || [];
      localStorage.setItem(KEYS.RELATIONS, JSON.stringify(relations));
    } else {
      relations = JSON.parse(relations);
    }

    // console.log('🪵 : getAllRelations : response:', relations);
    return relations;
  } catch (error) {
    console.error(`Request getAllRelations error ${JSON.stringify(error)}`);
    return [];
  }
};
const getAllRoles = async () => {
  try {
    let roles = localStorage.getItem(KEYS.ROLES);
    if (isBlank(roles)) {
      const response = await RolesAPI.get();
      roles = response.data || [];
      localStorage.setItem(KEYS.ROLES, JSON.stringify(roles));
    } else {
      roles = JSON.parse(roles);
    }

    // console.log('🪵 : getAllRoles : response:', roles);
    return roles;
  } catch (error) {
    console.error(`Request getAllRoles error ${JSON.stringify(error)}`);
    return [];
  }
};

const getAllSubjects = async () => {
  try {
    let subjects = localStorage.getItem(KEYS.SUBJECTS);
    if (isBlank(subjects)) {
      const response = await SubjectsAPI.get();
      subjects = response.data || [];
      localStorage.setItem(KEYS.SUBJECTS, JSON.stringify(subjects));
    } else {
      subjects = JSON.parse(subjects);
    }

    // console.log('🪵 : getAllSubjects : response:', subjects);
    return subjects;
  } catch (error) {
    console.error(`Request getAllSubjects error ${JSON.stringify(error)}`);
    return [];
  }
};

const getAllWorkShifts = async () => {
  try {
    let workShift = localStorage.getItem(KEYS.WORK_SHIFTS);
    if (isBlank(workShift)) {
      const response = await WorkShiftsAPI.get();
      workShift = response.data || [];
      localStorage.setItem(KEYS.WORK_SHIFTS, JSON.stringify(workShift));
    } else {
      workShift = JSON.parse(workShift);
    }

    // console.log('🪵 : getAllWorkShifts : response:', workShift);
    return workShift;
  } catch (error) {
    console.error(`Request getAllWorkShifts error ${JSON.stringify(error)}`);
    return [];
  }
};

export const loadModelData = async () => {
  const [
    classes,
    classGenderGroups,
    streams,
    classMediums,
    classSections,
    cities,
    states,
    countries,
    designations,
    employeeGrades,
    employmentTypes,
    genders,
    relations,
    roles,
    subjects,
    workShifts,
    qualifications
  ] = await Promise.all([
    getAllClasses(),
    getAllClassGenderGroups(),
    getAllStreams(),
    getAllClassMediums(),
    getAllClassSections(),
    getAllCities(),
    getAllStates(),
    getAllCountries(),
    getAllDesignations(),
    getAllEmployeeGrades(),
    getAllEmploymentTypes(),
    getAllGenders(),
    getAllRelations(),
    getAllRoles(),
    getAllSubjects(),
    getAllWorkShifts(),
    getQualifications()
  ]);

  return {
    classes,
    classGenderGroups,
    streams,
    classMediums,
    classSections,
    cities,
    states,
    countries,
    designations,
    employeeGrades,
    employmentTypes,
    genders,
    relations,
    roles,
    subjects,
    workShifts,
    qualifications
  };
};
