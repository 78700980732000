import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseURL } from '../../../constants';
import { FaFacebookF, FaInstagram, FaTwitter, FaPhone, FaRegEnvelope } from 'react-icons/fa';

const MobileWidgets = ({ styles }) => {
  const [contactData, setContactData] = useState();
  const [parsedPhoneNumbers, setParsedPhoneNumbers] = useState();
  const [parsedEmail, setParsedEmail] = useState();
  const [parsedTwitter, setParsedTwitter] = useState();
  const [parsedFacebook, setParsedFacebook] = useState();
  const [parsedInstagram, setParsedInstagram] = useState();

  let getUrl = `${baseURL}/api/contact/all`;

  useEffect(() => {
    if (contactData) {
      if (contactData.phone && contactData.phone.trim() !== '') {
        const trimmedPhones = contactData.phone.split(',').map((phone) => phone.trim());
        setParsedPhoneNumbers(trimmedPhones);
      } else {
        console.log('Phone data is empty or undefined or null.');
      }

      setParsedEmail(contactData.email || '');
      setParsedTwitter(contactData.twt || 'https://twitter.com');
      setParsedFacebook(contactData.fb || 'https://facebook.com');
      setParsedInstagram(contactData.in || 'https://instagram.com');
    }
  }, [contactData]);

  useEffect(() => {
    axios
      .get(getUrl)
      .then((resp) => {
        if (resp.data.success) {
          setContactData(resp.data.data[0]);
        }
      })
      .catch((e) => {
        console.log('Error ', e);
      });
  }, []);

  return (
    <div className={styles.offcanvasWidgetArea}>
      <div className={styles.offCanvasContactWidget}>
        <div className={styles.headerContactInfo}>
          <ul className={styles.headerContactInfoList}>
            <li>
              <a href={`tel://${parsedPhoneNumbers && parsedPhoneNumbers.length > 0 ? parsedPhoneNumbers[0] : ''}`}>
                <FaPhone />
                {parsedPhoneNumbers && parsedPhoneNumbers.length > 0 ? parsedPhoneNumbers[0] : ''}
              </a>
            </li>
            <li>
              <a href={`mailto:${parsedEmail}`}>
                <FaRegEnvelope />
                {parsedEmail}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.offCanvasWidgetSocial}>
        <a href={parsedTwitter} target='_blank' rel='noopener noreferrer' title='Twitter'>
          <FaTwitter />
        </a>
        <a href={parsedInstagram} target='_blank' rel='noopener noreferrer' title='Instagram'>
          <FaInstagram />
        </a>
        <a href={parsedFacebook} target='_blank' rel='noopener noreferrer' title='Facebook'>
          <FaFacebookF />
        </a>
      </div>
    </div>
  );
};

export default MobileWidgets;
