import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import toast, { Toaster } from 'react-hot-toast';
import LoadingIndicator from '../../../helpers/LoadingIndicator';
import { Box, Modal, Button, Stack, IconButton } from '@mui/material';
import { LandingImagesAPI, UsersAPI } from '../../../apis';
import { getCurrentLoggedInUser, isNotBlank } from '../../../utils';
import { BOX_STYLE, GRID_STYLE } from '../../../constants';
import { useHistory } from 'react-router-dom';
import { EmptyGridOverlay } from '../../../helpers/EmptyGridOverlay';
import LandingImageForm from './LandingForm';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import AlertDialog from '../../../helpers/AlertDialog';
import './LandingForm.css';

const LandingImageList = () => {
  const history = useHistory();
  // const [modelData, setModelData] = useState({});
  const [landingImages, setLandingImages] = useState([]);
  const [editLandingImage, setEditLandingImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showAddUpdateDialog, setShowAddUpdateDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const renderImage = (params) => {
    let imageUrl = '';

    if (params && 'row' in params && 'image' in params.row) {
      if (params.row.image && typeof params.row.image === 'object') {
        imageUrl = URL.createObjectURL(params.row.image);
      } else if (params.row.image && typeof params.row.image === 'string') {
        imageUrl = params.row.image;
      }
    }

    return (
      <div className='image-container smaller'>
        <img src={imageUrl} alt={'Image'} className='img' />
      </div>
    );
  };

  const columns = [
    {
      field: 'image',
      headerName: 'Image',
      renderCell: (params) => renderImage(params),
      width: 200
    },
    { field: 'title', headerName: 'Title', width: 150 },
    { field: 'description', headerName: 'Description', width: 300 },
    { field: 'redirectUrl', headerName: 'Redirect URL', width: 300 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: (params) => (
        <Stack direction={'row'} spacing={1}>
          <IconButton
            aria-label='edit'
            color='primary'
            style={{ width: '40px' }}
            onClick={() => handleRowClick(params.row)}
          >
            <EditOutlined />
          </IconButton>
          <IconButton
            aria-label='delete'
            color='primary'
            style={{ width: '40px' }}
            onClick={() => {
              setEditLandingImage(params.row);
              setShowDeleteDialog(true);
            }}
          >
            <DeleteOutline />
          </IconButton>
        </Stack>
      )
    }
  ];

  const deleteLandingPageAlert = useCallback(
    () => (
      <AlertDialog
        cancelButtonTitle='No'
        continueButtonTitle='Yes'
        title={'Delete'}
        message={'Are you sure you want to delete this landing page info?'}
        showDialog={showDeleteDialog}
        setShowDialog={setShowDeleteDialog}
        onContinue={continueDelete}
        onClose={() => {
          setEditLandingImage(null);
        }}
      />
    ),
    [showDeleteDialog, continueDelete, setShowDeleteDialog]
  );

  const loadCurrentUser = useCallback(async () => {
    try {
      const user = await getCurrentLoggedInUser();
      if (isNotBlank(user)) {
        setCurrentUser(user);
      } else {
        await UsersAPI.logout();
        history.push('/');
      }
    } catch (error) {
      console.log('Error loading current user:', error);
      await UsersAPI.logout();
      history.push('/');
    }
  }, [history]);

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await LandingImagesAPI.getAll();
      const landingInfo = (data && data.landingInfo) || [];
      setLandingImages(landingInfo);
    } catch (error) {
      console.log('Error loading data:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadCurrentUser();
    loadData();
  }, [loadData, loadCurrentUser]);

  const handleRowClick = (row) => {
    setEditLandingImage(row);
    setShowAddUpdateDialog(true);
  };

  const continueDelete = () => {
    setTimeout(async () => {
      try {
        setShowDeleteDialog(false);
        setLoading(true);
        const response = await LandingImagesAPI.delete({ id: editLandingImage._id });
        if (response.success) {
          await loadData();
          toast.success(response.message);
          setEditLandingImage(null);
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        console.log('\n▶️ -> setTimeout -> error:', error);
        toast.error('Unable to delete info!');
      } finally {
        setLoading(false);
      }
    }, 0);
  };

  const closeAddUpdateDialog = (event, reason) => {
    if (reason !== 'backdropClick') {
      setShowAddUpdateDialog(false);
    }
    setEditLandingImage(null);
  };

  const handleResponseCallback = async (response) => {
    setLoading(false);

    if (response.success) {
      closeAddUpdateDialog();
      await loadData();
      toast.success(response.message);
    } else {
      if (response.cancel) {
        closeAddUpdateDialog();
      }

      toast.error(response.message);
    }
  };

  const renderDataGrid = () => (
    <div style={{ minHeight: 400, width: '100%' }}>
      <DataGrid
        getRowId={(row) => row._id.valueOf()}
        getRowHeight={() => 120}
        slots={{ noRowsOverlay: EmptyGridOverlay }}
        onRowClick={(params) => handleRowClick(params.row)}
        rows={landingImages || []}
        columns={columns}
        initialState={{ pagination: { paginationModel: { pageSize: 13 } } }}
        pageSizeOptions={[13]}
        sx={GRID_STYLE}
      />
    </div>
  );

  const renderAddUpdateDialog = () => (
    <Modal
      open={showAddUpdateDialog}
      onClose={closeAddUpdateDialog}
      aria-labelledby='parent-modal-title'
      aria-describedby='parent-modal-description'
    >
      <Box sx={{ ...BOX_STYLE, width: '95%' }}>
        <LandingImageForm
          editLandingPageInfo={editLandingImage}
          responseCallBack={handleResponseCallback}
          setLoading={setLoading}
          currentUser={currentUser}
        />
      </Box>
    </Modal>
  );

  const handleRefreshClick = () => {
    loadData();
  };

  const handleAddLandingImageClick = () => {
    setShowAddUpdateDialog(true);
  };

  const renderActionButtons = () => (
    <div className='d-flex flex-row justify-content-start' style={{ height: '60px', margin: '10px 0' }}>
      <Button
        variant='contained'
        onClick={handleAddLandingImageClick}
        style={{ maxWidth: '150px', marginRight: '10px' }}
      >
        Add
      </Button>
      <Button variant='contained' onClick={handleRefreshClick} style={{ maxWidth: '150px', marginLeft: '10px' }}>
        Refresh
      </Button>
    </div>
  );

  return (
    <Fragment>
      <div key='Landing-Images' className='p-3 card w-100'>
        <div className='d-flex flex-column justify-content-between'>
          <h2 style={{ color: 'gray' }}>Landing Images</h2>
          {renderActionButtons()}
          {renderDataGrid()}
          {renderAddUpdateDialog()}
          {deleteLandingPageAlert()}
        </div>
      </div>
      <Toaster />
      <LoadingIndicator open={loading} />
    </Fragment>
  );
};

export default LandingImageList;
