import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';

const LoadingIndicator = ({ open = true, handleClose = () => {} }) => (
  <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={open} onClick={handleClose}>
    <CircularProgress color='inherit' />
  </Backdrop>
);

export default LoadingIndicator;
