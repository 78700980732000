import React, { Fragment } from 'react';
import Layout from '../layouts/layout';
import SalaryDistributionForm from '../containers/salaryDistribution/SalaryDistributionForm';

const Salary = () => {
  return (
    <Fragment>
      <Layout>
        <SalaryDistributionForm />
      </Layout>
    </Fragment>
  );
};

export default Salary;
